import { GET_ADRESS_FROM_COORD } from '../actions/types'
import request from 'request'

export const getAdressFromCoordinates = coordonne => dispatch => {
  if([0, 1, 2, 3, 4,10].includes(parseInt(coordonne.role))) {
    return new Promise(function (resolve, reject) {
      request('https://api-adresse.data.gouv.fr/reverse/?lon=' + coordonne.x + '&lat=' + coordonne.y, function (error, response, body) {
        if (body !== undefined) {
          dispatch({
            type: GET_ADRESS_FROM_COORD,
            payload: JSON.parse(body)
          })
          resolve(JSON.parse(body))
        }
      })
    })
  } else {
    return false
  }
}