/* Fonctions */
const getCategorie = (ancienneCategorie, collection) => {
  if (collection === 'enseignement') return enseignementMappingTypeCategorie[ancienneCategorie]
  if (collection === 'loisir') return loisirMappingTypeCategorie[ancienneCategorie]
  if (collection === 'commerces') return commercesMappingTypeCategorie[ancienneCategorie]
  if (collection === 'administration') return administrationMappingTypeCategorie[ancienneCategorie]
  if (collection === 'sante') return santeMappingTypeCategorie[ancienneCategorie]
  if (collection === 'transport') return transportMappingTypeCategorie[ancienneCategorie]
}

const getIndexCategorie = (groupCategorie, collection) => {
  if (collection === 'enseignement') return enseignemenListeCategorie.indexOf(groupCategorie)
  if (collection === 'loisir') return loisirListeCategorie.indexOf(groupCategorie)
  if (collection === 'commerces') return commercesListeCategorie.indexOf(groupCategorie)
  if (collection === 'administration') return administrationListeCategorie.indexOf(groupCategorie)
  if (collection === 'sante') return santeListeCategorie.indexOf(groupCategorie)
  if (collection === 'transport') return transportListeCategorie.indexOf(groupCategorie)
}

const getListeCategories = (collection) => {
  if (collection === 'enseignement') return enseignemenListeCategorie
  if (collection === 'loisir') return loisirListeCategorie
  if (collection === 'commerces') return commercesListeCategorie
  if (collection === 'administration') return administrationListeCategorie
  if (collection === 'sante') return santeListeCategorie
  if (collection === 'transport') return transportListeCategorie
}

const getListeTypeFromCollection = (collection) => {
  if (collection === 'enseignement') return enseignementListeType
  if (collection === 'loisir') return loisirListeType
  if (collection === 'commerces') return commercesListeType
  if (collection === 'administration') return administrationListeType
  if (collection === 'sante') return santeListeType
  if (collection === 'transport') return transportListeType
}

// Enseignement
const enseignementListeType = [
  "Autre_enseignement_supérieur",
  "Autre_formation_continue",
  "Autre_formation_post_bac_non_universitaire",
  "Centre_de_formation_d'apprentis_(hors_agriculture)",
  "Centre_dispensant_de_la_formation_continue_agricole",
  "Centre_dispensant_des_formations_d’apprentissage_agricole",
  "Collège",
  "École_élémentaire",
  "École_élémentaire_de_RPI_dispersé",
  "École_ingénieurs",
  "École_maternelle",
  "École_maternelle_de_RPI_dispersé",
  "Écoles_d’enseignement_supérieur_agricole",
  "Enseignement_général_supérieur_privé",
  "Formation_aux_métiers_du_sport",
  "Formation_commerce",
  "Formation_santé",
  "GRETA",
  "Institut_universitaire",
  "Lycée_d'enseignement_général_et/ou_techno",
  "Lycée_d'enseignement_professionnel",
  "Lycée_technique_et/ou_professionnel_agricole",
  "Résidence_universitaire",
  "Restaurant_universitaire",
  "SEP_:_Section_enseignement_professionnel",
  "SGT_:_Section_enseignement_général_et_techno",
  "STS_CPGE",
  "UFR"
]
const enseignemenListeCategorie = [
  'Apprentissage',
  'Autres formations',
  'Collège',
  'École maternelle et élémentaire',
  'Enseignement supérieur',
  'Fomation continue',
  'Lycée',
  'Résidence universtaire',
  'Restaurant universitaire'
]

const enseignementMappingTypeCategorie = {
  "Autre_enseignement_supérieur": "Enseignement supérieur",
  "Autre_formation_continue": "Fomation continue",
  "Autre_formation_post_bac_non_universitaire": "Autres formations",
  "Centre_de_formation_d'apprentis_(hors_agriculture)": "Apprentissage",
  "Centre_dispensant_de_la_formation_continue_agricole": "Fomation continue",
  "Centre_dispensant_des_formations_d’apprentissage_agricole": "Apprentissage",
  "Collège": "Collège",
  "École_élémentaire": "École maternelle et élémentaire",
  "École_élémentaire_de_RPI_dispersé": "École maternelle et élémentaire",
  "École_ingénieurs": "Enseignement supérieur",
  "École_maternelle": "École maternelle et élémentaire",
  "École_maternelle_de_RPI_dispersé": "École maternelle et élémentaire",
  "Écoles_d’enseignement_supérieur_agricole": "Enseignement supérieur",
  "Enseignement_général_supérieur_privé": "Enseignement supérieur",
  "Formation_aux_métiers_du_sport": "Autres formations",
  "Formation_commerce": "Autres formations",
  "Formation_santé": "Autres formations",
  "GRETA": "Autres formations",
  "Institut_universitaire": "Enseignement supérieur",
  "Lycée_d'enseignement_général_et/ou_techno": "Lycée",
  "Lycée_d'enseignement_professionnel": "Lycée",
  "Lycée_technique_et/ou_professionnel_agricole": "Lycée",
  "Résidence_universitaire": "Résidence universtaire",
  "Restaurant_universitaire": "Restaurant universitaire",
  "SEP_:_Section_enseignement_professionnel": "Autres formations",
  "SGT_:_Section_enseignement_général_et_techno": "Autres formations",
  "STS_CPGE": "Autres formations",
  "UFR": "Enseignement supérieur"
}

// Loisir
const loisirListeType = [
  "Agence_de_voyages",
  "Athlétisme",
  "Baignade_aménagée",
  "Bassin_de_natation",
  "Boucle_de_randonnée",
  "Boulodrome",
  "Bowling",
  "Camping",
  "Centre_équestre",
  "Cinéma",
  "Conservatoire",
  "Domaine_skiable",
  "Équipement_de_cyclisme",
  "Hôtel",
  "Information_touristique",
  "Musée",
  "Parcours_sportif/santé",
  "Plateaux_et_terrains_de_jeux_extérieurs",
  "Port_de_plaisance_Mouillage",
  "Restaurant",
  "Roller-Skate-Vélo_bicross_ou_freestyle",
  "Salles_de_combat",
  "Salles_de_remise_en_forme",
  "Salles_multisports_(gymnase)",
  "Salles_non_spécialisées",
  "Salles_spécialisées",
  "Sports_de_glace",
  "Sports_nautiques",
  "Tennis",
  "Terrain_de_golf",
  "Terrain_de_grands_jeux",
  "Théâtre_et_structutures_culturelles",
]
const loisirListeCategorie = [
  'Culture',
  'Restaurant',
  'Installations sportives',
  'Tourisme'
]
const loisirMappingTypeCategorie = {
  "Agence_de_voyages": "Tourisme",
  'Athlétisme': 'Installations sportives',
  'Baignade_aménagée': 'Installations sportives',
  'Bassin_de_natation': 'Installations sportives',
  'Boucle_de_randonnée': 'Installations sportives',
  'Boulodrome': 'Installations sportives',
  'Bowling': 'Installations sportives',
  'Camping': 'Tourisme',
  'Centre_équestre': 'Installations sportives',
  'Cinéma': 'Culture',
  'Conservatoire': 'Culture',
  'Domaine_skiable': 'Installations sportives',
  'Équipement_de_cyclisme': 'Installations sportives',
  "Information_touristique": "Tourisme",
  'Musée': 'Culture',
  'Parcours_sportif/santé': 'Installations sportives',
  'Plateaux_et_terrains_de_jeux_extérieurs': 'Installations sportives',
  'Port_de_plaisance_Mouillage': 'Installations sportives',
  "Restaurant": "Restaurant",
  'Roller-Skate-Vélo_bicross_ou_freestyle': 'Installations sportives',
  'Salles_de_combat': 'Installations sportives',
  'Salles_de_remise_en_forme': 'Installations sportives',
  'Salles_multisports_(gymnase)': 'Installations sportives',
  'Salles_non_spécialisées': 'Installations sportives',
  'Salles_spécialisées': 'Installations sportives',
  'Sports_de_glace': 'Installations sportives',
  'Sports_nautiques': 'Installations sportives',
  'Tennis': 'Installations sportives',
  'Terrain_de_golf': 'Installations sportives',
  'Terrain_de_grands_jeux': 'Installations sportives',
  'Théâtre_et_structutures_culturelles': 'Culture'
}

// Commerces
const commercesListeType = [
  "Agence_de_travail_temporaire",
  "Agence_immobilière",
  "Banque_Caisse_d’Épargne",
  "Blanchisserie-Teinturerie",
  "Boucherie_charcuterie",
  "Boulangerie",
  "Coiffure",
  "Contrôle_technique_automobile",
  "Droguerie_quincaillerie_Services divers",
  "École_de_conduite",
  "Électricien",
  "Entreprise_générale_du_bâtiment",
  "Épicerie",
  "Fleuriste",
  "Grande_surface_de_Services divers",
  "Horlogerie_Bijouterie",
  "Hypermarché",
  "Librairie_papeterie_journaux",
  "Location_auto-utilitaires_légers",
  "Magasin_d'art_de_sports_et_de_loisirs",
  "Magasin_d'électroménager_audio_vidéo",
  "Magasin_d'équipements_du_foyer",
  "Magasin_d’optique",
  "Magasin_de_chaussures",
  "Magasin_de_meubles",
  "Magasin_de_revêtements_murs_et_sols",
  "Magasin_de_vêtements",
  "Parfumerie",
  "Poissonnerie",
  "Pompes_funèbres",
  "Produits_surgelés",
  "Réparation_auto_et_de_matériel_agricole",
  "Soins_de_Services divers",
  "Supérette",
  "Supermarché"
]
const commercesListeCategorie = [
  'Alimention',
  'Banque',
  'Services divers',
  'Commerces',
  'Grande Distribution',
]
const commercesMappingTypeCategorie = {
  "Agence_de_travail_temporaire": "Services divers",
  "Agence_immobilière": "Services divers",
  "Banque_Caisse_d’Épargne": "Banque",
  "Blanchisserie-Teinturerie": "Services divers",
  "Boucherie_charcuterie": "Alimention",
  "Boulangerie": "Alimention",
  "Coiffure": "Services divers",
  "Contrôle_technique_automobile": "Services divers",
  "Droguerie_quincaillerie_Services divers": "Services divers",
  "École_de_conduite": "Services divers",
  "Électricien": "Services divers",
  "Entreprise_générale_du_bâtiment": "Services divers",
  "Épicerie": "Alimention",
  "Fleuriste": "Commerces",
  "Grande_surface_de_Services divers": "Services divers",
  "Horlogerie_Bijouterie": "Commerces",
  "Hôtel": "Tourisme",
  "Hypermarché": "Grande Distribution",
  "Librairie_papeterie_journaux": "Commerces",
  "Location_auto-utilitaires_légers": "Services divers",
  "Magasin_d'art_de_sports_et_de_loisirs": "Commerces",
  "Magasin_d'électroménager_audio_vidéo": "Commerces",
  "Magasin_d'équipements_du_foyer": "Commerces",
  "Magasin_d’optique": "Commerces",
  "Magasin_de_chaussures": "Commerces",
  "Magasin_de_meubles": "Commerces",
  "Magasin_de_revêtements_murs_et_sols": "Commerces",
  "Magasin_de_vêtements": "Commerces",
  "Parfumerie": "Commerces",
  "Poissonnerie": "Alimention",
  "Pompes_funèbres": "Services divers",
  "Produits_surgelés": "Alimention",
  "Réparation_auto_et_de_matériel_agricole": "Services divers",
  "Soins_de_Services divers": "Services divers",
  "Supérette": "Alimention",
  "Supermarché": "Grande Distribution"
}

// Administration
const administrationListeType = [
  "Agence_postale",
  "Aide_sociale_à_l'enfance__action_éduc",
  "Aide_sociale_à_l'enfance__hébergement",
  "Antenne_de_justice",
  "Autres_établissements_pour_adultes_et_familles_en_difficulté",
  "Bureau_de_poste",
  "Centre_accueil_demandeur_d'asile",
  "Centre_provisoire_d'hébergement",
  "CHRS_Centre_d'héberg_et_de_réadapt_sociale",
  "Conseil_départemental_d’accès_au_droit_(CDAD)",
  "Conseil_des_prud’hommes_(CPH)",
  "Cour_d’appel_(CA)",
  "Direction_Départementale_des_Finances_Publiques(DDFIP)",
  "Direction_Générale_des_Finances_Publiques(DGFIP)",
  "Direction_Régionale_des_Finances_Publiques(DRFIP)",
  "Gendarmeri",
  "Maison_de_justice_et_du_droit",
  "Police",
  "Relais_poste",
  "Réseau_de_proximité_Pôle_Emploi",
  "Réseau_partenarial_Pôle_Emploi",
  "Réseau_spécialisé_Pôle_Emploi",
  "Travail_protégé",
  "Tribunal_d’instance_(TI)",
  "Tribunal_de_commerce_(TCO)",
  "Tribunal_de_grande_instance_(TGI)",
]
const administrationListeCategorie = [
  'Autres',
  'Emploi',
  'Justice',
  'Police/Gendarmerie',
  'Poste',
  'Sociale'
]
const administrationMappingTypeCategorie = {
  "Agence_postale": "Poste",
  "Aide_sociale_à_l'enfance__action_éduc": "Sociale",
  "Aide_sociale_à_l'enfance__hébergement": "Sociale",
  "Antenne_de_justice": "Justice",
  "Autres_établissements_pour_adultes_et_familles_en_difficulté": "Sociale",
  "Bureau_de_poste": "Poste",
  "Centre_accueil_demandeur_d'asile": "Sociale",
  "Centre_provisoire_d'hébergement": "Sociale",
  "CHRS_Centre_d'héberg_et_de_réadapt_sociale": "Sociale",
  "Conseil_départemental_d’accès_au_droit_(CDAD)": "Justice",
  "Conseil_des_prud’hommes_(CPH)": "Justice",
  "Cour_d’appel_(CA)": "Justice",
  "Direction_Départementale_des_Finances_Publiques(DDFIP)": "Autres",
  "Direction_Générale_des_Finances_Publiques(DGFIP)": "Autres",
  "Direction_Régionale_des_Finances_Publiques(DRFIP)": "Autres",
  "Gendarmeri": "Police/Gendarmerie",
  "Maison_de_justice_et_du_droit": "Justice",
  "Police": "Police/Gendarmerie",
  "Relais_poste": "Poste",
  "Réseau_de_proximité_Pôle_Emploi": "Emploi",
  "Réseau_partenarial_Pôle_Emploi": "Emploi",
  "Réseau_spécialisé_Pôle_Emploi": "Emploi",
  "Travail_protégé": "Emploi",
  "Tribunal_d’instance_(TI)": "Justice",
  "Tribunal_de_commerce_(TCO)": "Justice",
  "Tribunal_de_grande_instance_(TGI)": "Justice"
}

// Santé
const santeListeType = [
  "Adultes_handicapés__hébergement",
  "Adultes_handicapés__Services divers_d’aide",
  "Adultes_handicapés_:_Services divers_de_soins_à_domicile",
  "Ambulance",
  "Audio_prothésiste",
  "Centre_de_santé",
  "Centre_lutte_cancer",
  "Centre_Médecins généralistese_préventive",
  "Chirurgien_Médecins spécialistes",
  "Dialyse",
  "Diététicien",
  "Enfants_handicapés__hébergement",
  "Enfants_handicapés_soins_à_domicile",
  "Ergothérapeute",
  "Établissement_d'accueil_du_jeune_enfant",
  "Établissement_psychiatrique_avec_hébergement",
  "Établissement_santé_court_séjour",
  "Établissement_santé_long_séjour",
  "Établissement_santé_moyen_séjour",
  "Établissement_thermal",
  "Hospitalisation_à_domicile",
  "Infirmier",
  "Laboratoire_d'analyses_et_de_biologie_médicales",
  "Magasin_de_matériel_médical_et_orthopédique",
  "Maison_de_santé_pluridisciplinaire",
  "Masseur_kinésithérapeute",
  "Maternité",
  "Médecins généralistes_omnipraticien",
  "Orthophoniste",
  "Orthoptiste",
  "Pédicure-podologue",
  "Personnes_âgées__foyers_restaurants",
  "Personnes_âgées__hébergement",
  "Personnes_âgées__Services divers_d'aide",
  "Personnes_âgées__Services divers_de_repas_à_domicile",
  "Personnes_âgées__soins_à_domicile",
  "Pharmacie",
  "Psychologue",
  "Psychomotricien",
  "Sage-femme",
  "Spéc_en_radiodiagnostic_et_imagerie_médicale",
  "Spécialiste_en_cardiologie",
  "Spécialiste_en_dermatologie_vénéréologie",
  "Spécialiste_en_gastro-entérologie",
  "Spécialiste_en_gynécologie_(médicale_et/ouobstétrique)",
  "Spécialiste_en_ophtalmologie",
  "Spécialiste_en_oto-rhino-laryngologie",
  "Spécialiste_en_pédiatrie",
  "Spécialiste_en_pneumologie",
  "Spécialiste_en_psychiatrie",
  "Spécialiste_en_stomatologie",
  "Structures_psychiatriques_en_ambulatoire",
  "Transfusion_sanguine",
  "Urgences",
  "Vétérinaire"
]
const santeListeCategorie = [
  'Autres',
  'Médecins spécialistes',
  'Etablissement de santé',
  'Infirmiers',
  'Laboratoire',
  'Médecins généralistes',
  'Pharmacie',
  'Médecins spécialistes'
]
const santeMappingTypeCategorie = {
  "Adultes_handicapés__hébergement": "Autres",
  "Adultes_handicapés__Services divers_d’aide": "Autres",
  "Adultes_handicapés_:_Services divers_de_soins_à_domicile": "Autres",
  "Ambulance": "Autres",
  "Audio_prothésiste": "Autres",
  "Centre_de_santé": "Etablissement de santé",
  "Centre_lutte_cancer": "Etablissement de santé",
  "Centre_Médecins généralistese_préventive": "Etablissement de santé",
  "Chirurgien_Médecins spécialistes": "Médecins spécialistes",
  "Dialyse": "Autres",
  "Diététicien": "Autres",
  "Enfants_handicapés__hébergement": "Autres",
  "Enfants_handicapés_soins_à_domicile": "Autres",
  "Ergothérapeute": "Autres",
  "Établissement_d'accueil_du_jeune_enfant": "Etablissement de santé",
  "Établissement_psychiatrique_avec_hébergement": "Etablissement de santé",
  "Établissement_santé_court_séjour": "Etablissement de santé",
  "Établissement_santé_long_séjour": "Etablissement de santé",
  "Établissement_santé_moyen_séjour": "Etablissement de santé",
  "Établissement_thermal": "Etablissement de santé",
  "Hospitalisation_à_domicile": "Autres",
  "Infirmier": "Infirmiers",
  "Laboratoire_d'analyses_et_de_biologie_médicales": "Laboratoire",
  "Magasin_de_matériel_médical_et_orthopédique": "Autres",
  "Maison_de_santé_pluridisciplinaire": "Etablissement de santé",
  "Masseur_kinésithérapeute": "Autres",
  "Maternité": "Etablissement de santé",
  "Médecins généralistes_omnipraticien": "Médecins généralistes",
  "Orthophoniste": "Autres",
  "Orthoptiste": "Autres",
  "Pédicure-podologue": "Autres",
  "Personnes_âgées__foyers_restaurants": "Autres",
  "Personnes_âgées__hébergement": "Autres",
  "Personnes_âgées__Services divers_d'aide": "Autres",
  "Personnes_âgées__Services divers_de_repas_à_domicile": "Autres",
  "Personnes_âgées__soins_à_domicile": "Autres",
  "Pharmacie": "Pharmacie",
  "Psychologue": "Autres",
  "Psychomotricien": "Autres",
  "Sage-femme": "Médecins spécialistes",
  "Spéc_en_radiodiagnostic_et_imagerie_médicale": "Médecins spécialistes",
  "Spécialiste_en_cardiologie": "Médecins spécialistes",
  "Spécialiste_en_dermatologie_vénéréologie": "Médecins spécialistes",
  "Spécialiste_en_gastro-entérologie": "Médecins spécialistes",
  "Spécialiste_en_gynécologie_(médicale_et/ouobstétrique)": "Médecins spécialistes",
  "Spécialiste_en_ophtalmologie": "Médecins spécialistes",
  "Spécialiste_en_oto-rhino-laryngologie": "Médecins spécialistes",
  "Spécialiste_en_pédiatrie": "Médecins spécialistes",
  "Spécialiste_en_pneumologie": "Médecins spécialistes",
  "Spécialiste_en_psychiatrie": "Médecins spécialistes",
  "Spécialiste_en_stomatologie": "Médecins spécialistes",
  "Structures_psychiatriques_en_ambulatoire": "Etablissement de santé",
  "Transfusion_sanguine": "Autres",
  "Urgences": "Etablissement de santé",
  "Vétérinaire": "Autres"
}

// Transport
const transportListeType = [
  "Aéroport",
  "Gare_de_voyageurs_interet_local",
  "Gare_de_voyageurs_interet_natiional",
  "Gare_de_voyageurs_interet_regional",
  "Station_service",
  "Taxi",
]
const transportListeCategorie = [
  'Aéroport',
  'Gare',
  'Station service',
  'Taxi'
]
const transportMappingTypeCategorie = {
  "Aéroport": "Aéroport",
  "Gare_de_voyageurs_interet_local": "Gare",
  "Gare_de_voyageurs_interet_natiional": "Gare",
  "Gare_de_voyageurs_interet_regional": "Gare",
  "Station_service": "Station service",
  "Taxi": "Taxi"
}

module.exports = {
  getCategorie,
  getIndexCategorie,
  getListeCategories,
  getListeTypeFromCollection
}