import { combineReducers } from "redux";
import authReducer from "./authReducer";
import communeReducer from "./communeReducer";
import irisReducer from "./irisReducer";
import departementReducer from "./departementReducer";
import errorReducer from "./errorReducer";
import registerReducer from "./registerReducer";
import mapReducer from "./mapReducer";
import searchCommuneReducer from "./searchCommuneReducer";
import bienReducer from "./bienReducer";
import districtReducer from "./districtReducer";
import adresseReducer from "./adresseReducer";
import geographicReducer from "./geographicReducer";
import critereReducer from "./critereReducer";
export default combineReducers({
  auth: authReducer,
  register: registerReducer,
  errors: errorReducer,
  communes: communeReducer,
  iris: irisReducer,
  departements: departementReducer,
  dataMap: mapReducer,
  searchCommune: searchCommuneReducer,
  bien: bienReducer,
  districts: districtReducer,
  adresse: adresseReducer,
  map: geographicReducer,
  critere: critereReducer,
});
