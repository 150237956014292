import {
  GET_CONTOUR_DEPARTEMENT,
} from '../actions/types';

const initialState = {
  oneDepartement: null,
  contour: null
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_CONTOUR_DEPARTEMENT:
      return {
        ...state,
        contour: action.payload
      }
    default:
      return state;
  }
}
