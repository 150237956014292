const { isEmpty } = require('../../shared/functions/isEmpty')

export const objectToUrl = (object) => {
    if (isEmpty(object)) {
      return ''
    }
    
    let paramArr = Object.entries(object).map((entry, index) => {
      let param = entry[0] + '=' + entry[1]
      if (index === Object.entries(object).length - 1) {
        return param
      }
      return param + '&'
    })
  
    return paramArr.join('')
}