import React, { useState, useEffect } from 'react';

// Material UI components
import { makeStyles } from '@material-ui/core/styles';
import { Paper, useMediaQuery } from '@material-ui/core';

export default function FixedButtonHorizontalList(props) {
  const [buttonBottomPosition, setButtonBottomPosition] = useState(80);
  const matches = useMediaQuery(theme => theme.breakpoints.down('sm'));

  const useStyles = makeStyles(theme => ({
    buttonPaper: {
      padding: '0px 64px',
      position: 'fixed',
      bottom: buttonBottomPosition + 'px',
      zIndex: 1000,
      width: '60%',
      left: '20%',
      backgroundColor: "rgba(0, 0, 0, 0)",
      boxShadow: "inherit",
      pointerEvents: 'none',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        left: '0%',
        padding: '0px 0px',
      },
      '@media print': {
        display: 'none'
      }
    },
    buttonUl: {
      listStyle: "none",
      margin: "0px",
      padding: "0px",
      textAlign: "center"
    }
  }));

  const classes = useStyles();

  const handleScroll = () => {
    let position = 0
    let margin = 16
    try {
      position = document.getElementById("footer").getBoundingClientRect()
    } catch {
      position = document.getElementById("footer_navbar").getBoundingClientRect()
    }
    
    if (matches) {
      setButtonBottomPosition(90)
    } else {
      if (position.y < window.innerHeight) {
        setButtonBottomPosition(window.innerHeight - position.y + margin)
      } else {
        setButtonBottomPosition(margin);
      }
    }
  }

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => window.removeEventListener("scroll", handleScroll);
  });

  return (
    <Paper className={classes.buttonPaper}>
      <ul className={classes.buttonUl}>
        {props.children}
      </ul>
    </Paper>
  );
}