import { GET_ERRORS, PUSH_ERROR } from '../actions/types'

const initialState = {}

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_ERRORS:
      return {
        ...action.payload
      }
    case PUSH_ERROR:
      return {
        ...action.payload
      }
    default:
      return state
  }
}