import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import L from "leaflet";

// Material UI components
import Grid from "@material-ui/core/Grid";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import Chart from "react-apexcharts";
import JaugePrix from "../../../kpi/JaugePrix";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { isEmpty } from "../../../../shared/functions/isEmpty";
import {
  getCommuneFromCoordinates,
  getCommuneByInsee,
} from "../../../../actions/communeActions";
import { get_notaire_data } from "../../../../actions/bienActions";
import { getIrisFromCoordinates } from "../../../../actions/irisActions";
import { getDistrict } from "../../../../actions/districtAction";
import { getAdressFromCoordinates } from "../../../../actions/adresseActions";
import axios from "axios";
import "./Donnesdumarche.css";
import { DriveEta } from "@mui/icons-material";
import useJaugeData from "../../../../shared/hooks/useJaugeData";
const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(2),
    marginLeft: "auto",
    marginRight: "auto",
    height: "210mm",
    width: "297mm",
    ["@media (max-width:800px)"]: {
      // eslint-disable-line no-useless-computed-key
      width: "100%",
      height: "fit-content",
    },

    padding: theme.spacing(2),
    breakAfter: "page",
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(1),
    },
    "@media print": {
      height: "209.5mm",
      width: "297mm",
      padding: theme.spacing(0),
      borderRadius: 0,
      //border: '1px solid ' + theme.palette.primary.main
    },
  },
  hidden: {
    display: "none",
  },
  root: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
    width: "100%",
  },
  gridList: {},
  gridTilePortrait: {
    height: "auto",
    width: "100%",
    minHeight: "100%",
    maxWidth: "100%",
  },
  gridTileLandscape: {
    height: "100%",
    width: "auto",
    maxHeight: "100%",
    minWidth: "100%",
  },
  cardValue: {
    fontSize: "1.6rem",
    color: theme.palette.secondary.main,
  },
  card2: {
    margin: theme.spacing(1),
    padding: theme.spacing(2),
    height: "195px",
    borderColor: "#eee",
    borderStyle: "solid",
    borderRadius: "4px",
    boxShadow: "none",
    position: "relative",
    ["@media (max-width:1000px)"]: {
      // eslint-disable-line no-useless-computed-key
      margin: theme.spacing(1),
      padding: theme.spacing(2),
      height: "125px",
      borderColor: "#eee",
      borderStyle: "solid",
      borderRadius: "4px",
      boxShadow: "none",
      position: "relative",
    },
  },
  cardTitle: {
    fontSize: "1.1rem",
    color: theme.palette.primary.main,
  },
  card: {
    margin: theme.spacing(1),
    padding: theme.spacing(2),
    height: "213px",
    borderColor: "#eee",
    borderStyle: "solid",
    borderRadius: "4px",
    boxShadow: "none",
    position: "relative",
    [theme.breakpoints.down("xs")]: {},
  },
  cardContent: {
    padding: 0,
  },

  cardSubtitle: {
    fontSize: "0.8rem",
    color: theme.palette.primary.main,
  },
  cardSubtitle2: {
    fontSize: "0.8rem",
    color: theme.palette.primary.light,
    bottom: 0,
    position: "absolute",
    margin: 0,
    paddingBottom: theme.spacing(0),
  },
}));
const DonnesDuMarche = (props) => {
  var data = props.bien;
  var [latitude, setLatitude] = useState();
  var [longitude, setLongitude] = useState();
  const dataIris = props.dataIris;
  const dataCommune = props.dataCommune;
  const [axeX, setAxeX] = useState([]);
  const [axeY, setAxeY] = useState([]);

  var [typeBienParam, setTypeBienParam] = useState("Appartement");
  var [nbePieceParam, setNbePieceBienParam] = useState("all");
  var [surface, setSurface] = useState(props.bien.surface);
  var [nbePiece, setNbePiece] = useState(props.bien.nbe_piece);
  var [typeBien, setTypeBien] = useState(props.bien.type_bien_corrige);

  const classes = useStyles();
  const matches = useMediaQuery((theme) => theme.breakpoints.down("xs"));
  // Prix M2 Vente Notaire - Commmune
  const [prixM2VenteNotaire, setPrixM2VenteNotaire] = useState();

  // Prix M2 Vente Notaire - Iris
  const [prixM2VenteIrisNotaire, setPrixM2VenteIrisNotaire] = useState();
  // const [prixM2VenteIrisNotaireMin, setPrixM2VenteIrisNotaireMin] =
  //   useState(1000);
  // const [prixM2VenteIrisNotaireMoy, setPrixM2VenteIrisNotaireMoy] =
  //   useState(2000);
  // const [prixM2VenteIrisNotaireMax, setPrixM2VenteIrisNotaireMax] =
  //   useState(3000);
  const [
    prixM2VenteIrisNotaireMin,
    prixM2VenteIrisNotaireMax,
    prixM2VenteIrisNotaireMoy,
  ] = useJaugeData(dataCommune, data);
  const [prixBienFAI] = useState(
    props.bien.qualification_robot.prix.prix_bien_fai
  );

  useEffect(() => {
    console.log(props.dataCommune, "datacommune");
    !isEmpty(props.dataCommune) &&
      setPrixM2VenteNotaire(
        props.dataCommune.properties.prix_m2_vente.source_wealzy
      );
    !isEmpty(props.dataCommune) &&
      console.log(
        "PrixM2VenteNotaire",
        props.dataCommune.properties.prix_m2_vente.source_wealzy
      );
  }, [JSON.stringify(props.dataCommune)]); //

  useEffect(() => {
    let dataToSend = {
      codeInsee: props.bien.code_insee,
      role: props.connectedUserRole,
    };
    console.log("dts", dataToSend, props.bien);
    props.getCommuneByInsee(dataToSend);
  }, [props.bien.code_insee]);
  var [prixM2Reel, setPrixM2Reel] = useState(0);
  useEffect(() => {
    // Initialisation / Mise à jour des données du bien via REDUX - prix_m2_vente_reel

    setPrixM2Reel(
      parseInt(props.bien.qualification_robot.prix.prix_bien_complet, 10) /
        parseInt(props.bien.surface, 10)
    );
  }, []);

  useEffect(() => {
    var latitudeIntermediaire = 0;
    var longitudeIntermediaire = 0;
    console.log("datalong", data);
    if (
      data.latitude !== undefined &&
      data.longitude !== undefined &&
      data.latitude !== 0 &&
      data.longitude !== 0
    ) {
      latitudeIntermediaire = data.latitude;
      longitudeIntermediaire = data.longitude;
    } else {
      if (
        dataCommune &&
        dataCommune.geometry &&
        dataCommune.geometry.coordinates
      ) {
        // Initialisation du contour de la commune
        let contourCommuneIntermediaire = [];

        if (dataCommune.geometry.type === "Polygon") {
          dataCommune.geometry.coordinates[0].forEach(function (element) {
            contourCommuneIntermediaire.push([element[1][0], element[0][0]]);
          });
        } else if (dataCommune.geometry.type === "MultiPolygon") {
          dataCommune.geometry.coordinates.forEach(function (polygon) {
            let poly = [[]];
            polygon[0].forEach(function (element) {
              poly[0].push([element[1], element[0]]);
            });

            contourCommuneIntermediaire.push(poly);
          });
        }

        const center = L.polygon(contourCommuneIntermediaire)
          .getBounds()
          .getCenter();
        latitudeIntermediaire = center[1];
        longitudeIntermediaire = center[0];
      }
    }

    setLatitude(latitudeIntermediaire);
    setLongitude(longitudeIntermediaire);
  }, [props.bien.latitude, props.bien.longitude, dataCommune]);
  useEffect(() => {
    let coordonne = {
      x: longitude,
      y: latitude,
      role: props.connectedUserRole,
    };
    console.log("getIrisFromCoordinates");

    if (
      !isEmpty(latitude) &&
      !isEmpty(latitude) &&
      longitude !== 0 &&
      latitude !== 0
    ) {
      console.log("getIrisFromCoordinates");
      props.getIrisFromCoordinates(longitude, latitude);
      props.getAdressFromCoordinates(coordonne);
    }
  }, [longitude, latitude]);
  let state = {
    series: [
      {
        name: "series1",
        data: axeX,
      },
    ],
    options: {
      chart: {
        height: 350,
        type: "area",
        toolbar: {
          show: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
      },
      xaxis: {
        categories: axeY,
      },
      tooltip: {
        x: {
          format: "dd/MM/yy HH:mm",
        },
      },
    },
  };

  const sortdata = (data) => {
    console.log(data);
    let x = [];
    let y = [];
    let arraytemp = [];
    data.map((item) => {
      arraytemp.push({ value: item[0], date: item[1] });
    });

    arraytemp.sort((a, b) => {
      return a.date - b.date;
    });
    arraytemp.shift();

    arraytemp.map((item, index) => {
      //ici le truc
      if (item.value != 0) {
        x.push(parseFloat(item.value).toFixed(0));
        y.push(item.date);
      }
    });

    setAxeX(x);
    setAxeY(y);

    console.log(arraytemp, "temptemp");
  };

  useEffect(() => {
    console.log(axeX, "X");
    console.log(axeY, "Y");
  }, [props.bien, axeX, axeY]);
  useEffect(() => {
    get_notaire_data({
      commune: props.bien.code_insee,
      type: props.bien.type_bien_corrige,
    }).then((res) => {
      sortdata(res.data);
    });
  }, []);

  useEffect(() => {
    // !isEmpty(props.dataIris) &&
    //   setPrixM2VenteIrisNotaire(
    //     props.dataCommune.oneCommune.properties.prix_m2_vente.source_wealzy
    //   );
    !isEmpty(props.dataCommune) &&
      setPrixM2VenteIrisNotaire(
        props.dataCommune.properties.prix_m2_vente.source_wealzy
      );
    !isEmpty(props.dataCommune) &&
      console.log(
        "prixM2Vente",
        props.dataCommune.properties.prix_m2_vente.source_wealzy
      );
  }, [JSON.stringify(props.dataIris)]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!isEmpty(nbePiece) && !isEmpty(props.dataCommune)) {
      // Paramètre Nbe de pièce pour récupérer les datas
      switch (true) {
        case nbePiece === 1:
          setNbePieceBienParam("t1");
          break;
        case nbePiece === 2:
          setNbePieceBienParam("t2");
          break;
        case nbePiece === 3:
          setNbePieceBienParam("t3");
          break;
        case nbePiece === 4:
          setNbePieceBienParam("t4");
          break;
        case nbePiece === 5:
          setNbePieceBienParam("t5");
          break;
        case nbePiece > 5:
          setNbePieceBienParam("t6plus");
          break;
        default:
          setNbePieceBienParam("all");
          break;
      }
    }

    // Paramètre Type de bien pour récupérer les datas
    if (typeBien === "Appartement" || typeBien === "Maison") {
      setTypeBienParam(typeBien);
    } else {
      setTypeBienParam("Appartement");
      setNbePieceBienParam("t2");
    }

    const tauxFraisAgence =
      props.bien.qualification_robot.prix.frais_agence / prixBienFAI;

    var tauxFraisAgenceEffect = 0.05;
    if (tauxFraisAgence > 0) {
      tauxFraisAgenceEffect =
        parseInt(tauxFraisAgence * 100 * 10, 10) / 10 / 100;
    } else {
      if (prixBienFAI > 0 && prixBienFAI <= 100000) {
        tauxFraisAgenceEffect = 0.1;
      } else if (prixBienFAI > 100000 && prixBienFAI <= 200000) {
        tauxFraisAgenceEffect = 0.08;
      } else if (prixBienFAI > 200000 && prixBienFAI <= 400000) {
        tauxFraisAgenceEffect = 0.06;
      } else if (prixBienFAI > 400000 && prixBienFAI <= 600000) {
        tauxFraisAgenceEffect = 0.05;
      } else {
        tauxFraisAgenceEffect = 0.05;
      }
    }

    // Récupération des données de prix m2 Vente des communes
    // if (!isEmpty(prixM2VenteIrisNotaire) && !isEmpty(prixM2VenteNotaire)) {
    //   console.log(
    //     "prixM2VenteIrisNotaire",
    //     parseInt(
    //       prixM2VenteIrisNotaire[prixM2VenteIrisNotaire.length - 1][
    //         typeBienParam
    //       ][nbePieceParam]["prix_m2_bas"],
    //       10
    //     )
    //   );
    //   if (
    //     parseInt(
    //       prixM2VenteIrisNotaire[prixM2VenteIrisNotaire.length - 1][
    //         typeBienParam
    //       ][nbePieceParam]["prix_m2_bas"],
    //       10
    //     ) === 0
    //   ) {
    //     if (
    //       prixM2VenteNotaire[prixM2VenteNotaire.length - 1][typeBienParam][
    //         nbePieceParam
    //       ]["nb_transactions"] < 20
    //     )
    //       nbePieceParam = "all";
    //     console.log(
    //       "1",
    //       prixM2VenteNotaire,
    //       ",",
    //       prixM2VenteNotaire.length - 1,
    //       ",",
    //       typeBienParam,
    //       ",",
    //       nbePieceParam
    //     );
    //     console.log(
    //       "a",
    //       parseInt(
    //         prixM2VenteNotaire[prixM2VenteNotaire.length - 1][typeBienParam][
    //           nbePieceParam
    //         ]["prix_m2_moyen"]
    //         // *(1 + tauxFraisAgenceEffect + 0.08)
    //       )
    //     );
    //     setPrixM2VenteIrisNotaireMin(
    //       parseInt(
    //         prixM2VenteNotaire[prixM2VenteNotaire.length - 1][typeBienParam][
    //           nbePieceParam
    //         ]["prix_m2_bas"]
    //         // *(1 + tauxFraisAgenceEffect + 0.08)
    //       )
    //     );
    //     setPrixM2VenteIrisNotaireMoy(
    //       parseInt(
    //         prixM2VenteNotaire[prixM2VenteNotaire.length - 1][typeBienParam][
    //           nbePieceParam
    //         ]["prix_m2_moyen"]
    //         // *(1 + tauxFraisAgenceEffect + 0.08)
    //       )
    //     );
    //     setPrixM2VenteIrisNotaireMax(
    //       parseInt(
    //         prixM2VenteNotaire[prixM2VenteNotaire.length - 1][typeBienParam][
    //           nbePieceParam
    //         ]["prix_m2_haut"]
    //         //*(1 + tauxFraisAgenceEffect + 0.08)
    //       )
    //     );
    //     console.log(
    //       "prix_haut",
    //       prixM2VenteNotaire[prixM2VenteNotaire.length - 1][typeBienParam][
    //         nbePieceParam
    //       ]
    //     );
    //   } else {
    //     if (
    //       prixM2VenteNotaire[prixM2VenteNotaire.length - 1][typeBienParam][
    //         nbePieceParam
    //       ]["nb_transactions"] < 20
    //     )
    //       nbePieceParam = "all";
    //     console.log(
    //       "2",
    //       prixM2VenteNotaire,
    //       ",",
    //       prixM2VenteNotaire.length - 1,
    //       ",",
    //       typeBienParam,
    //       ",",
    //       nbePieceParam
    //     );
    //     console.log(
    //       "b",
    //       parseInt(
    //         prixM2VenteNotaire[prixM2VenteNotaire.length - 1][typeBienParam][
    //           nbePieceParam
    //         ]["prix_m2_moyen"] *
    //           (1 + tauxFraisAgenceEffect + 0.08)
    //       )
    //     );

    //     setPrixM2VenteIrisNotaireMin(
    //       parseInt(
    //         prixM2VenteNotaire[prixM2VenteNotaire.length - 1][typeBienParam][
    //           nbePieceParam
    //         ]["prix_m2_bas"]
    //         // *(1 + tauxFraisAgenceEffect + 0.08)
    //       )
    //     );
    //     setPrixM2VenteIrisNotaireMoy(
    //       parseInt(
    //         prixM2VenteNotaire[prixM2VenteNotaire.length - 1][typeBienParam][
    //           nbePieceParam
    //         ]["prix_m2_moyen"]
    //         //  *(1 + tauxFraisAgenceEffect + 0.08)
    //       )
    //     );
    //     setPrixM2VenteIrisNotaireMax(
    //       parseInt(
    //         prixM2VenteNotaire[prixM2VenteNotaire.length - 1][typeBienParam][
    //           nbePieceParam
    //         ]["prix_m2_haut"]
    //         //*(1 + tauxFraisAgenceEffect + 0.08)
    //       )
    //     );
    //   }
    // }
  }, [
    nbePiece,
    JSON.stringify(prixM2VenteIrisNotaire),
    JSON.stringify(prixM2VenteNotaire),
  ]); // eslint-disable-line react-hooks/exhaustive-deps

  // Variables sur le prix de location retenue du bien
  var [prixLocationRetenueMensuel, setPrixLocationRetenueMensuel] = useState(0);
  //var [prixLocationRetenueAnnuel, setPrixLocationRetenueAnnuel] = useState(0)
  var [prixLocationRetenueM2, setPrixLocationRetenueM2] = useState(0);

  // Variable pour les prix m2 de la commune
  var [prixM2LocationCommuneMin, setPrixM2LocationCommuneMin] = useState(0);
  var [prixM2LocationCommuneMoy, setPrixM2LocationCommuneMoy] = useState(0);
  var [prixM2LocationCommuneMax, setPrixM2LocationCommuneMax] = useState(0);

  // Variable pour les prix d'une chambre en colocation au niveau Commune
  //var [prixM2ColocationCommuneMin, setPrixM2ColocationCommuneMin] = useState(0)
  //var [prixM2ColocationCommuneMoy, setPrixM2ColocationCommuneMoy] = useState(0)
  //var [prixM2ColocationCommuneMax, setPrixM2ColocationCommuneMax] = useState(0)

  useEffect(() => {
    // Initialisatiion / mise à jour du state prix_location_retenue_mensuel et prix_location_retenue_annuel si changement dans autre composant
    setPrixLocationRetenueMensuel(
      props.bien.qualification_robot.location.prix_location_retenue_mensuel
    );
  }, [props.bien.qualification_robot.location.prix_location_retenue_mensuel]);

  useEffect(() => {
    // Calcul du prix m2 réel de location
    setPrixLocationRetenueM2(
      parseInt((prixLocationRetenueMensuel / surface).toFixed(0), 10)
    );
  }, [prixLocationRetenueMensuel, surface]);

  useEffect(() => {
    // Récupération des données de prix m2 Location des communes et colocation
    if (
      !isEmpty(props.dataCommune) &&
      !isEmpty(props.dataCommune.properties) &&
      !isEmpty(props.dataCommune.properties.prix_m2_location)
    ) {
      let nbePieceParamUF = "all";
      let typeBienParamUF = typeBien;

      if (!isEmpty(nbePiece) && !isEmpty(props.dataCommune)) {
        // Paramètre Nbe de pièce pour récupérer les datas
        switch (true) {
          case nbePiece === 1:
            nbePieceParamUF = "t1";
            break;
          case nbePiece === 2:
            nbePieceParamUF = "t2";
            break;
          case nbePiece === 3:
            nbePieceParamUF = "t3";
            break;
          case nbePiece === 4:
            nbePieceParamUF = "t4";
            break;
          case nbePiece === 5:
            nbePieceParamUF = "t5";
            break;
          case nbePiece > 5:
            nbePieceParamUF = "t6plus";
            break;
          default:
            nbePieceParamUF = "all";
            break;
        }
      }

      // Paramètre Type de bien pour récupérer les datas
      if (typeBien === "Appartement" || typeBien === "Maison") {
        typeBienParamUF = typeBien;
      } else {
        typeBienParamUF = "Appartement";
        nbePieceParamUF = "t2";
      }

      if (
        dataCommune.properties.prix_m2_location[
          props.dataCommune.properties.prix_m2_location.length - 1
        ][typeBienParamUF][nbePieceParamUF]["nb_transactions"] < 20
      )
        nbePieceParamUF = "all";

      // Données de location niveau Commune
      props.dataCommune.properties.prix_m2_location
        ? setPrixM2LocationCommuneMin(
            parseInt(
              props.dataCommune.properties.prix_m2_location[
                props.dataCommune.properties.prix_m2_location.length - 1
              ][typeBienParamUF][nbePieceParamUF]["prix_m2_bas"],
              10
            )
          )
        : setPrixM2LocationCommuneMin(0);

      props.dataCommune.properties.prix_m2_location
        ? setPrixM2LocationCommuneMoy(
            parseInt(
              props.dataCommune.properties.prix_m2_location[
                props.dataCommune.properties.prix_m2_location.length - 1
              ][typeBienParamUF][nbePieceParamUF]["prix_m2_moyen"],
              10
            )
          )
        : setPrixM2LocationCommuneMoy(0);

      props.dataCommune.properties.prix_m2_location
        ? setPrixM2LocationCommuneMax(
            parseInt(
              props.dataCommune.properties.prix_m2_location[
                props.dataCommune.properties.prix_m2_location.length - 1
              ][typeBienParamUF][nbePieceParamUF]["prix_m2_haut"],
              10
            )
          )
        : setPrixM2LocationCommuneMax(0);
    }
  }, [
    props.dataCommune
      ? props.dataCommune.properties
        ? props.dataCommune.properties.prix_m2_location
        : ""
      : "",
  ]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    console.log(props.data);
  }, [props.data]);

  useEffect(() => {
    console.log(axeX, "axeX");
    console.log(axeY, "axeY");
  }, [axeX, axeY]);

  const str = "Données du marché";

  return (
    <Grid container>
      <Grid item xs={12} md={12}>
        <div className={classes.paper}>
          <Grid container>
            <Grid item xs={12} md={12}>
              <Grid container style={{ padding: "10px", width: "100%" }}>
                <Grid
                  item
                  xs={12}
                  md={4}
                  style={{
                    border: "solid",
                    height: "50%",
                    borderColor: "#f0f0f0",
                    width: "100%",
                    marginBottom: "20px",
                  }}
                >
                  <Typography
                    variant="h6"
                    gutterBottom
                    style={{ color: "#122659" }}
                  >
                    Evolution du marché à la vente
                  </Typography>
                  <div
                    style={{
                      width: "100%",
                      height: "40px",
                      color: "#28a745",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-around",
                      }}
                    >
                      {axeY.map((item, index) => (
                        <div>{item}</div>
                      ))}
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-evenly",
                      }}
                    >
                      {axeX.map((item, index) => (
                        <div>{parseInt(item, 10)}</div>
                      ))}
                    </div>
                  </div>

                  <div id="chart">
                    <Chart
                      options={state.options}
                      series={state.series}
                      type="area"
                      height={350}
                    />
                  </div>
                </Grid>
                <Grid container md={8}>
                  <Grid
                    container
                    xs={12}
                    md={12}
                    style={{ position: "relative", bottom: "8px" }}
                  >
                    <Grid item xs={12} md={8}>
                      <JaugePrix
                        style={{ height: "213px" }}
                        width="30px"
                        widthXS="30px"
                        widthSM="45px"
                        widthPrint="30px"
                        title={"Prix du marché à la vente"}
                        subtitle="Source Notaires + Wealzy 2022 | Quartier"
                        prixBas={parseInt(prixM2VenteIrisNotaireMin, 10)}
                        prixHaut={parseInt(prixM2VenteIrisNotaireMax, 10)}
                        prixMoyen={parseInt(prixM2VenteIrisNotaireMoy, 10)}
                        prixReel={prixM2Reel}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Card className={classes.card2}>
                        <CardContent className={classes.cardContent}>
                          <Typography
                            align={"center"}
                            gutterBottom
                            variant="h5"
                            component="h2"
                            className={classes.cardTitle}
                          >
                            Tension vente
                          </Typography>
                          <Typography
                            style={{ color: props.scolor }}
                            align={"center"}
                            variant="h4"
                            component="h3"
                            className={classes.cardValue}
                          >
                            {props.dataCommune &&
                            props.dataCommune.properties &&
                            props.dataCommune.properties.tension_immobiliere
                              ? parseInt(
                                  props.dataCommune.properties
                                    .tension_immobiliere.tension_vente,
                                  10
                                ) + " jr"
                              : "N.D."}
                          </Typography>
                          <Typography
                            paragraph
                            align={"center"}
                            variant="subtitle1"
                            color="textSecondary"
                            className={classes.cardSubtitle}
                          >
                            Nbe de jours moyen de vente
                          </Typography>
                        </CardContent>
                        <Typography
                          style={{ color: props.pcolor }}
                          paragraph
                          align={"center"}
                          variant="subtitle1"
                          color="textSecondary"
                          className={classes.cardSubtitle2}
                        >
                          Source Wealzy 2022
                        </Typography>
                      </Card>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    xs={12}
                    md={12}
                    style={{ position: "relative", top: "2px" }}
                  >
                    <Grid item xs={12} md={8}>
                      <JaugePrix
                        width="30px"
                        widthXS="30px"
                        widthSM="45px"
                        widthPrint="30px"
                        title={"Prix du marché à la location"}
                        subtitle="Source Wealzy 2022 | Quartier"
                        prixBas={prixM2LocationCommuneMin}
                        prixHaut={prixM2LocationCommuneMax}
                        prixMoyen={prixM2LocationCommuneMoy}
                        prixReel={prixLocationRetenueM2}
                        types={props.bien.annonceorbien}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Card className={classes.card2}>
                        <CardContent className={classes.cardContent}>
                          <Typography
                            align={"center"}
                            gutterBottom
                            variant="h5"
                            component="h2"
                            className={classes.cardTitle}
                          >
                            Tension locat.
                          </Typography>
                          <Typography
                            style={{ color: props.scolor }}
                            align={"center"}
                            variant="h4"
                            component="h3"
                            className={classes.cardValue}
                          >
                            {props.dataCommune &&
                            props.dataCommune.properties &&
                            props.dataCommune.properties.tension_immobiliere
                              ? parseInt(
                                  props.dataCommune.properties
                                    .tension_immobiliere.tension_location,
                                  10
                                ) + " jr"
                              : "N.D."}{" "}
                          </Typography>
                          <Typography
                            paragraph
                            align={"center"}
                            variant="subtitle1"
                            color="textSecondary"
                            className={classes.cardSubtitle}
                          >
                            Nbe de jours moyen de mise en location
                          </Typography>
                        </CardContent>
                        <Typography
                          style={{ color: props.pcolor }}
                          paragraph
                          align={"center"}
                          variant="subtitle1"
                          color="textSecondary"
                          className={classes.cardSubtitle2}
                        >
                          Source Wealzy 2020
                        </Typography>
                      </Card>
                    </Grid>
                  </Grid>
                </Grid>
                {/* <Grid container md={3}>
                  <Grid item xs={12} md={12}>
                    <Card className={classes.card2}>
                      <CardContent className={classes.cardContent}>
                        <Typography
                          align={"center"}
                          gutterBottom
                          variant="h5"
                          component="h2"
                          className={classes.cardTitle}
                        >
                          Tension vente
                        </Typography>
                        <Typography
                          style={{ color: props.scolor }}
                          align={"center"}
                          variant="h4"
                          component="h3"
                          className={classes.cardValue}
                        >
                          {props.dataCommune &&
                          props.dataCommune.properties &&
                          props.dataCommune.properties.tension_immobiliere
                            ? parseInt(
                                props.dataCommune.properties.tension_immobiliere
                                  .tension_vente,
                                10
                              ) + " jr"
                            : "N.D."}
                        </Typography>
                        <Typography
                          paragraph
                          align={"center"}
                          variant="subtitle1"
                          color="textSecondary"
                          className={classes.cardSubtitle}
                        >
                          Nbe de jours moyen de vente
                        </Typography>
                      </CardContent>
                      <Typography
                        style={{ color: props.pcolor }}
                        paragraph
                        align={"center"}
                        variant="subtitle1"
                        color="textSecondary"
                        className={classes.cardSubtitle2}
                      >
                        Source Wealzy 2020
                      </Typography>
                    </Card>
                    <Card className={classes.card2}>
                      <CardContent className={classes.cardContent}>
                        <Typography
                          align={"center"}
                          gutterBottom
                          variant="h5"
                          component="h2"
                          className={classes.cardTitle}
                        >
                          Tension locat.
                        </Typography>
                        <Typography
                          style={{ color: props.scolor }}
                          align={"center"}
                          variant="h4"
                          component="h3"
                          className={classes.cardValue}
                        >
                          {props.dataCommune &&
                          props.dataCommune.properties &&
                          props.dataCommune.properties.tension_immobiliere
                            ? parseInt(
                                props.dataCommune.properties.tension_immobiliere
                                  .tension_location,
                                10
                              ) + " jr"
                            : "N.D."}{" "}
                        </Typography>
                        <Typography
                          paragraph
                          align={"center"}
                          variant="subtitle1"
                          color="textSecondary"
                          className={classes.cardSubtitle}
                        >
                          Nbe de jours moyen de mise en location
                        </Typography>
                      </CardContent>
                      <Typography
                        style={{ color: props.pcolor }}
                        paragraph
                        align={"center"}
                        variant="subtitle1"
                        color="textSecondary"
                        className={classes.cardSubtitle2}
                      >
                        Source Wealzy 2020
                      </Typography>
                    </Card>
                  </Grid>
                </Grid> */}
              </Grid>
            </Grid>
            <Grid item xs={12} md={12}>
              <Grid container style={{}}>
                <Grid item xs={12} md={3}>
                  <Card className={classes.card2}>
                    <CardContent className={classes.cardContent}>
                      <Typography
                        align={"center"}
                        gutterBottom
                        variant="h5"
                        component="h2"
                        className={classes.cardTitle}
                      >
                        Pop. commune
                      </Typography>
                      <Typography
                        style={{ color: props.scolor }}
                        align={"center"}
                        variant="h4"
                        component="h3"
                        className={classes.cardValue}
                      >
                        {props.dataCommune
                          ? props.dataCommune.properties
                            ? (props.dataCommune.properties.commune_population
                                .evolution_3_ans > 0
                                ? "+" +
                                  props.dataCommune.properties
                                    .commune_population.evolution_3_ans
                                : props.dataCommune.properties
                                    .commune_population.evolution_3_ans) + " %"
                            : "Indisponible"
                          : "Indisponible"}{" "}
                      </Typography>
                      <Typography
                        paragraph
                        align={"center"}
                        variant="subtitle1"
                        color="textSecondary"
                        className={classes.cardSubtitle}
                      >
                        Evolution entre 2014 et 2016
                      </Typography>
                    </CardContent>
                    <Typography
                      style={{ color: props.pcolor }}
                      paragraph
                      align={"center"}
                      variant="subtitle1"
                      color="textSecondary"
                      className={classes.cardSubtitle2}
                    >
                      Source Insee 2016
                    </Typography>
                  </Card>
                </Grid>
                <Grid item xs={12} md={3}>
                  <Card className={classes.card2}>
                    <CardContent className={classes.cardContent}>
                      <Typography
                        align={"center"}
                        gutterBottom
                        variant="h5"
                        component="h2"
                        className={classes.cardTitle}
                      >
                        Population quartier
                      </Typography>
                      <Typography
                        style={{ color: props.scolor }}
                        align={"center"}
                        variant="h4"
                        component="h3"
                        className={classes.cardValue}
                      >
                        {props.dataIris
                          ? props.dataIris.properties
                            ? props.dataIris.properties.iris_population
                                .evolution_3_ans
                              ? (props.dataIris.properties.iris_population
                                  .evolution_3_ans > 0
                                  ? "+" +
                                    props.dataIris.properties.iris_population
                                      .evolution_3_ans
                                  : props.dataIris.properties.iris_population
                                      .evolution_3_ans) + " %"
                              : "Indisponible"
                            : "Indisponible"
                          : "Indisponible"}{" "}
                      </Typography>
                      <Typography
                        paragraph
                        align={"center"}
                        variant="subtitle1"
                        color="textSecondary"
                        className={classes.cardSubtitle}
                      >
                        Evolution entre 2014 et 2016
                      </Typography>
                    </CardContent>
                    <Typography
                      style={{ color: props.pcolor }}
                      paragraph
                      align={"center"}
                      variant="subtitle1"
                      color="textSecondary"
                      className={classes.cardSubtitle2}
                    >
                      Source Insee 2016
                    </Typography>
                  </Card>
                </Grid>
                <Grid item xs={12} md={3}>
                  <Card className={classes.card2}>
                    <CardContent className={classes.cardContent}>
                      <Typography
                        align={"center"}
                        gutterBottom
                        variant="h5"
                        component="h2"
                        className={classes.cardTitle}
                      >
                        Vacance loc. com.
                      </Typography>
                      <Typography
                        style={{ color: props.scolor }}
                        align={"center"}
                        variant="h4"
                        component="h3"
                        className={classes.cardValue}
                      >
                        {props.dataCommune
                          ? props.dataCommune.properties
                            ? parseInt(
                                props.dataCommune.properties.logements_vacants
                                  .taux,
                                10
                              ) + " %"
                            : "Indisponible"
                          : "Indisponible"}
                      </Typography>
                      <Typography
                        paragraph
                        align={"center"}
                        variant="subtitle1"
                        color="textSecondary"
                        className={classes.cardSubtitle}
                      >
                        Niveau commune
                      </Typography>
                    </CardContent>
                    <Typography
                      style={{ color: props.pcolor }}
                      paragraph
                      align={"center"}
                      variant="subtitle1"
                      color="textSecondary"
                      className={classes.cardSubtitle2}
                    >
                      Source Insee 2016
                    </Typography>
                  </Card>
                </Grid>
                <Grid item xs={12} md={3}>
                  <Card className={classes.card2}>
                    <CardContent className={classes.cardContent}>
                      <Typography
                        align={"center"}
                        gutterBottom
                        variant="h5"
                        component="h2"
                        className={classes.cardTitle}
                      >
                        Vacance loc. quart.
                      </Typography>
                      <Typography
                        style={{ color: props.scolor }}
                        align={"center"}
                        variant="h4"
                        component="h3"
                        className={classes.cardValue}
                      >
                        {props.dataIris
                          ? props.dataIris.properties
                            ? props.dataIris.properties.logements_vacants
                              ? parseInt(
                                  props.dataIris.properties.logements_vacants
                                    .taux,
                                  10
                                ) + " %"
                              : "Indisponible"
                            : "Indisponible"
                          : "Indisponible"}{" "}
                      </Typography>
                      <Typography
                        paragraph
                        align={"center"}
                        variant="subtitle1"
                        color="textSecondary"
                        className={classes.cardSubtitle}
                      >
                        Niveau quartier
                      </Typography>
                    </CardContent>
                    <Typography
                      style={{ color: props.pcolor }}
                      paragraph
                      align={"center"}
                      variant="subtitle1"
                      color="textSecondary"
                      className={classes.cardSubtitle2}
                    >
                      Source Insee 2016
                    </Typography>
                  </Card>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  dataBien: state.bien.bien,
  dataCommune: state.communes.oneCommune,
  dataIris: state.iris.oneIris,
  centerCommune: state.districts.districts,
  connectedUserRole: state.auth.user.role,
});

export default connect(mapStateToProps, {
  getCommuneFromCoordinates,
  getCommuneByInsee,
  getIrisFromCoordinates,
  getDistrict,
  getAdressFromCoordinates,
})(DonnesDuMarche);
