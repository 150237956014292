import React, { useEffect, useState, useDebugValue } from "react";

export default function useJaugeData(datacom, dataBien) {
  const [MinValue, setMinValue] = useState(1000);
  const [MaxValue, setMaxValue] = useState(3000);
  const [MoyValue, setMoyvalue] = useState(2000);
  const [prixM2VenteNotaire, setPrixM2VenteNotaire] = useState([]);

  useEffect(() => {
    console.log("datacomm", datacom);
    if (datacom !== null) {
      setPrixM2VenteNotaire(datacom.properties.prix_m2_vente.source_wealzy);
    }
  }, [datacom]);

  useEffect(() => {
    console.log(
      dataBien?.type_bien_corrige !== undefined,
      "jauge2",
      dataBien?.type_bien_corrige
    );
    if (
      datacom !== null &&
      prixM2VenteNotaire.length > 0 &&
      dataBien?.nbe_piece !== null &&
      dataBien?.type_bien_corrige !== undefined
    ) {
      if (
        prixM2VenteNotaire.length > 0 &&
        dataBien?.type_bien_corrige !== "Terrain"
      ) {
        if (
          prixM2VenteNotaire[prixM2VenteNotaire.length - 1][
            dataBien?.type_bien_corrige === "Maison" ||
            dataBien?.type_bien_corrige === "Appartement"
              ? dataBien?.type_bien_corrige
              : "Appartement"
          ][
            `t${
              dataBien?.nbe_piece > 5 || dataBien?.nbe_piece === undefined
                ? "6plus"
                : dataBien?.nbe_piece
            }`
          ]["nb_transactions"] < 20
        ) {
          setMinValue(
            parseInt(
              prixM2VenteNotaire[prixM2VenteNotaire.length - 1][
                dataBien?.type_bien_corrige === "Maison" ||
                dataBien?.type_bien_corrige === "Appartement"
                  ? dataBien?.type_bien_corrige
                  : "Appartement"
              ]["all"]["prix_m2_bas"],
              10
            )
          );

          setMoyvalue(
            parseInt(
              prixM2VenteNotaire[prixM2VenteNotaire.length - 1][
                dataBien?.type_bien_corrige === "Maison" ||
                dataBien?.type_bien_corrige === "Appartement"
                  ? dataBien?.type_bien_corrige
                  : "Appartement"
              ]["all"]["prix_m2_moyen"],
              10
            )
          );

          setMaxValue(
            parseInt(
              prixM2VenteNotaire[prixM2VenteNotaire.length - 1][
                dataBien?.type_bien_corrige === "Maison" ||
                dataBien?.type_bien_corrige === "Appartement"
                  ? dataBien?.type_bien_corrige
                  : "Appartement"
              ][`all`]["prix_m2_haut"],
              10
            )
          );
        } else {
        
          setMinValue(
            parseInt(
              prixM2VenteNotaire[prixM2VenteNotaire.length - 1][
                dataBien?.type_bien_corrige === "Maison" ||
                dataBien?.type_bien_corrige === "Appartement"
                  ? dataBien?.type_bien_corrige
                  : "Appartement"
              ][
                `t${
                  dataBien?.nbe_piece > 5 || dataBien?.nbe_piece === undefined
                    ? "6plus"
                    : dataBien?.nbe_piece
                }`
              ]["prix_m2_bas"],
              10
            )
          );

          setMoyvalue(
            parseInt(
              prixM2VenteNotaire[prixM2VenteNotaire.length - 1][
                dataBien?.type_bien_corrige === "Maison" ||
                dataBien?.type_bien_corrige === "Appartement"
                  ? dataBien?.type_bien_corrige
                  : "Appartement"
              ][`t${dataBien?.nbe_piece > 5 ? "6plus" : dataBien?.nbe_piece}`][
                "prix_m2_moyen"
              ],
              10
            )
          );

          setMaxValue(
            parseInt(
              prixM2VenteNotaire[prixM2VenteNotaire.length - 1][
                dataBien?.type_bien_corrige === "Maison" ||
                dataBien?.type_bien_corrige === "Appartement"
                  ? dataBien?.type_bien_corrige
                  : "Appartement"
              ][`t${dataBien?.nbe_piece > 5 ? "6plus" : dataBien?.nbe_piece}`][
                "prix_m2_haut"
              ],
              10
            )
          );
        }
      }
    }
  }, [prixM2VenteNotaire]);

  useEffect(() => {}, [dataBien, MinValue, MaxValue, MoyValue]);
  useDebugValue(`prixM2VenteNotaire ${prixM2VenteNotaire}`);
  useDebugValue(`prixM2VenteNotaire ${prixM2VenteNotaire}`);
  useDebugValue(`datacomm ${datacom}`);
  useDebugValue(`dataBien: ${dataBien}`);

  return [MinValue, MaxValue, MoyValue];
}
