//
// /!\ OLD /!\
//
import {
  GET_IRIS_AROUND,
  GET_IRIS_FROM_COORD,
  GET_IRIS_FOR_ONE_COMMUNE
} from '../actions/types';

const initialState = {
  oneIris: null,
  contour: null
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_IRIS_FROM_COORD:
      return {
        ...state,
        oneIris: action.payload,
        contour: state.contour
      }
    case GET_IRIS_AROUND:
      return {
        ...state,
        contour: action.payload
      };
    case GET_IRIS_FOR_ONE_COMMUNE:
      return {
        ...state,
        listeIrisOneCommune: action.payload
      };
    default:
      return state;
  }
}
//
// /!\ OLD /!\
//