import {
  GET_COMMUNE_FROM_COORD,
  GET_COMMUNE_AROUND,
  GET_CONTOUR_COMMUNE_ALL,
  GET_COMMUNE_EXTREMA,
  SEARCH_COMMUNE_COORDINATES,
  GET_COMMUNE_BY_INSEE
} from '../actions/types';

const initialState = {
  oneCommune: null,
  contour: null,
  extremas: []
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_COMMUNE_FROM_COORD:
      return {
        ...state,
        oneCommune: action.payload,
        contour: state.contour
      }
    case GET_COMMUNE_BY_INSEE:
      return {
        ...state,
        oneCommune: action.payload[0],
      }
    case GET_COMMUNE_AROUND:
    case GET_CONTOUR_COMMUNE_ALL:
      return {
        ...state,
        contour: action.payload
      }
    case GET_COMMUNE_EXTREMA:
      return {
        ...state,
        extremas: action.payload
      }
    case SEARCH_COMMUNE_COORDINATES:
      return {
        ...state,
        communeCoordinates: action.payload
      };
    default:
      return state;
  }
}
