import {
  SET_CURRENT_USER,
  GET_ALL_USERS,
  GET_GESTIONNAIRE,
  GET_ALL_CLIENT_GESTIONNAIRE,
  GET_ALL_GESTIONNAIRE_ADMIN,
  DELETE_USER,
  ALERT_ACTIVER,
  ALERT_DESACTIVER,
  ALERT,
  RESET_USER,
  UPDATE_USER,
  GET_USER,
  UPDATE_USER_PHOTO,
  UPDATE_USER_COMPTE,
  UPDATE_USER_INFOS,
  UPDATE_USER_INFOS_ENTREPRISE,
  UPDATE_USER_CRITERE_RECHERCHE,
  UPDATE_USER_MANDAT,
  GET_CREATE_OR_UPDATE_USER_ERRORS,
  UPDATE_USER_ERRORS,
  UPDATE_DATA_USER_NOT_YET,
  UPDATE_WALLET_CLIENT,
  DELETE_BIEN_WALLET,
  REINIT_USER_MISE_A_JOUR,
  UPDATE_IDSTRIPE,
  UPDATE_IDSUB,
  UPDATE_ROLE,
  UPDATE_MAIL,
  UPDATE_USER_COLORS,
  LOGOUT_USER,
  USER_CLIENT,
  USER_GEST,
  USER_GUIDE,
} from "../actions/types";
const { isEmpty } = require("../shared/functions/isEmpty");

const initialState = {
  isAuthenticated: false,
  user: {},
  couleurprimaire: "",
  couleursecondaire: "",
  userToAddOrUpdate: {},
  userClient: {},
  userGest: {},
  userGuide: {
    nav: !localStorage.getItem("nav") ? "false" : localStorage.getItem("nav"),
    residentiel: !localStorage.getItem("residentiel")
      ? "false"
      : localStorage.getItem("residentiel"),
    localisation: !localStorage.getItem("localisation")
      ? "false"
      : localStorage.getItem("localisation"),
    client: !localStorage.getItem("client")
      ? "false"
      : localStorage.getItem("client"),
    chercheL: !localStorage.getItem("chercheL")
      ? "false"
      : localStorage.getItem("chercheL"),
    annonceD: !localStorage.getItem("annonceD")
      ? "false"
      : localStorage.getItem("annonceD"),
    modelR: !localStorage.getItem("modelR")
      ? "false"
      : localStorage.getItem("modelR"),
    annoncePdf: !localStorage.getItem("annoncePdf")
      ? "false"
      : localStorage.getItem("annoncePdf"),
    btnadd: !localStorage.getItem("btnadd")
      ? "false"
      : localStorage.getItem("btnadd"),
  },
  miseAJour: 0,
  listeClient: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case UPDATE_USER_PHOTO: {
      let updateduser = state.user;
      updateduser.logo_entreprise = action.payload.logo_entreprise;

      return {
        ...state,
        user: updateduser,
      };
    }

    case LOGOUT_USER: {
      return {
        ...state,
        isAuthenticated: false,
        user: {},
      };
    }
    case SET_CURRENT_USER:
      return {
        ...state,
        isAuthenticated: !isEmpty(action.payload),
        user: action.payload,
      };
    case GET_ALL_USERS:
      return {
        ...state,
        listeUsers: action.payload,
      };
    case GET_ALL_CLIENT_GESTIONNAIRE:
      return {
        ...state,
        listeClient: action.payload,
      };
    case GET_ALL_GESTIONNAIRE_ADMIN:
      return {
        ...state,
        listeGestionnaire: action.payload,
      };
    case GET_GESTIONNAIRE:
      return {
        ...state,
        gestionnaire: action.payload["gestionnaire"],
      };
    case GET_USER:
      return {
        ...state,
        userToAddOrUpdate: action.payload,
      };
    case RESET_USER:
      return {
        ...state,
        userToAddOrUpdate: action.payload,
      };
    case USER_CLIENT:
      return {
        ...state,
        userClient: action.payload,
      };
    case USER_GEST:
      return {
        ...state,
        userGest: action.payload,
      };
    case USER_GUIDE:
      return {
        ...state,
        userGuide: action.payload,
      };
    case DELETE_USER:
      return {
        ...state,
        deletedUser: action.payload,
      };
    case ALERT:
      return {
        ...state,
        AlertedUser: action.payload,
      };
    case ALERT_ACTIVER:
      return {
        ...state,
        AlertedUser1: action.payload,
      };
    case ALERT_DESACTIVER:
      return {
        ...state,
        AlertedUser2: action.payload,
      };

    case UPDATE_USER_COLORS:
      return {
        ...state,
        couleurprimaire: action.payload.pcolor,
        couleursecondaire: action.payload.scolor,
      };

    case UPDATE_USER:
      return {
        ...state,
        miseAJour: 1,
      };
    case REINIT_USER_MISE_A_JOUR:
      return {
        ...state,
        miseAJour: 0,
      };
    case UPDATE_USER_COMPTE:
      return {
        ...state,
        userToAddOrUpdate: {
          ...state.userToAddOrUpdate,
          email: action.payload.email,
          password: action.payload.password,
          password2: action.payload.password,
          passwordToCheck: action.payload.passwordToCheck,
          role: action.payload.role,
          gestionnaire: action.payload.gestionnaire,
          admin_gestionnaire: action.payload.admin_gestionnaire,
        },
      };
    case UPDATE_USER_INFOS:
      return {
        ...state,
        userToAddOrUpdate: {
          ...state.userToAddOrUpdate,
          nom: action.payload.nom,
          prenom: action.payload.prenom,
          telephone: action.payload.telephone,
          adresse: action.payload.adresse,
        },
      };
    case UPDATE_USER_INFOS_ENTREPRISE:
      return {
        ...state,
        userToAddOrUpdate: {
          ...state.userToAddOrUpdate,
          raison_sociale: action.payload.raison_sociale,
          siret: action.payload.siret,
          adresse_entreprise: action.payload.adresse_entreprise,
          telephone_entreprise: action.payload.telephone_entreprise,
          email_entreprise: action.payload.email_entreprise,
          logo_entreprise: action.payload.logo_entreprise,
          siteweb_entreprise: action.payload.siteweb_entreprise,
        },
      };
    case UPDATE_USER_CRITERE_RECHERCHE:
      return {
        ...state,
        userToAddOrUpdate: {
          ...state.userToAddOrUpdate,
          departement_cible: action.payload.departement_cible,
          commune_cible: action.payload.commune_cible,
          budget: action.payload.budget,
          plus_value: action.payload.plus_value,
          type_bien: action.payload.type_bien,
          rendement: action.payload.rendement,
          surface: action.payload.surface,
          nbe_piece: action.payload.nbe_piece,
          commentaire: action.payload.commentaire,
          caract_bien:action.payload.caract_bien,
          nbe_etage:action.payload.nbe_etage,
          prix_m2:action.payload.prix_m2,
          etatBien:action.payload.etatBien
        },
      };
    case UPDATE_USER_MANDAT:
      return {
        ...state,
        userToAddOrUpdate: {
          ...state.userToAddOrUpdate,
          statut_client: action.payload.statut_client,
          statut_sprint: action.payload.statut_sprint,
          date_sprint: action.payload.date_sprint,
          date_fin_sprint: action.payload.date_fin_sprint,
          bien_propose: action.payload.bien_propose,
        },
      };
    case GET_CREATE_OR_UPDATE_USER_ERRORS:
      return {
        ...state,
        userToAddOrUpdate: {
          ...state.userToAddOrUpdate,
          errors: action.payload,
        },
      };
    case UPDATE_USER_ERRORS:
      return {
        ...state,
        userToAddOrUpdate: {
          ...state.userToAddOrUpdate,
          errors: {
            ...state.userToAddOrUpdate.errors,
            [action.payload.name]: action.payload.value,
          },
        },
      };
    case UPDATE_DATA_USER_NOT_YET:
      return {
        ...state,
        miseAJour: 0,
      };
    case UPDATE_WALLET_CLIENT:
      return {
        ...state,
        userToAddOrUpdate: action.payload,
        miseAJour: 1,
      };
    case DELETE_BIEN_WALLET:
      return {
        ...state,
        miseAJour: 1,
      };
    case UPDATE_IDSTRIPE:
      return {
        ...state,
        user: {
          ...state.user,
          IDStripe: action.payload,
        },
      };
    case UPDATE_IDSUB:
      return {
        ...state,
        user: {
          ...state.user,
          IdSub: action.payload,
        },
      };
    case UPDATE_ROLE:
      return {
        ...state,
        user: {
          ...state.user,
          role: action.payload,
        },
      };
    case UPDATE_MAIL:
      return {
        ...state,
        user: {
          ...state.user,
          mailVerified: action.payload,
        },
      };
    default:
      return state;
  }
}
