import React, { useState, useEffect } from "react";
import "./SectionPlan.css";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import axios from "axios";
import Button from "@mui/material/Button";
import Grid from "@material-ui/core/Grid";
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';

import { add_plans_to_bien } from "../../../../actions/bienActions";
const Input = styled("input")({
  display: "none",
});

const SectionPlan = (props) => {
  const [files, Setfiles] = useState([]);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }
  const fileSelectedHandler = (e) => {
    console.log(' e.target.files', e.target.files)
    Setfiles({ imgCollection: e.target.files });
  };

  const uploadHandler = (e) => {
    e.preventDefault();
    var formData = new FormData();
    for (const key of Object.keys(files.imgCollection)) {
      formData.append("imgCollection", files.imgCollection[key]);
    }
    formData.append("id", props.bien[0]._id);
    add_plans_to_bien(props.bien[0]._id, formData).then((res) => {
      console.log(res);
      props.updateplans();
    });
  };
  if (props.bien[0].plans.length <= 0) {
    return (
      <div class="containers">
        <h3>Aucun plan associé avec ce bien</h3>
        <div>
          sélectionner les plans à ajouter
          <label htmlFor="icon-button-file">
            <Input
              //accept="image/*"
              multiple
              onChange={(e) => {
                fileSelectedHandler(e);
              }}
              id="icon-button-file"
              type="file"
            />
            <IconButton
              color="primary"
              aria-label="upload picture"
              component="span"
            >
              <PhotoCamera sx={{ fontSize: "30px", color: "#122659" }} />
            </IconButton>
            puis cliquer sur
            <Button
              onClick={uploadHandler}
              style={{
                marginRight: "10px",
                backgroundColor: "#122659",
                width: "90px",
                fontSize: "10px",
                marginLeft: "10px",
              }}
              variant="contained"
            >
              Télécharger
            </Button>
          </label>
        </div>
      </div>
    );
  } else {
    return (
      <Grid
        container
        spacing={2}
        style={{ width: "100%", height: "fitt-content" }}
      >
        {props.bien[0]?.plans?.map((plan, index) => (
          <Grid
            item
            xs={4}
            md={4}
            style={{
              width: "100%",
             height: "200px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            {
              plan.includes('pdf') ?
              <a href={plan} target="_blank">
              <Document file={plan} onLoadSuccess={onDocumentLoadSuccess}  options={{
           workerSrc:
             "//cdn.jsdelivr.net/npm/react-pdf@4.0.0-beta.5/dist/pdf.worker.entry.js" }}
             >
             <Page pageNumber={pageNumber} />
           </Document>
           </a>
         
             :
             <img
             style={{ width: "100%", height: "100%" }}
             src={plan}
             alt={plan}
           />
        
            }
          
           
          </Grid>
        ))}
      </Grid>
    );
  }
};
export default SectionPlan;
