import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import TextField from '@material-ui/core/TextField';
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Paper } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import BedOutlinedIcon from "@mui/icons-material/BedOutlined";
import BathtubOutlinedIcon from "@mui/icons-material/BathtubOutlined";
import KitchenOutlinedIcon from "@mui/icons-material/KitchenOutlined";
import EngineeringOutlinedIcon from "@mui/icons-material/EngineeringOutlined";
import LocalParkingOutlinedIcon from "@mui/icons-material/LocalParkingOutlined";
import BalconyOutlinedIcon from "@mui/icons-material/BalconyOutlined";
import DeckOutlinedIcon from "@mui/icons-material/DeckOutlined";
import ElevatorOutlinedIcon from "@mui/icons-material/ElevatorOutlined";
import HSBar from "react-horizontal-stacked-bar-chart";
import { Map, TileLayer, Marker, ScaleControl } from "react-leaflet";
import { getServicesAround } from "../../../../actions/servicesActions";

import L from "leaflet";
import { getMarkersAroundConcat } from "../../../../actions/geographicActions";
import busIcon from "../../../../img/markers/bus.png";
import metroIcon from "../../../../img/markers/metro.png";
import rerIcon from "../../../../img/markers/rer.png";
import terIcon from "../../../../img/markers/ter.png";
import tramwayIcon from "../../../../img/markers/tramway.png";
import trainIcon from "../../../../img/markers/train.png";
import tgvIcon from "../../../../img/markers/tgv.png";
import ecoleIcon from "../../../../img/markers/ecole.png";
import collegeIcon from "../../../../img/markers/college.png";
import lyceeIcon from "../../../../img/markers/lycee.png";
import ecoleSuperieureIcon from "../../../../img/markers/ecole-superieure.png";
import commissariatIcon from "../../../../img/markers/commissariat.png";
import mairieIcon from "../../../../img/markers/mairie.png";
import alimentationIcon from "../../../../img/markers/alimentation.png";
import grandeDistribIcon from "../../../../img/markers/grandeDistrib.png";
import commercesIcon from "../../../../img/markers/commerces.png";
import banqueIcon from "../../../../img/markers/banque.png";

import "./DetailBien.css";
const {
  getListeCategories,
  getCategorie,
} = require("../../../../shared/functions/getServiceGroupCategorie");

const { isEmpty } = require("../../../../shared/functions/isEmpty");

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(0),
    marginLeft: "auto",
    marginRight: "auto",
    height: "210mm",
    width: "297mm",
    ["@media (max-width:800px)"]: {
      // eslint-disable-line no-useless-computed-key
      width: "100%",
      height: "fit-content",
    },
    padding: theme.spacing(2),
    breakAfter: "page",
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(1),
    },
    "@media print": {
      height: "209.5mm",
      width: "297mm",
      padding: theme.spacing(0),
      borderRadius: 0,
      //border: '1px solid ' + theme.palette.primary.main
    },
  },
  vision:{
    marginTop: "10px",
                    backgroundColor: "#f2f2f2",
                    textAlign: "start",
                    minHeight: "400px",
                    overflow: "scroll",
                    maxHeight: "400px",
                    ["@media (max-width:1000px)"]: {
                      // eslint-disable-line no-useless-computed-key
                      marginTop: "10px",
                      backgroundColor: "#f2f2f2",
                      textAlign: "start",
                      minHeight: "auto",
                      overflow: "scroll",
                      maxHeight: "400px",
                    },
  },
  hidden: {
    display: "none",
  },
  maps:{
    border: "solid",
    borderColor: "#f0f0f0",
    position: "relative",
    top: "12px",
    marginBottom: "24px",
    ["@media (max-width:1000px)"]: {
      border: "solid",
      borderColor: "#f0f0f0",
      position: "relative",
      top: "12px",
      marginBottom: "24px",
      height:"450px"

      // eslint-disable-line no-useless-computed-key
    },
  }
  ,
  cardMap: {
    margin: theme.spacing(1),
    borderColor: "#eee",
    borderStyle: "solid",
    borderRadius: "4px",
    boxShadow: "none",
    height: "50%",
    ["@media (max-width:1000px)"]: {
      margin: theme.spacing(1),
    borderColor: "#eee",
    borderStyle: "solid",
    borderRadius: "4px",
    boxShadow: "none",
    height: "60%",
    }
  },
  root: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
    width: "100%",
  },
  gridList: {},
  gridTilePortrait: {
    height: "auto",
    width: "100%",
    minHeight: "100%",
    maxWidth: "100%",
  },
  gridTileLandscape: {
    height: "100%",
    width: "auto",
    maxHeight: "100%",
    minWidth: "100%",
  },
  cardValue: {
    fontSize: "1.6rem",
    color: theme.palette.secondary.main,
  },
  card2: {
    margin: theme.spacing(1),
    padding: theme.spacing(2),
    height: "162px",
    borderColor: "#eee",
    borderStyle: "solid",
    borderRadius: "4px",
    boxShadow: "none",
    position: "relative",
  },
  card: {
    margin: theme.spacing(1),
    padding: theme.spacing(2),
    height: "192px",
    borderColor: "#eee",
    borderStyle: "solid",
    borderRadius: "4px",
    boxShadow: "none",
    position: "relative",
    [theme.breakpoints.down("xs")]: {},
  },
  cardContent: {
    padding: 0,
  },

  cardSubtitle: {
    fontSize: "0.8rem",
    color: theme.palette.primary.main,
  },
  cardSubtitle2: {
    fontSize: "0.8rem",
    color: theme.palette.primary.light,
    bottom: 0,
    position: "absolute",
    margin: 0,
    paddingBottom: theme.spacing(0),
  },
}));

const DetailBien = (props) => {
  const classes = useStyles();
  const matches = useMediaQuery((theme) => theme.breakpoints.down("xs"));
  const [busMarkers, setBusMarkers] = useState([]);
  const [markerscount, setMarkercount] = useState([]);
  const [markers, setMarkers] = useState([]);
  const [categoriesCounter, setCategoriesCounter] = useState([]);
  const [comstat, setcomstat] = useState();
  const [zoom, setzoom] = useState(14);

  // Coordonnées GPS du bien
  const longitude = props.bien.longitude;
  const latitude = props.bien.latitude;

  useEffect(() => {
    if (!isEmpty(longitude) && !isEmpty(latitude)) {
      props.getMarkersAroundConcat(longitude, latitude, 16.5, "bus");
      console.log(props.markers, "bus", props.markers.length);
      props.getMarkersAroundConcat(longitude, latitude, 16.5, "metro");
      props.getMarkersAroundConcat(longitude, latitude, 16.5, "rer");
      props.getMarkersAroundConcat(longitude, latitude, 16.5, "ter");
      props.getMarkersAroundConcat(longitude, latitude, 16.5, "tramway");
      props.getMarkersAroundConcat(longitude, latitude, 16.5, "train");
      props.getMarkersAroundConcat(longitude, latitude, 16.5, "tgv");
      props.getMarkersAroundConcat(longitude, latitude, 16.5, "ecole");
      props.getMarkersAroundConcat(longitude, latitude, 16.5, "college");
      props.getMarkersAroundConcat(longitude, latitude, 16.5, "lycee");
      props.getMarkersAroundConcat(
        longitude,
        latitude,
        16.5,
        "ecole-superieure"
      );
      props.getMarkersAroundConcat(
        longitude,
        latitude,
        16.5,
        "commissariat_police"
      );
      props.getMarkersAroundConcat(longitude, latitude, 16.5, "mairie");
    }
  }, [longitude, latitude]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    let countCategories = [];
    let commerceListeCategorie = getListeCategories("commerces");
    if (comstat) {
      commerceListeCategorie.forEach((categorie) => {
        let icon;
        let categorieTexte;

        if (categorie === "Alimention") {
          icon = alimentationIcon;
          categorieTexte = "commerce(s) de bouche";
        } else if (categorie === "Banque") {
          icon = banqueIcon;
          categorieTexte = "banque(s)";
        } else if (categorie === "Commerces") {
          icon = commercesIcon;
          categorieTexte = "commerce(s)";
        } else if (categorie === "Grande Distribution") {
          icon = grandeDistribIcon;
          categorieTexte = "grande(s) distribution(s)";
        }
        if (
          ["Alimention", "Banque", "Commerces", "Grande Distribution"].includes(
            categorie
          )
        ) {
          countCategories.push({
            nom: categorie,
            count: comstat.filter(
              (marker) => getCategorie(marker.type, "commerces") === categorie
            ).length,
            icon: icon,
            texte: categorieTexte,
          });
        }
      });
      setCategoriesCounter(countCategories);
    }
  }, [comstat]);

  useEffect(() => {
    if (!isEmpty(longitude) && !isEmpty(longitude)) {
      let type = "commerces";
      let categories = "all";

      let countCategories = [];

      let commerceListeCategorie = getListeCategories("commerces");
      console.log(commerceListeCategorie, "commerceListeCategorie");
      // let dataToUse = data
      let traitement = (commerceListeCategorie, countCategories, data) => {
        getServicesAround(type, categories, longitude, latitude, 1).then(
          (datacalc) => {
            console.log(datacalc, "datacalc");
            setcomstat(datacalc);
          }
        );

        let listeMarkers = [];
        data.forEach((marker, index) => {
          let iconMode;
          if (getCategorie(marker.type, "commerces") === "Alimention") {
            iconMode = alimentationIcon;
          } else if (getCategorie(marker.type, "commerces") === "Banque") {
            iconMode = banqueIcon;
          } else if (getCategorie(marker.type, "commerces") === "Commerces") {
            iconMode = commercesIcon;
          } else if (
            getCategorie(marker.type, "commerces") === "Grande Distribution"
          ) {
            iconMode = grandeDistribIcon;
          }

          var markerToAdd = {
            key: index,
            type: getCategorie(marker.type, "commerces"),
            position: [
              marker.geometry.coordinates[1],
              marker.geometry.coordinates[0],
            ],
            icon: {
              iconUrl: iconMode,
              iconSize: [20, 20],
            },
          };
          if (
            [
              "Alimention",
              "Banque",
              "Commerces",
              "Grande Distribution",
            ].includes(getCategorie(marker.type, "commerces"))
          ) {
            listeMarkers.push(markerToAdd);
          }
        });
        setMarkers(listeMarkers);
      };

      getServicesAround(type, categories, latitude, longitude, 1).then(
        (data) => {
          let commerceListeCategorie = getListeCategories("commerces");

          let dataToUse = data;

          if (data.length >= 100) {
            getServicesAround(type, categories, longitude, latitude, 0.3).then(
              (data) => {
                setzoom(17);
                dataToUse = data;
                console.log(data);
                traitement(commerceListeCategorie, countCategories, dataToUse);
              }
            );
          }

          // console.log(data.length)
          else if (data.length === 0) {
            getServicesAround(type, categories, longitude, latitude, 1).then(
              (data) => {
                if (data.length >= 100) {
                  getServicesAround(
                    type,
                    categories,
                    longitude,
                    latitude,
                    0.3
                  ).then((data) => {
                    setzoom(16);
                    dataToUse = data;
                    // console.log(data)
                    traitement(
                      commerceListeCategorie,
                      countCategories,
                      dataToUse
                    );
                  });
                } else {
                  dataToUse = data;
                  // console.log(data)
                  traitement(
                    commerceListeCategorie,
                    countCategories,
                    dataToUse
                  );
                }
              }
            );
          } else {
            traitement(commerceListeCategorie, countCategories, dataToUse);
          }
        }
      );
      // if (data.length === 0) {
      //   getServicesAround(type, categories, longitude, latitude, 1).then(data => {
      //     dataToUse = data
      //     traitement(commerceListeCategorie, countCategories,  dataToUse)
      //   })
      // } else {
      //   traitement(commerceListeCategorie, countCategories,  dataToUse)
      // }
    }
  }, []);

  // Markers
  useEffect(() => {
    var busMarkersEffect;
    var busMarkersEffect2 = [];

    if (!isEmpty(props.markers)) {
      console.log("props.markers", props.markers);
      props.markers.forEach((markerObject) => {
        var iconMode;
        if (markerObject.mode === "bus") {
          iconMode = busIcon;
        } else if (markerObject.mode === "metro") {
          iconMode = metroIcon;
        } else if (markerObject.mode === "rer") {
          iconMode = rerIcon;
        } else if (markerObject.mode === "ter") {
          iconMode = terIcon;
        } else if (markerObject.mode === "tramway") {
          iconMode = tramwayIcon;
        } else if (markerObject.mode === "train") {
          iconMode = trainIcon;
        } else if (markerObject.mode === "tgv") {
          iconMode = tgvIcon;
        } else if (markerObject.mode === "ecole") {
          iconMode = ecoleIcon;
        } else if (markerObject.mode === "college") {
          iconMode = collegeIcon;
        } else if (markerObject.mode === "lycee") {
          iconMode = lyceeIcon;
        } else if (markerObject.mode === "ecole-superieure") {
          iconMode = ecoleSuperieureIcon;
        } else if (markerObject.mode === "commissariat_police") {
          iconMode = commissariatIcon;
        } else if (markerObject.mode === "mairie") {
          iconMode = mairieIcon;
        }

        busMarkersEffect = markerObject.areas;

        busMarkersEffect &&
          busMarkersEffect.forEach((element) => {
            var marker = {
              key: element._id,
              position: [
                element.geometry.coordinates[1],
                element.geometry.coordinates[0],
              ],
              icon: {
                iconUrl: iconMode,
                iconSize: [20, 20],
              },
            };

            busMarkersEffect2.push(marker);
          });

        setBusMarkers(busMarkersEffect2);
      });
    }
  }, [JSON.stringify(props.markers)]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    console.log(busMarkers);
    console.log(comstat, "comstat");
    console.log(categoriesCounter, "categoriesCounter");
  }, [busMarkers, comstat, categoriesCounter]);
  return (
    <Grid container>
      <Grid item xs={12} md={12}>
        <div className={classes.paper}>
          <Grid item xs={12} md={12}>
            <Typography variant="h6" gutterBottom style={{ color: "#122659" }}>
              {props.bien.type_bien_corrige.toUpperCase()} DE{" "}
              {props.bien.nbe_piece} PIECES ET DE {props.bien.surface} M2{" "}
            </Typography>
            <Typography variant="h5" gutterBottom>
              Quartier de {props.bien.nom_commune} | {props.bien.code_insee}{" "}
              {props.bien.nom_commune}
            </Typography>
          </Grid>
          <Grid item xs={12} md={12}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <Grid
                  item
                  xs={12}
                  md={12}
                  style={{
                    border: "solid",
                    borderColor: "#f0f0f0",
                  }}
                >
                  <Grid item xs={12} md={12}>
                    <a style={{ color: "#122659" }}>
                      {" "}
                      <span style={{ color: "#122659", fontWeight: "bold" }}>
                        Caractéristiques du bien
                      </span>{" "}
                    </a>
                    <Grid container>
                      <Grid
                        item
                        xs={2}
                        md={2}
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          flexDirection: "column",
                          textAlign: "center",
                          color: "#122659",
                          flexWrap: "wrap",
                        }}
                      >
                        <BedOutlinedIcon style={{ fontSize: "50px" }} />
                        <h6>{props.bien.nbe_chambre} chambres</h6>
                      </Grid>
                      <Grid
                        item
                        xs={2}
                        md={2}
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          flexDirection: "column",
                          textAlign: "center",
                          color: "#122659",
                        }}
                      >
                        <BathtubOutlinedIcon style={{ fontSize: "50px" }} />
                        <h6>{props.bien.nbe_salle_de_bain} SDB</h6>
                      </Grid>
                      <Grid
                        item
                        xs={2}
                        md={2}
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          flexDirection: "column",
                          textAlign: "center",
                          color: "#122659",
                        }}
                      >
                        <KitchenOutlinedIcon style={{ fontSize: "50px" }} />
                        <h6>Cuisine séparée</h6>
                      </Grid>
                      <Grid
                        item
                        xs={2}
                        md={2}
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          flexDirection: "column",
                          textAlign: "center",
                          color: "#122659",
                        }}
                      >
                        <EngineeringOutlinedIcon style={{ fontSize: "50px" }} />
                        <h6>Travaux à prévoir</h6>
                      </Grid>
                      {props.bien.garage === true && (
                        <Grid
                          item
                          xs={2}
                          md={2}
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            flexDirection: "column",
                            textAlign: "center",
                            color: "#122659",
                          }}
                        >
                          <LocalParkingOutlinedIcon
                            style={{ fontSize: "50px" }}
                          />
                          <h6>Parking</h6>
                        </Grid>
                      )}

                      <Grid
                        item
                        xs={2}
                        md={2}
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          flexDirection: "column",
                          textAlign: "center",
                          color: "#122659",
                        }}
                      >
                        <BalconyOutlinedIcon style={{ fontSize: "50px" }} />
                        <h6>Cave</h6>
                      </Grid>
                      {props.bien.terrasse === true && (
                        <Grid
                          item
                          xs={2}
                          md={2}
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            flexDirection: "column",
                            textAlign: "center",
                            color: "#122659",
                          }}
                        >
                          <DeckOutlinedIcon style={{ fontSize: "50px" }} />
                          <h6>Terrasse</h6>
                        </Grid>
                      )}
                      {props.bien.ascenseur === true && (
                        <Grid
                          item
                          xs={2}
                          md={2}
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            flexDirection: "column",
                            textAlign: "center",
                            color: "#122659",
                          }}
                        >
                          <ElevatorOutlinedIcon style={{ fontSize: "50px" }} />
                          <h6>Ascenseur</h6>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    spacing={3}
                    style={{
                      display: "flex",
                      justifyContent: "space-evenly",
                      marginTop: "10px",
                      color: "#122659",
                    }}
                  >
                    <Grid item xs={12} md={4}>
                      <h6>
                        DPE{" "}
                        {props.bien.dep_valeur
                          ? props.bien.classe_enegetique
                          : ""}
                      </h6>

                      <HSBar
                        showTextIn
                        showTextWithValue={false}
                        height={15}
                        outlineWidth={0.5}
                        outlineColor="black"
                        id="new_id"
                        fontColor="#fff"
                        data={[
                          {
                            name: "A",
                            value: 200,
                            color: "#11a376",
                          },
                          {
                            name: "B",
                            value: 200,
                            color: "#02b352",
                          },
                          {
                            name: "C",
                            value: 200,
                            color: "#45c27d",
                          },
                          {
                            name: "D",
                            value: 200,
                            color: "#45c27d",
                          },
                          {
                            name: "E",
                            value: 200,
                            color: "#ffc048",
                          },
                          {
                            name: "F",
                            value: 200,
                            color: "#ff7d33",
                          },
                          {
                            name: "G",
                            value: 200,
                            color: "#ed051c",
                          },
                        ]}
                        onClick={(e) => console.log(e.bar)}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <h6>
                        GES {props.bien.ges_lettre ? props.bien.ges_valeur : ""}
                      </h6>
                      <HSBar
                        showTextIn
                        showTextWithValue={false}
                        height={15}
                        outlineWidth={0.5}
                        id="new_id"
                        fontColor="#fff"
                        data={[
                          {
                            name: "A",
                            value: 200,
                            color: "#11a376",
                          },
                          {
                            name: "B",
                            value: 200,
                            color: "#02b352",
                          },
                          {
                            name: "C",
                            value: 200,
                            color: "#45c27d",
                          },
                          {
                            name: "D",
                            value: 200,
                            color: "#45c27d",
                          },
                          {
                            name: "E",
                            value: 200,
                            color: "#ffc048",
                          },
                          {
                            name: "F",
                            value: 200,
                            color: "#ff7d33",
                          },
                          {
                            name: "G",
                            value: 200,
                            color: "#ed051c",
                          },
                        ]}
                        onClick={(e) => console.log(e.bar)}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={12}
                  className={classes.vision}
                >
                  {/* <p
                    style={{
                      marginTop: "10px",
                      backgroundColor: "#f2f2f2",
                      textAlign: "start",
                      maxHeight: "400px",
                      padding: "10px",
                    }}
                  >
                    <span style={{ fontSize: "18px" }}>Notre vision</span>
                    <br />

                    {props.bien.description_bien}
                  </p> */}
                        <TextField
          
          value={isEmpty(props.bien.description_bien) ? "" : props.bien.description_bien.replace( /(<([^>]+)>)/ig, '')}
          inputProps={
            { readOnly: true, }
          }
          required={true}
          multiline={true}
          type="text"
        style={{
          width:"100%"
        }}
        />
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                md={5}
               className={classes.maps}
              >
                <span style={{ color: "#122659", fontWeight: "bold" }}>
                  Localisation
                </span>
                <Card className={classes.cardMap}>
                  {props.bien.latitude ? (
                    <Map
                      id="mapid"
                      minZoom={9}
                      maxZoom={18}
                      zoom={15}
                      zoomControl={false}
                      center={[props.bien.latitude, props.bien.longitude]}
                      style={{
                        height: "100%",
                      }}
                    >
                      <Marker
                        position={[props.bien.latitude, props.bien.longitude]}
                      />
                      {busMarkers.map((marker, index) => (
                        <Marker
                          key={index}
                          position={[marker.position[0], marker.position[1]]}
                          icon={L.icon({
                            iconUrl: marker.icon.iconUrl,
                            iconSize: marker.icon.iconSize,
                          })}
                        />
                      ))}

                      {!isEmpty(markers) &&
                        markers.map((marker, index) => (
                          <Marker
                            key={index}
                            position={[
                              marker.position[1] > marker.position[0]
                                ? marker.position[1]
                                : marker.position[0],
                              marker.position[1] > marker.position[0]
                                ? marker.position[0]
                                : marker.position[1],
                            ]}
                            icon={L.icon({
                              iconUrl: marker.icon.iconUrl,
                              iconSize: marker.icon.iconSize,
                            })}
                          />
                        ))}

                      <TileLayer
                        url="https://{s}.basemaps.cartocdn.com/light_nolabels/{z}/{x}/{y}.png"
                        attribution="©OpenStreetMap, ©CartoDB"
                      />
                      <TileLayer
                        url="https://{s}.basemaps.cartocdn.com/light_only_labels/{z}/{x}/{y}.png"
                        attribution="©OpenStreetMap, ©CartoDB"
                      />
                      <ScaleControl
                        position="topright"
                        maxWidth={300}
                        imperial={false}
                      />
                    </Map>
                  ) : (
                    <img src="https://miro.medium.com/max/900/0*iMkCQLHxXw9gLWjD." />
                  )}
                </Card>
                {props.markers.length >= 13 && (
                  <div>
                    <div>
                      <span style={{ color: "#122659", fontWeight: "bold" }}>
                        Transports à 1km
                      </span>
                      <p>
                        {props.markers[1].areas.length + " "} stations de métro,{" "} 
                        {props.markers[6].areas.length + " "} gares SNCF,{" "} 
                        {props.markers[5].areas.length + " "} gares de RER,{" "} 
                        {props.markers[0].areas.length + " "} arrêts de bus
                      </p>
                    </div>
                    <div>
                      <span style={{ color: "#122659", fontWeight: "bold" }}>
                        Enseignements à 1km
                      </span>
                      <p>
                        {props.markers[7].areas.length + " "}écoles maternelles, {" "} 
                        {props.markers[8].areas.length + " "}écoles élémentaires,{" "} 
                        {props.markers[10].areas.length + " "}lycées,{" "} 
                        {props.markers[9].areas.length + " "}établissements supérieurs
                      </p>
                    </div>
                    <div>
                      <span style={{ color: "#122659", fontWeight: "bold" }}>
                        Commerces à 1km
                      </span>
                      {categoriesCounter.length > 0 && (
                        <p>
                          {categoriesCounter[0].count + " "} commerces de
                          bouches, {" "} {categoriesCounter[1].count + " "} banques,{" "} 
                          {categoriesCounter[2].count + " "} restaurants
                        </p>
                      )}
                    </div>
                  </div>
                )}
              </Grid>
            </Grid>
          </Grid>
        </div>
      </Grid>
    </Grid>
  );
};
const mapStateToProps = (state) => ({
  dataBien: state.bien.bien,
  markers: state.map.markers,
});
export default connect(mapStateToProps, {
  getMarkersAroundConcat,
})(DetailBien);
