import axios from 'axios'
import { objectToUrl } from './functions/objectToUrl'

import {
    GET_DISTRICT_AROUND,
    GET_DISTRICT_FROM_COORD,
    GET_MARKERS_AROUND,
    GET_MARKERS_AROUND_CONCAT
} from './types'
const { APIServerUrl } = require('../shared/values/environnement')

export const getDistrictAround =
    (district, x, y, radius, dataParams, category) => dispatch => {
        axios
            .get(APIServerUrl + '/api/' + district + '/around/' + x + '/' + y + '/radius/' + radius +
                '/category/' + category + '?' + objectToUrl(dataParams))
            .then(res => {
                dispatch({
                    type: GET_DISTRICT_AROUND,
                    payload: res.data
                })
            })
            .catch(err => {
                dispatch({
                    type: GET_DISTRICT_AROUND,
                    payload: { error: 'KO' }
                })
            })
    }

export const getDistrictFromCoord = (district, x, y) => dispatch => {
    axios
        .get(APIServerUrl + '/api/' + district + '/from-coordinates/' + x + '/' + y)
        .then(res => {
            dispatch({
                type: GET_DISTRICT_FROM_COORD,
                payload: res.data
            })
        })
        .catch(err => {
            dispatch({
                type: GET_DISTRICT_FROM_COORD,
                payload: { error: 'KO' }
            })
        })
}

export const getMarkersAround = (x, y, zoom, type) => dispatch => {
    axios
        .get(APIServerUrl + '/api/markers/' + type + '/around/' + x + '/' + y + '/zoom/' + zoom)
        .then(res => {
            dispatch({
                type: GET_MARKERS_AROUND,
                payload: {
                    mode: type,
                    areas: res.data
                }
            })
        })
        .catch(err => {
            dispatch({
                type: GET_MARKERS_AROUND,
                payload: { error: 'KO' }
            })
        })
}

export const getMarkersAroundConcat = (x, y, zoom, type) => dispatch => {
    axios
        .get(APIServerUrl + '/api/markers/' + type + '/around/' + x + '/' + y + '/zoom/' + zoom)
        .then(res => {
            dispatch({
                type: GET_MARKERS_AROUND_CONCAT,
                payload: {
                    mode: type,
                    areas: res.data
                }
            })
        })
        .catch(err => {
            dispatch({
                type: GET_MARKERS_AROUND_CONCAT,
                payload: { error: 'KO' }
            })
        })
}

export const emptyMarkers = (type) => dispatch => {
    dispatch({
        type: GET_MARKERS_AROUND,
        payload: {
            mode: type,
            areas: []
        }
    })
}