import React,{useEffect} from 'react'
import PropTypes from 'prop-types'

// Material UI components
import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'
import Typography from '@material-ui/core/Typography'
import { makeStyles, useTheme } from '@material-ui/core/styles'

// Specific components
import { ResponsivePieCanvas } from '@nivo/pie'

function PieChart(props) {
  // Définition des styles
  const useStyles = makeStyles(theme => ({
    card: {
      margin: theme.spacing(1),
      padding: theme.spacing(2),
      height: props.height + 'px',
      borderColor: '#eee',
      borderStyle: 'solid',
      borderRadius: '4px',
      boxShadow: 'none',
      position: 'relative',
      '@media print': {
        padding: theme.spacing(1),
        margin: theme.spacing(0.5),
      }
    },
    div: {
      height: props.heightDiv,
    },
    cardValue: {
      fontSize: '2.5rem',
      color: theme.palette.secondary.main
    },
    cardTitle: {
      fontSize: '1.1rem',
      color: theme.palette.primary.main
    },
    cardSubtitle: {
      fontSize: '0.8rem',
      color: theme.palette.primary.light,
      left: '3px',
      bottom: 0,
      position: 'absolute',
      margin: 0,
      paddingBottom: theme.spacing(0)
    },
    gridIcon: {
      textAlign: 'center',
      padding: theme.spacing(0.5),
      textTransform: 'uppercase',
      color: theme.palette.primary.main,
      marginTop: '-15px'
    }
  }))
  const classes = useStyles()
  const theme = useTheme()
  useEffect(() => {

  },[props.scolor,props.pcolor])

  return (
    <Grid item xs={12} sm={12} md={12} className={classes.pieContainer}>
      <Card className={classes.card}>
        <Typography align={'center'} gutterBottom variant="h5" component="h2" className={classes.cardTitle}  style={{color:props.pcolor}}>
          {props.title}
        </Typography>
        <div className={classes.div}>
          <ResponsivePieCanvas
            data={props.data}
            margin={props.margin}
            pixelRatio={2}
            innerRadius={0.5}
            padAngle={0.7}
            cornerRadius={3}
            colors={['#2ed6ff', '#57deff', '#10a89b', '#17f0de', '#45f3e4', '#2095b2']}
            borderColor={{ from: 'color', modifiers: [['darker', 0.6]] }}
            radialLabel={function (e) { return e.id + " (" + e.value + ")" }}
            radialLabelsSkipAngle={10}
            radialLabelsTextXOffset={6}
            radialLabelsTextColor={props.pcolor}
            radialLabelsLinkOffset={0}
            radialLabelsLinkDiagonalLength={10}
            radialLabelsLinkHorizontalLength={10}
            radialLabelsLinkStrokeWidth={2}
            radialLabelsLinkColor={{ from: 'color' }}
            slicesLabelsSkipAngle={10}
            slicesLabelsTextColor={props.scolor}
            enableSliceLabels={false}
            animate={true}
            motionStiffness={90}
            motionDamping={15}
          />
        </div>
        {props.value ? <Typography align={'center'} variant="h4" component="h3" style={{color:props.pcolor}} className={classes.cardValue}>{props.value}</Typography> : <p></p>}
        {props.subtitle ? <Typography align={'center'} variant="subtitle" style={{color:props.pcolor}} className={classes.subtitle}>{props.subtitle}</Typography> : <p></p>}
        <Grid item className={classes.gridIcon} style={{color:props.pcolor}}>
          {props.icon}
          <Typography align={'center'} variant="subtitle2" style={{color:props.pcolor}}>
            {props.iconTitle}
          </Typography>
        </Grid>
        <Typography paragraph align={'center'} variant="subtitle1" color="textSecondary" className={classes.cardSubtitle} style={{color:props.pcolor}}>
          {props.subtitle2}
        </Typography>
      </Card>
    </Grid >)
}

var defaultData = [
  {
    "id": "Exemple 1",
    "value": 100,
  },
  {
    "id": "Exemple 2",
    "value": 200,
  },
  {
    "id": "Exemple 3",
    "value": 300,
  },
]

PieChart.defaultProps = {
  title: '',
  subtitle: '',
  subtitle2: '',
  value: 0,
  data: defaultData,
  margin: { top: 30, right: 10, bottom: 50, left: 10 },
  height: 400,
  heightDiv: '100%'
}

PieChart.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  subtitle2: PropTypes.string,
  value: PropTypes.number,
  data: PropTypes.array,
  margin: PropTypes.object,
  height: PropTypes.number,
  heightDiv: PropTypes.string
}

export default PieChart
