import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
// Wealzy
import PieChart from "../../kpi/PieChart";
import GridContainer from "../../common-component/GridContainer";
import GridItem from "../../common-component/GridItem";
import { useDispatch } from "react-redux";

// Material UI
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

// Icons
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import CardKpiWithIcon from "../../kpi/CardKpiWithIcon";
const { isEmpty } = require("../../../shared/functions/isEmpty");

export default function DossierBienDetailleModeleFinancier(props) {
  const dispatch = useDispatch();
  var [revenuFoncierAnnuel, setRevenuFoncierAnnuel] = useState(0);

  var [revenuAnnuel, setRevenuAnnuel] = useState(0);
  var [chargeAnnuel, setChargeAnnuel] = useState(0);
  var [montantTravaux, setMontantTravaux] = useState(0);
  var [optionFiscale, setOptionFiscale] = useState(0);
  var [tableauChargeInteretAnnuel, setTableauChargeInteretAnnuel] = useState(
    []
  );
  var [montantAmortissementBienAnnuel, setMontantAmortissementBienAnnuel] =
    useState(0);
  const [cashFlowMoyen, setCashFlowMoyen] = useState();
  var [apportPersonnel, setApportPersonnel] = useState(
    isEmpty(props.data.financement.apport_personnel)
      ? 0
      : props.data.financement.apport_personnel
  );

  var [montantAEmprunter, setMontantAEmprunter] = useState(
    isEmpty(props.data.financement.montant_a_emprunter)
      ? props.data.prix.prix_bien_complet
      : props.data.financement.montant_a_emprunter
  );

  var [nbeMensualiteRemboursement, setNbeMensualiteRemboursement] = useState(
    isEmpty(props.data.financement.nbe_mensualite_remboursement) ||
      props.data.financement.nbe_mensualite_remboursement == "undefined"
      ? 240
      : props.data.financement.nbe_mensualite_remboursement
  );

  var [tauxEmprunt, setTauxEmprunt] = useState(
    isEmpty(props.data.financement.taux_emprunt)
      ? 1.3
      : props.data.financement.taux_emprunt
  );

  var [tauxAssurance, setTauxAssurance] = useState(
    isEmpty(props.data.financement.taux_assurance)
      ? 0.5
      : props.data.financement.taux_assurance
  );

  var [fraisDossierBancaire, setFraisDossierBancaire] = useState(
    isEmpty(props.data.financement.frais_dossier_financement)
      ? 0
      : props.data.financement.frais_dossier_financement
  );

  var [garantieCreditLogement, setGarantieCreditLogement] = useState(
    isEmpty(props.data.financement.frais_garanties_cuationnement)
      ? 0
      : props.data.financement.frais_garanties_cuationnement
  );

  var [fraisCourtage, setFraisCourtage] = useState(
    isEmpty(props.data.financement.frais_courtage)
      ? 0
      : props.data.financement.frais_courtage
  );
  var [arraytab, setArraytab] = useState([]);
  var [montantMensualiteHorsAssurance, setMontantMensualiteHorsAssurance] =
    useState(0);

  var [montantMensualiteAssurance, setMontantMensualiteAssurance] = useState(0);

  const [isCalculated, setIsCalculated] = useState(false);

  const [timerCalcul, setTimerCalcul] = useState();
  var [revenuFoncierGlobal, setRevenuFoncierGlobal] = useState([]);
  const [revenuFoncierMoyen, setRevenuFoncierMoyen] = useState(0);
  var [mensualiteRemboursement, setMensualiteRemboursement] = useState(0);

  const useStyles = makeStyles((theme) => ({
    cardbudget: {
      padding: 16,
      borderStyle: "solid",
      margin: 9,
      borderColor: "#eee",
      borderRadius: 4,
      minHeight: props.isbrut ? 600 : "",
      display: "flex",
      alignItems: props.isbrut ? "center" : "flex-start",
      ["@media (max-width:1000px)"]: {
        // eslint-disable-line no-useless-computed-key
        padding: 16,
        borderStyle: "solid",
        margin: 9,
        borderColor: "#eee",
        borderRadius: 4,
        minHeight: "50%",
        display: "flex",
        alignItems: props.isbrut ? "center" : "flex-start",
      },
    },
  }));
  const classes = useStyles();
  const isBadNumber = (number) => {
    return isNaN(number) ||
      number === null ||
      number === "" ||
      number === Infinity
      ? 0
      : number;
  };

  useEffect(() => {
    console.log("111111111111111", props.data);
    var montantAEmprunterEffect =
      props.data.prix.prix_bien_complet - isBadNumber(apportPersonnel);
    console.log("dkhaal2", montantAEmprunterEffect);
    setMontantAEmprunter(parseInt(montantAEmprunterEffect, 10));

    // Calcul Mensualités Remboursement Emprunt Hors Assurance
    console.log("tauxEmprunt", tauxEmprunt);
    var tauxPeriodique = isBadNumber(tauxEmprunt) / 100 / 12;

    var montantMensualiteHorsAssurance =
      (montantAEmprunterEffect * tauxPeriodique) /
      (1 -
        Math.pow(1 + tauxPeriodique, -isBadNumber(nbeMensualiteRemboursement)));
    setMontantMensualiteHorsAssurance(
      isBadNumber(montantMensualiteHorsAssurance)
    );
    console.log(
      "aaaaaaaa",
      props.data.financement.nbe_mensualite_remboursement,
      "////",
      nbeMensualiteRemboursement
    );
    console.log(
      "dkhal333",
      montantAEmprunterEffect,
      "*",
      tauxPeriodique,
      "////",
      nbeMensualiteRemboursement
    );
    // Calcul Mensualités Assurance
    var montantMensualiteAssurance =
      (montantAEmprunterEffect * isBadNumber(tauxAssurance)) / 100 / 12;
    setMontantMensualiteAssurance(isBadNumber(montantMensualiteAssurance));
  }, []);

  useEffect(() => {
    // Initialisation / Mise à jour des données du bien via REDUX - prix_bien_complet
    //console.log('props.dataBien.qualification_robot.financement.montant_mensualite_remboursement',props.dataBien.qualification_robot.financement
    //)
    if (!isNaN(props.data.financement.montant_mensualite_remboursement)) {
      setMensualiteRemboursement(
        props.data.financement.montant_mensualite_remboursement
      );
    } else {
      setMensualiteRemboursement(
        props.data.financement.montant_mensualite_hors_assurance +
          props.data.financement.montant_mensualite_assurance
      );
    }
  }, [props.data]);

  useEffect(() => {
    //console.log('props.data',props.data.financement)
    setApportPersonnel(props.data.financement.apport_personnel);
    console.log(
      "props.data.financement.nbe_mensualite_remboursement",
      props.data.financement.nbe_mensualite_remboursement
    );
    if (props.data.financement.nbe_mensualite_remboursement != undefined) {
      setNbeMensualiteRemboursement(
        props.data.financement.nbe_mensualite_remboursement
      );
    }
    setTauxAssurance(props.data.financement.taux_assurance);
    if (props.data.financement.taux_emprunt != undefined) {
      setTauxEmprunt(props.data.financement.taux_emprunt);
    }
    setFraisCourtage(props.data.financement.frais_courtage);
    setFraisDossierBancaire(props.data.financement.frais_dossier_financement);
    setGarantieCreditLogement(
      props.data.financement.frais_garanties_cuationnement
    );
    console.log(
      "props.data.financement.nbe_mensualite_remboursement",
      props.data.financement.nbe_mensualite_remboursement
    );
  }, [props.data.financement]);

  const calculerTableauAmortissement = async (
    montantMensualiteHorsAssurance,
    montantMensualiteAssurance,
    nbeMensualiteRemboursement,
    tauxEmprunt,
    montantAEmprunter
  ) => {
    var i;
    var dataAmortissementEcheance;
    var annee = 1;
    var mois = 1;
    var capitalRestantDu = parseInt(montantAEmprunter, 10);
    var capitalRembourseMois = 0;
    var tableauAmortissementPret;
    //console.log('dkhal1')
    for (i = 0; i < isBadNumber(nbeMensualiteRemboursement); i++) {
      if (i !== 0) {
        annee = Math.ceil((i + 1) / 12);
        capitalRestantDu = capitalRestantDu - capitalRembourseMois;
      }

      mois = i + 1 - (annee - 1) * 12;

      capitalRembourseMois =
        montantMensualiteHorsAssurance -
        (capitalRestantDu * tauxEmprunt) / 100 / 12;

      dataAmortissementEcheance = {
        num_echeance: i + 1,
        annee: annee,
        mois: mois,
        interet_mois:
          parseInt(
            ((capitalRestantDu * isBadNumber(tauxEmprunt)) / 100 / 12) * 100
          ) / 100,
        capital_rembourse_mois: parseInt(capitalRembourseMois * 100) / 100,
        capital_restant_du_mois_avant_remboursement:
          parseInt(capitalRestantDu * 100, 10) / 100,
        capital_restant_du_mois_apres_remboursement:
          parseInt((capitalRestantDu - capitalRembourseMois) * 100, 10) / 100,
        assurance_mois: parseInt(montantMensualiteAssurance * 100, 10) / 100,
        total_mensualite:
          parseInt(
            (capitalRembourseMois +
              (capitalRestantDu * isBadNumber(tauxEmprunt)) / 100 / 12 +
              montantMensualiteAssurance) *
              100,
            10
          ) / 100,
      };

      if (tableauAmortissementPret === undefined) {
        tableauAmortissementPret = [];
        tableauAmortissementPret.push(dataAmortissementEcheance);
      } else {
        tableauAmortissementPret.push(dataAmortissementEcheance);
      }
      var result = [];
      tableauAmortissementPret.reduce(function (res, value) {
        if (!res[value.annee]) {
          res[value.annee] = { annee: value.annee, interet_mois: 0 };
          result.push(res[value.annee]);
        }
        res[value.annee].interet_mois += value.interet_mois;
        return res;
      }, {});
      return result;
      // calculerChargeInteretMoyen(result).then((res) => {
      //   setArraytab(parseInt(res.toFixed(0), 10));
      // });
    }
    //console.log('dkhal2')

    //console.log('dkhal3')
    // props.callback({
    //   source: "financement",
    //   name: "tableau_amortissement_pret",
    //   value: tableauAmortissementPret,
    // });
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      console.log("tableauChargeInteretAnnuel", tableauChargeInteretAnnuel);
      if (tableauChargeInteretAnnuel?.length > 0) {
        let revenuFoncierInitial = [];
        for (let i = 0; i < tableauChargeInteretAnnuel.length; i++) {
          revenuFoncierInitial.push(
            props.data.location.prix_location_retenue_annuel -
              props.data.charge.total_charges -
              tableauChargeInteretAnnuel[i].interet_mois
          );
        }
        console.log("revenuFoncierInitial", revenuFoncierInitial);
        setRevenuFoncierAnnuel(revenuFoncierInitial);
      }
    }, 1000);

    return () => clearTimeout(timer);
  }, [
    // eslint-disable-line react-hooks/exhaustive-deps
    props.data,
    tableauChargeInteretAnnuel,
  ]);

  useEffect(() => {
    calculerTableauAmortissement(
      montantMensualiteHorsAssurance,
      montantMensualiteAssurance,
      nbeMensualiteRemboursement,
      tauxEmprunt,
      montantAEmprunter
    ).then((res) => {
      setTableauChargeInteretAnnuel(res);
      console.log("arrayrabaaaaaaaa##", apportPersonnel, res);
      calculerChargeInteretMoyen(res).then((resultats) => {
        console.log("resultattab", resultats);
        setArraytab(parseInt(resultats.toFixed(0), 10));
        //console.log("resssssssss", parseInt(res.toFixed(0), 10));
      });
      //setIsCalculated(false)
    });
  }, []);

  const calculerChargeInteretMoyen = async (tableauChargeInteretAnnuel) => {
    if (
      !isEmpty(tableauChargeInteretAnnuel) &&
      tableauChargeInteretAnnuel !== []
    ) {
      var i;
      var tableauChargesInteretAnnuelVar = 0;
      if (tableauChargeInteretAnnuel !== []) {
        for (i = 0; i < tableauChargeInteretAnnuel.length; i++) {
          if (tableauChargeInteretAnnuel[i]) {
            tableauChargesInteretAnnuelVar =
              tableauChargesInteretAnnuelVar +
              tableauChargeInteretAnnuel[i].interet_mois;
          }
        }
      }
      tableauChargesInteretAnnuelVar =
        tableauChargesInteretAnnuelVar / tableauChargeInteretAnnuel.length;
      // setTableauChargeInteretAnnuel(tableauChargesInteretAnnuelVar);
      return tableauChargesInteretAnnuelVar;
    } else {
      return 0;
    }
  };

  useEffect(() => {
    let prixLocationRetenueAnnuel = parseInt(
      props.data.location.prix_location_retenue_annuel,
      10
    );
    let mensualiteRemboursement = props.data.financement
      .montant_mensualite_remboursement
      ? props.data.financement.montant_mensualite_remboursement * 12
      : (props.data.financement.montant_mensualite_assurance +
          props.data.financement.montant_mensualite_hors_assurance) *
        12;

    let totalCharges = parseInt(props.data.charge.total_charges, 10);
    console.log(
      "totalCharges =",
      prixLocationRetenueAnnuel,
      "-",
      mensualiteRemboursement,
      "* 12 -",
      totalCharges
    );
    setCashFlowMoyen(
      prixLocationRetenueAnnuel - mensualiteRemboursement - totalCharges
    );

    console.log(props.data);
  }, [props.data]);

  // console.log("cashFlowMoyen", cashFlowMoyen !== cashFlowMoyen);
  const dataRevenus = [
    {
      id: "Revenus locatifs",
      value: props.data.prix_location_retenue_annuel,
    },
  ];

  useEffect(() => {
    console.log(
      "montantMensualiteHorsAssurance + montantMensualiteAssurance",
      montantMensualiteHorsAssurance,
      montantMensualiteAssurance
    );
  }, [montantMensualiteHorsAssurance, montantMensualiteAssurance]);

  const calculerRevenuFoncierAnnuel = async (
    montantTravaux,
    montantAmortissementBienAnnuel,
    revenuFoncierAnnuel
  ) => {
    //console.log("revenuFoncierAnnuel", revenuFoncierAnnuel.length);
    if (revenuFoncierAnnuel.length > 0) {
      let revenuFoncierArray = [];
      let travauxRestant = montantTravaux;
      let delta = 0;

      for (let i = 0; i < revenuFoncierAnnuel.length; i++) {
        if (travauxRestant > 0) {
          delta = revenuFoncierAnnuel[i] - travauxRestant;
        } else {
          delta = revenuFoncierAnnuel[i];
        }

        if (delta <= -10700) {
          revenuFoncierArray[i] = -10700;
          travauxRestant = travauxRestant - revenuFoncierAnnuel[i] - 10700;
        } else {
          if (delta >= 0) {
            delta = delta - montantAmortissementBienAnnuel;
            if (delta >= 0) {
              revenuFoncierArray[i] = delta;
              travauxRestant = travauxRestant - revenuFoncierAnnuel[i];
            } else {
              revenuFoncierArray[i] = 0;
              travauxRestant = travauxRestant - revenuFoncierAnnuel[i];
            }
          } else {
            revenuFoncierArray[i] = delta;
            travauxRestant = travauxRestant - revenuFoncierAnnuel[i];
          }
        }
      }
      setRevenuFoncierGlobal(revenuFoncierArray);

      let revenuFoncierMoyen =
        revenuFoncierArray.reduce((a, b) => a + b) / revenuFoncierAnnuel.length;
      console.log("aabbbbbbb", revenuFoncierMoyen);
      setRevenuFoncierMoyen(parseInt(revenuFoncierMoyen, 10));
    }
  };
  useEffect(() => {
    if (props.data != undefined)
      props.data.location.option_location === 0
        ? setOptionFiscale(0)
        : setOptionFiscale(1);
  }, [props.data]);
  useEffect(() => {
    //console.log("aaaaaa", props.data);
    if (props.data != undefined) {
      console.log("revenue", props.data);
      setRevenuAnnuel(props.data.location.prix_location_retenue_annuel);
      setChargeAnnuel(props.data.charge.total_charges);
      setMontantTravaux(props.data.prix.montant_travaux);
    }
  }, [props.data]);
  useEffect(() => {
    // Calcul du montant amortissement annuel du bien
    setMontantAmortissementBienAnnuel(
      optionFiscale
        ? parseInt(
            (
              (props.data.prix.montant_travaux +
                props.data.prix.prix_bien_hors_fai) /
              30
            ).toFixed(0),
            10
          )
        : 0
    );
  }, [props.data, optionFiscale]);
  useEffect(() => {
    const timer = setTimeout(() => {
      //console.log("tableauChargeInteretAnnuel", tableauChargeInteretAnnuel);
      if (tableauChargeInteretAnnuel?.length > 0) {
        let revenuFoncierInitial = [];
        for (let i = 0; i < tableauChargeInteretAnnuel.length; i++) {
          revenuFoncierInitial.push(
            props.data.location.prix_location_retenue_annuel -
              props.data.charge.total_charges -
              tableauChargeInteretAnnuel[i].interet_mois
          );
        }
        //console.log("revenuFoncierInitial", revenuFoncierInitial);
        setRevenuFoncierAnnuel(revenuFoncierInitial);
      }
    }, 1000);

    return () => clearTimeout(timer);
  }, [
    // eslint-disable-line react-hooks/exhaustive-deps
    props.data,
    JSON.stringify(tableauChargeInteretAnnuel), // eslint-disable-line react-hooks/exhaustive-deps
  ]);
  const mainRender = () => (
    <GridContainer>
      <GridItem
        padding={false}
        item
        xs={12}
        sm={props.isbrut ? 12 : 6}
        md={props.isbrut ? 12 : 6}
        lg={props.isbrut ? 12 : 6}
      >
        <TableContainer className={classes.cardbudget}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell colSpan={2} align="center">
                  BUDGET ACQUISITION
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>Prix du bien FAI avec négociations</TableCell>
                <TableCell>
                  {new Intl.NumberFormat().format(
                    props.data.prix.prix_bien_fai - props.data.prix.negociation
                  ) + " €"}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Frais de notaire</TableCell>
                <TableCell>
                  {new Intl.NumberFormat().format(
                    props.data.prix.frais_notaire
                  ) + " €"}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Travaux et ameublement</TableCell>
                <TableCell>
                  {new Intl.NumberFormat().format(
                    props.data.prix.montant_travaux +
                      props.data.prix.montant_meuble
                  ) + " €"}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  Frais de financement (courtage, caution, dossier)
                </TableCell>
                <TableCell>
                  {new Intl.NumberFormat().format(
                    props.data.financement.frais_courtage +
                      props.data.financement.frais_dossier_financement +
                      props.data.financement.frais_garanties_cuationnement
                  ) + " €"}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Commissions et autres frais</TableCell>
                <TableCell>
                  {new Intl.NumberFormat().format(
                    props.data.prix.commission_wealzy +
                      props.data.prix.autres_frais
                  ) + " €"}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  align="right"
                  style={{
                    color: props.pcolor,
                    fontWeight: "bold",
                    borderStyle: "none",
                  }}
                >
                  Budget total
                </TableCell>
                <TableCell
                  style={{
                    fontWeight: "bold",
                    borderStyle: "none",
                    color: props.pcolor,
                  }}
                >
                  {new Intl.NumberFormat().format(
                    props.data.prix.prix_bien_complet != 0
                      ? props.data.prix.prix_bien_complet
                      : props.data.prix.prix_bien_fai
                      ? props.data.prix.prix_bien_fai
                      : props.data.prix.prix_bien_hors_fai
                  ) + " €"}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        {!props.isbrut && (
          <TableContainer
            style={{
              padding: 16,
              borderStyle: "solid",
              margin: 9,
              borderColor: "#eee",
              borderRadius: 4,
            }}
          >
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell colSpan={3} align="center">
                    FINANCEMENT
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell colSpan={2}>Apport personnel</TableCell>

                  {new Intl.NumberFormat().format(
                    props.data.financement.apport_personnel
                  ) == undefined ||
                  Number.isNaN(
                    Number.parseInt(props.data.financement.apport_personnel)
                  ) ? (
                    <TableCell>{0 + " €"}</TableCell>
                  ) : (
                    <TableCell>
                      {new Intl.NumberFormat().format(
                        props.data.financement.apport_personnel
                      ) + " €"}
                    </TableCell>
                  )}
                </TableRow>
                <TableRow>
                  <TableCell colSpan={2}>Montant à emprunter</TableCell>
                  {new Intl.NumberFormat().format(
                    props.data.financement.montant_a_emprunter
                  ) == undefined ||
                  Number.isNaN(
                    Number.parseInt(props.data.financement.montant_a_emprunter)
                  ) ? (
                    <TableCell>{0 + " €"}</TableCell>
                  ) : (
                    <TableCell>
                      {new Intl.NumberFormat().format(
                        props.data.financement.montant_a_emprunter
                      ) + " €"}
                    </TableCell>
                  )}
                </TableRow>
                <TableRow>
                  <TableCell colSpan={2}>Taux emprunt + assurance</TableCell>
                  <TableCell>
                    {isEmpty(props.data.financement.taux_emprunt)
                      ? props.data.financement.taux_assurance + "%"
                      : isEmpty(props.data.financement.taux_assurance)
                      ? props.data.financement.taux_emprunt + "%"
                      : isEmpty(props.data.financement.taux_emprunt) &&
                        isEmpty(props.data.financement.taux_assurance)
                      ? 0
                      : (
                          props.data.financement.taux_emprunt +
                          props.data.financement.taux_assurance
                        ).toFixed(2) + "%"}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={2}>Durée de l'emprunt</TableCell>
                  {props.data.financement.nbe_mensualite_remboursement ==
                  undefined ? (
                    <TableCell>{0 + " mois"}</TableCell>
                  ) : (
                    <TableCell>
                      {props.data.financement.nbe_mensualite_remboursement +
                        " mois"}
                    </TableCell>
                  )}
                </TableRow>
                <TableRow>
                  <TableCell colSpan={2}>
                    Charges d'intérêts (moyenne sur 5 ans)
                  </TableCell>
                  {props.data.fiscalite !== undefined &&
                  props.data.fiscalite.charges_interets_moyen_5_ans ==
                    undefined ? (
                    <TableCell>{arraytab + " €/an"}</TableCell>
                  ) : (
                    <TableCell>
                      {props.data.fiscalite !== undefined &&
                        props.data.fiscalite.charges_interets_moyen_5_ans +
                          " €/an"}
                    </TableCell>
                  )}
                </TableRow>
                <TableRow>
                  <TableCell
                    align="right"
                    style={{
                      color: props.pcolor,
                      fontWeight: "bold",
                      borderStyle: "none",
                    }}
                  >
                    Remboursement
                  </TableCell>

                  {new Intl.NumberFormat().format(
                    new Intl.NumberFormat().format(mensualiteRemboursement)
                  ) == undefined ||
                  Number.isNaN(
                    Number.parseInt(
                      new Intl.NumberFormat().format(mensualiteRemboursement)
                    )
                  ) ? (
                    <TableCell
                      style={{
                        color: props.pcolor,
                        fontWeight: "bold",
                        borderStyle: "none",
                      }}
                    >
                      {parseInt(
                        montantMensualiteAssurance +
                          montantMensualiteHorsAssurance,
                        10
                      ) + " €/mois"}
                    </TableCell>
                  ) : (
                    <TableCell
                      style={{
                        color: props.pcolor,
                        fontWeight: "bold",
                        borderStyle: "none",
                      }}
                    >
                      {new Intl.NumberFormat().format(
                        parseInt(mensualiteRemboursement, 10)
                      ) + " €/mois"}
                    </TableCell>
                  )}
                  {new Intl.NumberFormat().format(
                    new Intl.NumberFormat().format(mensualiteRemboursement)
                  ) == undefined ||
                  Number.isNaN(
                    Number.parseInt(
                      new Intl.NumberFormat().format(mensualiteRemboursement)
                    )
                  ) ? (
                    <TableCell
                      style={{
                        color: props.pcolor,
                        fontWeight: "bold",
                        borderStyle: "none",
                      }}
                    >
                      {parseInt(
                        montantMensualiteAssurance +
                          montantMensualiteHorsAssurance,
                        10
                      ) *
                        12 +
                        " €/an"}
                    </TableCell>
                  ) : (
                    <TableCell
                      style={{
                        color: props.pcolor,
                        fontWeight: "bold",
                        borderStyle: "none",
                      }}
                    >
                      {new Intl.NumberFormat().format(
                        parseInt(mensualiteRemboursement * 12, 10)
                      ) + " €/an"}
                    </TableCell>
                  )}
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </GridItem>
      {!props.isbrut && (
        <GridItem padding={false} xs={12} sm={6} md={6} lg={6}>
          <TableContainer
            style={{
              padding: 16,
              borderStyle: "solid",
              margin: 9,
              borderColor: "#eee",
              borderRadius: 4,
            }}
          >
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell colSpan={3} align="center">
                    REVENUS
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>Revenus locatifs</TableCell>
                  <TableCell>
                    {new Intl.NumberFormat().format(
                      parseInt(
                        props.data.location.prix_location_retenue_annuel / 12,
                        10
                      )
                    ) + " €/mois"}
                  </TableCell>
                  <TableCell>
                    {new Intl.NumberFormat().format(
                      props.data.location.prix_location_retenue_annuel
                    ) + " €/an"}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    align="right"
                    style={{
                      color: props.pcolor,
                      fontWeight: "bold",
                      borderStyle: "none",
                    }}
                  >
                    Revenus total
                  </TableCell>
                  <TableCell
                    style={{
                      color: props.pcolor,
                      fontWeight: "bold",
                      borderStyle: "none",
                    }}
                  >
                    {new Intl.NumberFormat().format(
                      parseInt(
                        props.data.location.prix_location_retenue_annuel / 12,
                        10
                      )
                    ) + " €/mois"}
                  </TableCell>
                  <TableCell
                    style={{
                      color: props.pcolor,
                      fontWeight: "bold",
                      borderStyle: "none",
                    }}
                  >
                    {new Intl.NumberFormat().format(
                      props.data.location.prix_location_retenue_annuel
                    ) + " €/an"}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <TableContainer
            style={{
              padding: 16,
              borderStyle: "solid",
              margin: 9,
              borderColor: "#eee",
              borderRadius: 4,
            }}
          >
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell colSpan={3} align="center">
                    CHARGES D'EXPLOITATION
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>Copropriété et entretien</TableCell>
                  <TableCell>
                    {new Intl.NumberFormat().format(
                      parseInt(
                        props.data.charge.charges_copropriete / 12 +
                          props.data.charge.entretien_courant / 12,
                        10
                      )
                    ) + " €/mois"}
                  </TableCell>
                  <TableCell>
                    {new Intl.NumberFormat().format(
                      props.data.charge.charges_copropriete +
                        props.data.charge.entretien_courant
                    ) + " €/an"}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Assurances (PNO, Impayés, etc.)</TableCell>
                  <TableCell>
                    {new Intl.NumberFormat().format(
                      parseInt(
                        props.data.charge.assurance_pno / 12 +
                          props.data.charge.garantie_impayes / 12,
                        10
                      )
                    ) + " €/mois"}
                  </TableCell>
                  <TableCell>
                    {new Intl.NumberFormat().format(
                      parseInt(props.data.charge.assurance_pno, 10) +
                        parseInt(props.data.charge.garantie_impayes, 10)
                    ) + " €/an"}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Gestion et autres charges</TableCell>
                  <TableCell>
                    {new Intl.NumberFormat().format(
                      parseInt(
                        props.data.charge.charges_gestion / 12 +
                          props.data.charge.charges_complementaires / 12,
                        10
                      )
                    ) + " €/mois"}
                  </TableCell>
                  <TableCell>
                    {new Intl.NumberFormat().format(
                      props.data.charge.charges_gestion +
                        props.data.charge.charges_complementaires
                    ) + " €/an"}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Taxe foncière</TableCell>
                  <TableCell>
                    {new Intl.NumberFormat().format(
                      parseInt(props.data.charge.taxe_fonciere / 12, 10)
                    ) + " €/mois"}
                  </TableCell>
                  <TableCell>
                    {new Intl.NumberFormat().format(
                      props.data.charge.taxe_fonciere
                    ) + " €/an"}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    align="right"
                    style={{
                      fontWeight: "bold",
                      borderStyle: "none",
                      color: props.pcolor,
                    }}
                  >
                    Charges totales
                  </TableCell>
                  <TableCell
                    style={{
                      fontWeight: "bold",
                      borderStyle: "none",
                      color: props.pcolor,
                    }}
                  >
                    {new Intl.NumberFormat().format(
                      parseInt(props.data.charge.total_charges / 12, 10)
                    ) + " €/mois"}
                  </TableCell>
                  <TableCell
                    style={{
                      fontWeight: "bold",
                      borderStyle: "none",
                      color: props.pcolor,
                    }}
                  >
                    {new Intl.NumberFormat().format(
                      props.data.charge.total_charges
                    ) + " €/an"}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <TableContainer
            style={{
              padding: 16,
              borderStyle: "solid",
              margin: 9,
              borderColor: "#eee",
              borderRadius: 4,
            }}
          >
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell colSpan={3} align="center">
                    RESULTATS SUR 5 ANS
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>Rendement</TableCell>
                  <TableCell>
                    {parseInt(
                      (props.data.location.prix_location_retenue_annuel /
                        props.data.prix.prix_bien_complet) *
                        100 *
                        10,
                      10
                    ) /
                      10 +
                      " % brut"}
                  </TableCell>
                  <TableCell>
                    {props.data.rendement.rendement_net_retenue + " % net"}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Revenus fonciers</TableCell>
                  <TableCell></TableCell>
                  {props.data.fiscalite !== undefined && (
                    <TableCell>
                      {props.data.fiscalite.revenu_foncier_annuel
                        ? new Intl.NumberFormat().format(
                            props.data.fiscalite.revenu_foncier_annuel
                          )
                        : revenuFoncierAnnuel + " €/an"}
                    </TableCell>
                  )}
                </TableRow>
                <TableRow>
                  <TableCell
                    colSpan={2}
                    align="right"
                    style={{
                      color: props.pcolor,
                      fontWeight: "bold",
                      borderStyle: "none",
                    }}
                  >
                    Cash Flow avant impôt
                  </TableCell>
                  <TableCell
                    style={{
                      color: props.pcolor,
                      fontWeight: "bold",
                      borderStyle: "none",
                    }}
                  >
                    {cashFlowMoyen !== cashFlowMoyen
                      ? "à calculer"
                      : new Intl.NumberFormat().format(
                          parseInt(cashFlowMoyen)
                        ) + "€/an"}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </GridItem>
      )}
    </GridContainer>
  );

  return mainRender();
}
