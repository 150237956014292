import React, { useEffect } from "react";
import logo from "../../../../img/wealzy/logo_vertical_haut_bleu_texte_bleu.png";
import "./Couverture.css";
const { fileServerUrl } = require("../../../../shared/values/environnement");

const Couverture = (props) => {
  useEffect(() => {
    console.log(props.bien, "props.bien");
  }, [props.bien]);
  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  }
  return (
    <div style={{ height: "100%" }}>
      <div className="cover_container">
        <div className="cover_media">
          <img
            src={
              props.logo && props.logo?.length > 0
                ? fileServerUrl + "/" + props.logo
                : logo
            }
            alt="logo"
            className="cover_logo"
          />
        </div>
        <div className="cover_content">
          <h3 style={{ color: "#122659" }}>
            {props.bien.type_bien_corrige.toUpperCase()} DE{" "}
            {props.bien.nbe_piece} PIECES ET DE {new Intl.NumberFormat().format( parseInt(props.bien.surface))} M2
          </h3>
          <h3 style={{ color: "#122659" }}>
            Quartier de {props.bien.nom_commune}
          </h3>
          <h3 style={{ color: "#122659" }}>
            {props.bien.code_insee} {props.bien.nom_commune}
          </h3>
          <h3 style={{ color: "#122659" }}>
            {numberWithCommas(
              props.bien.qualification_robot.prix.prix_bien_complet?props.bien.qualification_robot.prix.prix_bien_complet
              :props.bien.prix_bien
            )}{" "}
            €
          </h3>
          {props.bien.annonceorbien == 0 && (
            <h3 style={{ color: "#122659" }}>
              {
               parseInt((props.bien.qualification_robot.location.prix_location_retenue_annuel /props.bien.qualification_robot.prix.prix_bien_complet ) * 100 * 10, 10) /
               10
              }
              {"% "}
              brut
            </h3>
          )}
        </div>
      </div>
    </div>
  );
};

export default Couverture;
