import React,{useEffect} from 'react'
import PropTypes from 'prop-types'

// Material UI components
import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'

function CardKpiWithIcon
  (props) {
  // Définition des styles
  const useStyles = makeStyles(theme => ({
    card: {
      margin: theme.spacing(1),
      height: props.height,
      position: 'relative',
      [theme.breakpoints.down('sm')]: {
        height: '150px',
      },
      [theme.breakpoints.down('xs')]: {
        height: '130px',
      },
      borderColor: '#eee',
      borderStyle: 'solid',
      borderRadius: '4px',
      boxShadow: 'none',
      '@media print': {
        height: props.heightPrint,
        margin: theme.spacing(0.5),
      }
    },
    cardValue: {
      fontSize: '1.8rem',
      color: theme.palette.secondary.main,
      padding: 0,
      lineHeight: props.subValue !== '' ? '2rem' : '2.5rem',
      [theme.breakpoints.down('xs')]: {
        fontSize: '2.5rem',
      }
    },
    cardTitle: {
      fontSize: '1.1rem',
      color: theme.palette.primary.main
    },
    cardSubtitle: {
      fontSize: '0.8rem',
      color: theme.palette.primary.light,
      left: '3px',
      bottom: 0,
      position: 'absolute',
      margin: 0,
      paddingBottom: theme.spacing(0)
    },
    cardSubtitle2: {
      fontSize: '0.8rem',
      color: theme.palette.primary.light,
      right: '3px',
      bottom: 0,
      position: 'absolute',
      margin: 0,
      paddingBottom: theme.spacing(0)
    },
    gridIcon: {
      textAlign: 'center',
      textTransform: 'uppercase',
      width: '30%',
      color: theme.palette.primary.main
    },
    gridValue: {
      textAlign: 'center',
      paddingTop: theme.spacing(0.5),
      width: '70%',
    },
    cardSubValue: {
      fontSize: '1rem',
    }
  }))
  const classes = useStyles()
  useEffect(() => {

  },[props.scolor,props.pcolor])
  return (
    <Grid item xs={12} sm={12} md={12}>
      <Card className={classes.card}>
        <CardContent>
          <Typography align={'center'} style={{color:props.pcolor}} gutterBottom variant="h5" component="h2" className={classes.cardTitle}>
            {props.title}
          </Typography>
          <Grid container>
            <Grid item className={classes.gridIcon} style={{color:props.pcolor}}>
              {props.icon}
              <Typography align={'center'} variant="subtitle2" style={{color:props.pcolor}}>
                {props.iconTitle}
              </Typography>
            </Grid>
          <Grid item className={classes.gridValue}>
              <Typography align={'center'} variant="h4" component="h3" className={classes.cardValue} style={{color:props.scolor}}>
                {props.value}
              </Typography>
              <Typography align={'center'} variant="body1" className={classes.cardSubValue} style={{color:props.pcolor}}>
                {props.subValue}
              </Typography>
            </Grid>
          </Grid>
          <Typography paragraph align={'center'} variant="subtitle1" color="textSecondary" className={classes.cardSubtitle} style={{color:props.pcolor}}>
            {props.subtitle}
          </Typography>
        </CardContent>
      </Card>
    </Grid>)
}

CardKpiWithIcon.defaultProps = {
  title: '',
  subtitle: '',
  height: 170,
  heightPrint: 170,
  value: 0
}

CardKpiWithIcon.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  height: PropTypes.number,
  heightPrint: PropTypes.number,
}

export default CardKpiWithIcon
