import React from 'react'
import PropTypes from 'prop-types'

import { Grid as GridLoader } from 'react-spinners-css'
import Backdrop from '@material-ui/core/Backdrop'
import { makeStyles, useTheme } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: 1000,
    color: '#fff',
  },
  container: {
    textAlign: 'center',
    fontSize: '2rem'
  }
}))

export default function BackDropLoader(props) {
  const classes = useStyles()
  const theme = useTheme()

  return (
    <div>
      <Backdrop className={classes.backdrop} open={true}>
        <div className={classes.container}>
          <GridLoader color={theme.palette.secondary.main} size={200}/>
          <div>{props.title}</div>
        </div>
      </Backdrop>
    </div>
  )
}

BackDropLoader.defaultProps = {
  title: 'CHARGEMENT ...',
}

BackDropLoader.propTypes = {
  title: PropTypes.string
}