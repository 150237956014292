import { createMuiTheme } from '@material-ui/core/styles'
import createBreakpoints from '@material-ui/core/styles/createBreakpoints'
//import ArciformOtf from '../../fonts/Arciform.otf'

/* const Arciform = {
  fontFamily: 'Arciform',
  fontWeight: 400,
  src: `
    local('Arciform'),
    url(${ArciformOtf}) format('opentype')
  `,
} */

const breakpoints = createBreakpoints({})

export const overridesTheme = createMuiTheme({
  overrides: {
    MuiAlert: {
      root: {
        fontSize: '1rem',
        width: '80%',
        marginLeft: 'auto',
        marginRight: 'auto'
      },
    },
    MuiAppBar: {
      root: {
        zIndex: 1100
      },
    },
    MuiTooltip: {
      tooltip: {
        fontSize: '1rem',
      },
    },
    MuiSnackbar: {
      anchorOriginRightCenter: {
        top: '100px',
        [breakpoints.up('xs')]: {
          top: '100px!important',
        },
        [breakpoints.up('sm')]: {
          top: '100px!important',
        },
        [breakpoints.up('md')]: {
          top: '100px!important',
        },
      }
    },
    MuiInputBase: {
      root: {
        //backgroundColor: "#eee"
      }
    },
    MuiIconButton: {
      root: {
        padding: 0
      }
    },
    MuiExpansionPanelDetails: {
      root: {
        [breakpoints.down('xs')]: {
          padding: 0
        },
      }
    },
    MuiAccordionDetails: {
      root: {
        [breakpoints.down('xs')]: {
          padding: 0
        },
      }
    },
    MuiExpansionPanelSummary: {
      content: {
        [breakpoints.down('xs')]: {
          display: 'inline-block',
          textAlign: 'center'
        },
      }
    },
    MuiSlider: {
      root: {
        color: '#2ed6ff',
        height: 3,
        padding: '13px 0',
        width: '90%'
      },
      thumb: {
        height: 27,
        width: 27,
        backgroundColor: '#122659',
        marginTop: -12,
        marginLeft: -13,
        boxShadow: '#ebebeb 0 2px 2px',
        '&:focus, &:hover, &$active': {
          boxShadow: '#ccc 0 2px 3px 1px',
        },
        '& .bar': {
          // display: inline-block !important
          height: 9,
          width: 1,
          backgroundColor: '#fff',
          marginLeft: 1,
          marginRight: 1,
        },
      },
      active: {},
      track: {
        height: 3,
      },
      rail: {
        color: '#d8d8d8',
        opacity: 1,
        height: 3,
      }
    },
    MuiTableCell: {
      head: {
        color: '#122659',
        fontWeight: 'bold',
        borderBottomWidth: 3,
        borderBottomColor: '#122659'
      },
      body: {
        color: '#122659',
      },
      root: {
        padding: 8
      }
    },
    MuiTableContainer: {
      root: {
        width: 'inherit'
      }
    }
  },
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: '#122659',
      // dark: will be calculated from palette.primary.main,
      contrastText: '#fff',
    },
    secondary: {
      light: '#17f0de',
      main: '#2ed6ff',
      // dark: will be calculated from palette.secondary.main,
      contrastText: '#fff',
    },
    info: {
      main: '#17f0de',
    },
    background: {
      // light: will be calculated from palette.primary.main,
      main: '#f5f5f5',
      // dark: will be calculated from palette.secondary.main,
      contrastText: '#122659',
    },
    error: {
      main: '#f44336',
      light: '#e57373',
      contrastText: '#fff',
    }
  },
  typography: {
    fontFamily: "'Arciform', 'Roboto'",
  }
})

overridesTheme.typography.h1 = {
  fontSize: '1.5rem',
  fontFamily: "'Arciform', 'Roboto'",
  color: overridesTheme.palette.primary.main,
  paddingBottom: overridesTheme.spacing(2),
  [breakpoints.down('xs')]: {
    fontSize: '1.4rem',
    textAlign: 'center'
  },
}

overridesTheme.typography.h2 = {
  fontSize: '1.3rem',
  fontFamily: "'Arciform', 'Roboto'",
  color: overridesTheme.palette.secondary.main,
  paddingBottom: overridesTheme.spacing(1),
  [breakpoints.down('xs')]: {
    fontSize: '1.2rem',
    textAlign: 'center'
  },
}

overridesTheme.typography.h3 = {
  fontSize: '1.2rem',
  fontFamily: "'Arciform', 'Roboto'",
  color: overridesTheme.palette.primary.main,
  paddingBottom: overridesTheme.spacing(1),
  [breakpoints.down('xs')]: {
    fontSize: '1.1rem',
    textAlign: 'center'
  }
}

overridesTheme.typography.h4 = {
  fontSize: '1rem',
  color: overridesTheme.palette.primary.main,
  paddingBottom: overridesTheme.spacing(1)
}

overridesTheme.typography.h5 = {
  fontSize: '0.8rem',
  fontWeight: 'bold',
  color: overridesTheme.palette.primary.main,
  paddingBottom: overridesTheme.spacing(1)
}

overridesTheme.typography.body1 = {
  fontSize: '0.8rem',
  fontFamily: 'Fira Sans',
  color: overridesTheme.palette.primary.main,
  paddingBottom: overridesTheme.spacing(0),
  textAlign: 'left'
}

overridesTheme.typography.body2 = {
  fontSize: '0.8rem',
  fontFamily: 'Fira Sans',
  color: overridesTheme.palette.primary.main,
  paddingBottom: overridesTheme.spacing(0),
  textAlign: 'left'
}

overridesTheme.typography.subtitle1 = {
  fontSize: '0.8rem',
  fontFamily: 'Fira Sans',
  color: overridesTheme.palette.primary.main,
  paddingBottom: overridesTheme.spacing(2)
}

