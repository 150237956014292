import axios from 'axios';
import {
} from '../actions/types';
const { getListeTypeFromCollection } = require('../shared/functions/getServiceGroupCategorie')
const { APIServerUrl } = require('../shared/values/environnement')

// Get commune by Insee
// Redux False
export const getServicesByInseeAndCollection = (codeInsee, collection) => {
  const listeType = getListeTypeFromCollection(collection);
  const data = {
    code_insee: codeInsee,
    liste_type: listeType
  }

  return axios
    .post(APIServerUrl + '/api/services/liste/code-insee/liste-type', data)
    .then(res => {
      return res.data
    })
    .catch(err => {
      return err
    })
}

// Get services around a point
// Redux False
export const getServicesAround = (type, categorie, lat, lon, rayon) => {
  console.log(type,categorie,lat,lon,rayon)
  return axios
    .get(APIServerUrl + '/api/services/type/' + type + '/categorie/' + categorie + '/around/' + lon + '/' + lat + '/rayon/' + rayon)
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err;
    })
}

export const getServicesAroundByinsee = (code) => {
  return axios
    .get(APIServerUrl + '/api/commune/cherche/'+code)
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err;
    })
}

