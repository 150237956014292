import React from 'react'

// Material UI 
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'

// Styles
const useStyles = makeStyles(theme => ({
  gridContainer: {
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(0),
      paddingRight: theme.spacing(0),
    },
    '@media print': {
      paddingLeft: theme.spacing(0),
      paddingBottom: theme.spacing(0),
      paddingRight: theme.spacing(0),
    }
  },
}))

export default function GridContainer(props) {
  const classes = useStyles()

  const mainRender = () => (
    <Grid container className={classes.gridContainer} style={props.style}>
      {props.children}
    </Grid>
  )

  return (
    mainRender()
  )
}

GridContainer.propTypes = {

}