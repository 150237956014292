import { SEARCH_COMMUNE, SEARCH_COMMUNE_COORDINATES } from '../actions/types';

const initialState = {
  searchCommune: null,
  searchCommuneCoordinates: null
};

export default function(state = initialState, action) {
  switch (action.type) {
    case SEARCH_COMMUNE:
      return {
        ...state,
        searchCommune: action.payload
      };
    case SEARCH_COMMUNE_COORDINATES:
      return {
        ...state,
        searchCommuneCoordinates: action.payload
      };
    default:
      return state;
  }
}
