import {useEffect} from "react"
import socketIOClient from "socket.io-client"


function useSocket(user,dispatch){
    console.log("aa",user)
    useEffect(()=>{
        // const socket = socketIOClient.connect('https://localhost:3000/')
        // socket.emit('join',user)
    },[dispatch])
}

export default useSocket