import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import registerServiceWorker ,{unregister} from './registerServiceWorker'
import CssBaseline from '@material-ui/core/CssBaseline'
import { ThemeProvider } from '@material-ui/core/styles'
import { overridesTheme } from './shared/theme/overridesTheme'
import { Provider } from 'react-redux'
import { SocketContext, socket } from "./App";
import store from './store'
 

ReactDOM.render(
  <ThemeProvider theme={overridesTheme}>
    <CssBaseline />
    <Provider store={store}>
 
      {/* <SocketContext.Provider value={socket}>  */}
    <App />
     {/* </SocketContext.Provider> */}

</Provider>,
   
  </ThemeProvider>,
  document.getElementById('root')
)

unregister();
