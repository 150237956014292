import { 
    GET_ONE_DISTRICT,
    GET_DISTRICT_SUGGESTIONS, 
    GET_DISTRICT_COORDINATES,
} from '../actions/types'

const initialState = {
    district: {},
    districts: [],
    mapInfoDistrict: {},
    districtCoordinates: {}
}

export default function(state = initialState, action) {
    switch(action.type) {
        case GET_ONE_DISTRICT:
            return {
                district: action.payload,
                districts: state.districts,
                mapInfoDistrict: state.mapInfoDistrict,
                districtCoordinates: state.districtCoordinates
            }
        case GET_DISTRICT_SUGGESTIONS:
            return {
                ...state,
                districts: action.payload,
                mapInfoDistrict: state.mapInfoDistrict,
                districtCoordinates: state.districtCoordinates
            }
        case GET_DISTRICT_COORDINATES:
            return {
                ...state,
                districtCoordinates: action.payload
            }
        default:
            return state
    }
}