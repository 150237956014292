const isEmpty = value =>
  value === undefined ||
  value === null ||
  (typeof value === 'object' && Object.keys(value).length === 0) ||
  (typeof value === 'string' && value.trim().length === 0);

const isObjectEmpty = object => {
  if (isEmpty(object)) {
    return true
  } else return false
  //return Object.values(object).some(v => isEmpty(v))
}

const isArrayEmpty = array => {
  if (isEmpty(array)) {
    return true
  } else return false
  //return array.some(v => isEmpty(v))
}

module.exports = {
  isEmpty,
  isObjectEmpty,
  isArrayEmpty
}
