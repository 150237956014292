var pdfExportSetting = {
  pageSize: "A4",

  pageOrientation: "landscape",

  pageMargins: [0, 0, 0, 0],
  content: [],
};

var pdfExportSettingnew = {
  pageSize: "A4",

  pageOrientation: "landscape",

  pageMargins: [0, 0, 0, 0],
  content: [],
};

var pdfExportSettingSynthese = {
  pageSize: "A4",

  pageOrientation: "landscape",

  content: [],
};

const generatepdfsettingnew = (settings) => {
  settings.forEach((setting) => {
    console.log(setting.name);
    if (setting.name === "fin") {
      pdfExportSetting.content.push({
        width: 850, // for 50 % image width
        height: 600, // change the numbers accordingly
        absolutePosition: {
          // absolute positioning if required
          x: -25,
        },

        image: setting.img,
      });
    } else {
      pdfExportSetting.content.push({
        width: 850, // for 50 % image width
        height: 550, // change the numbers accordingly
        absolutePosition: {
          // absolute positioning if required
          x: -25,
        },

        pageBreak: "after",
        image: setting.img,
      });
    }
  });

  let final = pdfExportSetting;
  pdfExportSetting = {
    pageSize: "A4",

    pageOrientation: "landscape",

    pageMargins: [0, 0, 0, 0],
    content: [],
  };

  return final;
};

const generatepdfsetting = (settings) => {
  settings.forEach((setting) => {
    console.log(setting.name);

    if (setting.name === "commerces") {
      pdfExportSetting.content.push({
        width: 950, // for 50 % image width
        height: 950, // change the numbers accordingly
        absolutePosition: {
          // absolute positioning if required
          x: -5,
          y: 2,
        },

        image: setting.img,
      });
    }

    if (setting.name === "fin") {
      pdfExportSetting.content.push({
        width: 950, // for 50 % image width
        height: 950, // change the numbers accordingly
        absolutePosition: {
          // absolute positioning if required
          x: -9,
          y: 5,
        },

        image: setting.img,
      });
    } else if (setting.name == "logement_ville") {
      pdfExportSetting.content.push({
        width: 950, // for 50 % image width
        height: 950, // change the numbers accordingly
        absolutePosition: {
          // absolute positioning if required
          x: -9,
          y: 5,
        },

        pageBreak: "after",
        image: setting.img,
      });
    } else {
      pdfExportSetting.content.push({
        width: 950, // for 50 % image width
        height: 600, // change the numbers accordingly
        absolutePosition: {
          // absolute positioning if required
          x: -9,
          y: 2,
        },

        pageBreak: "after",
        image: setting.img,
      });
    }
  });
  let final = pdfExportSetting;
  pdfExportSetting = {
    pageSize: "A4",

    pageOrientation: "landscape",

    pageMargins: [0, 0, 0, 0],
    content: [],
  };

  return final;
};

const generatepdfsettingsynthese = (settings) => {
  settings.forEach((setting) => {
    if (setting.name === "fin") {
      pdfExportSettingSynthese.content.push({
        width: 850, // for 50 % image width
        height: 600, // change the numbers accordingly
        absolutePosition: {
          // absolute positioning if required
          x: -9,
          y: 5,
        },

        image: setting.img,
      });
    } else if (setting.name === "couverture") {
      pdfExportSettingSynthese.content.push({
        width: 850, // for 50 % image width
        height: 600, // change the numbers accordingly
        absolutePosition: {
          // absolute positioning if required
          x: -9,
          y: 5,
        },
        pageBreak: "after",

        image: setting.img,
      });
    } else {
      pdfExportSettingSynthese.content.push({
        // fit:[612.00,792.00],

        width: 950, // for 50 % image width
        height: 600, // change the numbers accordingly
        // absolutePosition: {  // absolute positioning if required
        //     x: 0,
        //     y: 0
        // },

        absolutePosition: {
          // absolute positioning if required
          x: -9,
          y: 5,
        },

        pageBreak: "after",
        image: setting.img,
      });
    }
  });

  return pdfExportSettingSynthese;
};
module.exports = {
  generatepdfsetting,
  generatepdfsettingsynthese,
  generatepdfsettingnew,
};
