import React from 'react'
import PropTypes from 'prop-types'

// Material UI 
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'

// Wealzy

export default function GridItem(props) {

  // Styles
  const useStyles = makeStyles(theme => ({
    gridItem: {
      paddingLeft: props.padding ? theme.spacing(2) : theme.spacing(0),
      paddingRight: props.padding ? theme.spacing(2) : theme.spacing(0),
      paddingBottom: props.padding ? theme.spacing(2) : theme.spacing(0),
      margin: props.margin ? theme.spacing(2) : theme.spacing(0),
      [theme.breakpoints.down('sm')]: {
        paddingLeft: props.padding ? theme.spacing(1) : theme.spacing(0),
        paddingRight: props.padding ? theme.spacing(1) : theme.spacing(0),
        paddingBottom: props.padding ? theme.spacing(1) : theme.spacing(0),
      },
      marginTop: props.marginTop,
      '@media print': {
        paddingLeft: props.printPadding ? theme.spacing(1) : theme.spacing(0),
        paddingRight: props.printPadding ? theme.spacing(1) : theme.spacing(0),
        paddingBottom: props.printPadding ? theme.spacing(1) : theme.spacing(0),
      }
    }
  }))

  const classes = useStyles()

  const mainRender = () => (
    <Grid 
      style={props.style} 
      id={props.id} 
      item 
      xs={props.xs} 
      sm={props.sm} 
      md={props.md} 
      lg={props.lg} 
      className={classes.gridItem}
      onClick={props.onClick}
    >
      {props.children}
    </Grid>
  )

  return (
    mainRender()
  )
}

GridItem.defaultProps = {
  xs: 12,
  md: 6,
  sm: 4,
  lg: 3,
  padding: true,
  printPadding: true,
  marginTop: 0,
  margin: false
}

GridItem.propTypes = {
  xs: PropTypes.number,
  sm: PropTypes.number,
  md: PropTypes.number,
  lg: PropTypes.number,
  marginTop: PropTypes.number,
  padding: PropTypes.bool,
  printPadding: PropTypes.bool,
  margin: PropTypes.bool
}