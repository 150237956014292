export const GET_ERRORS = "GET_ERRORS";
export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const CLEAR_ERRORS = "CLEAR_ERRORS";
export const CREATE_USER = "CREATE_USER";
export const RESET_USER = "RESET_USER";
export const UPDATE_USER_PHOTO = "UPDATE_USER_PHOTO";
export const UPDATE_USER_COLORS = "UPDATE_USER_COLORS";
export const DELETE_BIEN_WALLET = "DELETE_BIEN_WALLET";
export const REINIT_REGISTER = "REINIT_REGISTER";
export const GET_COMMUNE_FROM_COORD = "GET_COMMUNE_FROM_COORD";
export const GET_COMMUNE_AROUND = "GET_COMMUNE_AROUND";
export const GET_CONTOUR_COMMUNE_ALL = "GET_CONTOUR_COMMUNE_ALL";
export const GET_CONTOUR_DEPARTEMENT = "GET_CONTOUR_DEPARTEMENT";
export const GET_DEPARTEMENT_FROM_COORD = "GET_DEPARTEMENT_FROM_COORD";
export const GET_DEPARTEMENT_AROUND = "GET_DEPARTEMENT_AROUND";
export const GET_COMMUNE_EXTREMA = "GET_COMMUNE_EXTREMA";
export const DATA_MAP_LOCATION = "DATA_MAP_LOCATION";
export const DATA_MAP_VENTE_NOTAIRE = "DATA_MAP_VENTE_NOTAIRE";
export const DATA_MAP_VENTE_ACTUEL = "DATA_MAP_VENTE_ACTUEL";
export const DATA_MAP_RENDEMENT_BRUT = "DATA_MAP_RENDEMENT_BRUT";
export const SEARCH_COMMUNE = "SEARCH_COMMUNE";
export const SEARCH_COMMUNE_COORDINATES = "SEARCH_COMMUNE_COORDINATES";
export const PUSH_ERROR = "PUSH_ERROR";

export const GET_IRIS_FROM_COORD = "GET_IRIS_FROM_COORD";
export const GET_IRIS_FOR_ONE_COMMUNE = "GET_IRIS_FOR_ONE_COMMUNE";
export const GET_IRIS_AROUND = "GET_IRIS_AROUND";

export const GET_BIEN_ALL = "GET_BIEN_ALL";
export const GET_BIEN_SEARCH = "GET_BIEN_SEARCH";
export const UPDATE_CRITERE_RECHERCHE = "UPDATE_CRITERE_RECHERCHE";
export const UPDATE_CRITERE_RECHERCHE_REQSTART =
  "UPDATE_CRITERE_RECHERCHE_REQSTART";
export const RESET_BIEN_SEARCH = "RESET_BIEN_SEARCH";
export const RESET_BIEN_SEARCH_BRUT = "RESET_BIEN_SEARCH_BRUT";
export const RESET_BIEN_SEARCH_WALLET = "RESET_BIEN_SEARCH_WALLET";

export const RESET_DATA_BIEN = "RESET_DATA_BIEN";

export const GET_COUNT_TYPE_BIEN = "GET_COUNT_TYPE_BIEN";
export const GET_COUNT_SOURCE = "GET_COUNT_SOURCE";
export const GET_COUNT_SOURCE_24 = "GET_COUNT_SOURCE";
export const GET_COUNT_STATUT_QUALIFICATION = "GET_COUNT_STATUT_QUALIFICATION";
export const GET_COUNT_TODAY = "GET_COUNT_TODAY";
export const GET_LISTE_BIEN_SIMILAIRE_VENTE = "GET_LISTE_BIEN_SIMILAIRE_VENTE";

export const UPDATE_DATA_BIEN = "UPDATE_DATA_BIEN";
export const UPDATE_DATA_BIEN_NOT_YET = "UPDATE_DATA_BIEN_NOT_YET";
export const UPDATE_DATABIEN_FROM_WALLET = "UPDATE_DATABIEN_FROM_WALLET";
export const GET_LISTE_BIEN_FROM_WALLET = "GET_LISTE_BIEN_FROM_WALLET";
export const UPDATE_LISTE_BIEN_SIMILAIRE_VENTE =
  "UPDATE_LISTE_BIEN_SIMILAIRE_VENTE";
export const UPDATE_LISTE_BIEN_SIMILAIRE_LOCATION =
  "UPDATE_LISTE_BIEN_SIMILAIRE_LOCATION";
export const UPDATE_STATUT_QUALIFICATION = "UPDATE_STATUT_QUALIFICATION";
export const UPDATE_CARACTERISTIQUE_BIEN = "UPDATE_CARACTERISTIQUE_BIEN";
export const UPDATE_CARACTERISTIQUE_BIEN_SURFACE =
  "UPDATE_CARACTERISTIQUE_BIEN_SURFACE";
export const UPDATE_CARACTERISTIQUE_BIEN_NBE_PIECE =
  "UPDATE_CARACTERISTIQUE_BIEN_NBE_PIECE";
export const UPDATE_CARACTERISTIQUE_BIEN_DESCRIPTION_BIEN =
  "UPDATE_CARACTERISTIQUE_BIEN_DESCRIPTION_BIEN";
export const UPDATE_CARACTERISTIQUE_BIEN_TYPE_BIEN =
  "UPDATE_CARACTERISTIQUE_BIEN_TYPE_BIEN";
export const UPDATE_CARACTERISTIQUE_BIEN_STATUT_QUALIFICATION =
  "UPDATE_CARACTERISTIQUE_BIEN_STATUT_QUALIFICATION";
export const GET_ONE_DISTRICT = "GET_ONE_DISTRICT";
export const GET_DISTRICT = "GET_DISTRICT";
export const GET_DISTRICT_AROUND = "GET_DISTRICT_AROUND";
export const GET_DISTRICT_FROM_COORD = "GET_DISTRICT_FROM_COORD";
export const GET_DISTRICT_MAP_INFO = "GET_DISTRICT_MAP_INFO";
export const GET_DISTRICT_COORDINATES = "GET_DISTRICT_COORDINATES";
export const GET_DISTRICT_SUGGESTIONS = "GET_DISTRICT_SUGGESTIONS";

export const USER_GUIDE = "USER_GUIDE";

// USERS
export const USER_GEST = "USER_GEST";
export const USER_CLIENT = "USER_CLIENT";
export const GET_ALL_USERS = "GET_ALL_USERS";
export const GET_USER = "GET_USER";
export const DELETE_USER = "DELETE_USER";
export const ALERT = "ALERT";
export const ALERT_ACTIVER = "ALERT_ACTIVER";
export const ALERT_DESACTIVER = "ALERT_DESACTIVER";
export const UPDATE_USER = "UPDATE_USER";
export const UPDATE_USER_COMPTE = "UPDATE_USER_COMPTE";
export const UPDATE_USER_INFOS = "UPDATE_USER_INFOS";
export const UPDATE_USER_INFOS_ENTREPRISE = "UPDATE_USER_INFOS_ENTREPRISE";
export const UPDATE_USER_CRITERE_RECHERCHE = "UPDATE_USER_CRITERE_RECHERCHE";
export const UPDATE_USER_MANDAT = "UPDATE_USER_MANDAT";
export const UPDATE_USER_ERRORS = "UPDATE_USER_ERRORS";
export const UPDATE_DATA_USER_NOT_YET = "UPDATE_DATA_USER_NOT_YET";
export const UPDATE_WALLET_CLIENT = "UPDATE_WALLET_CLIENT";
export const GET_CREATE_OR_UPDATE_USER_ERRORS =
  "GET_CREATE_OR_UPDATE_USER_ERRORS";
export const REINIT_USER_MISE_A_JOUR = "REINIT_USER_MISE_A_JOUR";
export const UPDATE_IDSTRIPE = "UPDATE_IDSTRIPE";
export const UPDATE_IDSUB = "UPDATE_IDSUB";
export const UPDATE_ROLE = "UPDATE_ROLE";
export const UPDATE_MAIL = "UPDATE_MAIL";
export const LOGOUT_USER = "LOGOUT_USER";

// BIEN
export const GET_ANNONCE_NON_INVESTISSEUR = "GET_ANNONCE_NON_INVESTISSEUR";
export const GET_ANNONCE_WALLET = "GET_ANNONCE_WALLET";
export const GET_DATA_BIEN = "GET_DATA_BIEN";
export const SEND_ANNONCE_A_QUALIFIER = "SEND_ANNONCE_A_QUALIFIER";
export const GET_NOMBRE_ANNONCE = "GET_NOMBRE_ANNONCE";
export const GET_NOMBRE_ANNONCE_BRUT = "GET_NOMBRE_ANNONCE_BRUT";

export const GET_NOMBRE_ANNONCE_WALLET = "GET_NOMBRE_ANNONCE_WALLET";

// ADRESSE ACTIONS
export const GET_ADRESS_FROM_COORD = "GET_ADRESS_FROM_COORD";

export const GET_TRANSPORTS_WITHIN = "GET_TRANSPORTS_WITHIN";
export const GET_MARKERS_AROUND = "GET_MARKERS_AROUND";
export const GET_MARKERS_AROUND_CONCAT = "GET_MARKERS_AROUND_CONCAT";

// UPDATE PHOTOS D'UN BIEN
export const UPDATE_PHOTO_BIEN = "UPDATE_PHOTO_BIEN";
export const UPDATE_FAVORIS_PHOTO_BIEN = "UPDATE_FAVORIS_PHOTO_BIEN";

// UPDATE ADRESSE D'UN BIEN
export const UPDATE_ADRESSE_BIEN = "UPDATE_ADRESSE_BIEN";

// UPDATE CLIENTS D'UN BIEN
export const UPDATE_CLIENT_BIEN = "UPDATE_CLIENT_BIEN";
export const UPDATE_CLIENT_LIST_TO_UPDATE = "UPDATE_CLIENT_LIST_TO_UPDATE";

// UPDATE PRIX D'UN BIEN
export const UPDATE_PRIX_BIEN_PRIX_BIEN_FAI = "UPDATE_PRIX_BIEN_PRIX_BIEN_FAI";
export const UPDATE_PRIX_BIEN_FRAIS_AGENCE = "UPDATE_PRIX_BIEN_FRAIS_AGENCE";
export const UPDATE_PRIX_BIEN_FRAIS_NOTAIRE = "UPDATE_PRIX_BIEN_FRAIS_NOTAIRE";
export const UPDATE_PRIX_BIEN_NEGOCIATION = "UPDATE_PRIX_BIEN_NEGOCIATION";
export const UPDATE_PRIX_BIEN_COMMISSION_WEALZY =
  "UPDATE_PRIX_BIEN_COMMISSION_WEALZY";
export const UPDATE_PRIX_BIEN_MONTANT_MEUBLE =
  "UPDATE_PRIX_BIEN_MONTANT_MEUBLE";
export const UPDATE_PRIX_BIEN_AUTRES_FRAIS = "UPDATE_PRIX_BIEN_AUTRES_FRAIS";
export const UPDATE_PRIX_BIEN_MONTANT_TRAVAUX =
  "UPDATE_PRIX_BIEN_MONTANT_TRAVAUX";
export const UPDATE_PRIX_BIEN_PRIX_BIEN_HORS_FAI =
  "UPDATE_PRIX_BIEN_PRIX_BIEN_HORS_FAI";
export const UPDATE_PRIX_BIEN_PRIX_BIEN_COMPLET =
  "UPDATE_PRIX_BIEN_PRIX_BIEN_COMPLET";
export const UPDATE_PRIX_BIEN_PRIX_M2_VENTE_REEL =
  "UPDATE_PRIX_BIEN_PRIX_M2_VENTE_REEL";
export const GET_CONNEXION = "GET_CONNEXION";

// UPDATE REVENU LOCATIF D'UN BIEN
export const UPDATE_REVENU_LOCATIF_PRIX_LOC_NUE_MENS =
  "UPDATE_REVENU_LOCATIF_PRIX_LOC_NUE_MENS";
export const UPDATE_REVENU_LOCATIF_PRIX_LOC_NUE_ANNU =
  "UPDATE_REVENU_LOCATIF_PRIX_LOC_NUE_ANNU";
export const UPDATE_REVENU_LOCATIF_PRIX_LOC_MEUBLEE_MENS =
  "UPDATE_REVENU_LOCATIF_PRIX_LOC_MEUBLEE_MENS";
export const UPDATE_REVENU_LOCATIF_PRIX_LOC_MEUBLEE_ANNU =
  "UPDATE_REVENU_LOCATIF_PRIX_LOC_MEUBLEE_ANNU";
export const UPDATE_REVENU_LOCATIF_ELIGIBLE_COLOCATION =
  "UPDATE_REVENU_LOCATIF_ELIGIBLE_COLOCATION";
export const UPDATE_REVENU_LOCATIF_NBE_CHAMBRE =
  "UPDATE_REVENU_LOCATIF_NBE_CHAMBRE";
export const UPDATE_REVENU_LOCATIF_PRIX_COLOC_CHAMBRE_MENSUEL =
  "UPDATE_REVENU_LOCATIF_PRIX_COLOC_CHAMBRE_MENSUEL";
export const UPDATE_REVENU_LOCATIF_PRIX_COLOC_MENSUEL =
  "UPDATE_REVENU_LOCATIF_PRIX_COLOC_MENSUEL";
export const UPDATE_REVENU_LOCATIF_PRIX_COLOC_ANNUEL =
  "UPDATE_REVENU_LOCATIF_PRIX_COLOC_ANNUEL";
export const UPDATE_REVENU_LOCATIF_PRIX_LOC_RETENUE_MENS =
  "UPDATE_REVENU_LOCATIF_PRIX_LOC_RETENUE_MENS";
export const UPDATE_REVENU_LOCATIF_PRIX_LOC_RETENUE_ANNU =
  "UPDATE_REVENU_LOCATIF_PRIX_LOC_RETENUE_ANNU";
export const UPDATE_REVENU_LOCATIF_OPTION_LOCATION =
  "UPDATE_REVENU_LOCATIF_OPTION_LOCATION";
export const UPDATE_REVENU_LOCATIF_PRIX_M2 = "UPDATE_REVENU_LOCATIF_PRIX_M2";

export const UPDATE_RENDEMENT_BRUT = "UPDATE_RENDEMENT_BRUT";
export const UPDATE_RENDEMENT_NET = "UPDATE_RENDEMENT_NET";
// UPDATE CHARGES D'UN BIEN
export const UPDATE_CHARGES_BIEN_ENTRETIEN_COURANT =
  "UPDATE_CHARGES_BIEN_ENTRETIEN_COURANT";
export const UPDATE_CHARGES_BIEN_CHARGES_COPROPRIETE =
  "UPDATE_CHARGES_BIEN_CHARGES_COPROPRIETE";
export const UPDATE_CHARGES_BIEN_ASSURANCE_PNO =
  "UPDATE_CHARGES_BIEN_ASSURANCE_PNO";
export const UPDATE_CHARGES_BIEN_CHARGES_GESTION =
  "UPDATE_CHARGES_BIEN_CHARGES_GESTION";
export const UPDATE_CHARGES_BIEN_GARANTIE_IMPAYES =
  "UPDATE_CHARGES_BIEN_GARANTIE_IMPAYES";
export const UPDATE_CHARGES_BIEN_TAXE_FONCIERE =
  "UPDATE_CHARGES_BIEN_TAXE_FONCIERE";
export const UPDATE_CHARGES_BIEN_CHARGES_COMPLEMENTAIRES =
  "UPDATE_CHARGES_BIEN_CHARGES_COMPLEMENTAIRES";
export const UPDATE_CHARGES_BIEN_CHARGES_TOTAL_CHARGES =
  "UPDATE_CHARGES_BIEN_CHARGES_TOTAL_CHARGES";

// UPDATE FINANCEMENT BIEN
export const UPDATE_FINANCEMENT_BIEN_MENSUALITE_REMBOURSEMENT =
  "UPDATE_FINANCEMENT_BIEN_MENSUALITE_REMBOURSEMENT";
export const UPDATE_FINANCEMENT_BIEN_MENSUALITE_REMBOURSEMENT_HORS_ASSURANCE =
  "UPDATE_FINANCEMENT_BIEN_MENSUALITE_REMBOURSEMENT_HORS_ASSURANCE";
export const UPDATE_FINANCEMENT_BIEN_MENSUALITE_REMBOURSEMENT_ASSURANCE =
  "UPDATE_FINANCEMENT_BIEN_MENSUALITE_REMBOURSEMENT_ASSURANCE";
export const UPDATE_FINANCEMENT_BIEN_FRAIS_DOSSIER_FINANCEMENT =
  "UPDATE_FINANCEMENT_BIEN_FRAIS_DOSSIER_FINANCEMENT";
export const UPDATE_FINANCEMENT_BIEN_FRAIS_COURTAGE =
  "UPDATE_FINANCEMENT_BIEN_FRAIS_COURTAGE";
export const UPDATE_FINANCEMENT_BIEN_FRAIS_GARANTIES_CAUTION =
  "UPDATE_FINANCEMENT_BIEN_FRAIS_GARANTIES_CAUTION";
export const UPDATE_FINANCEMENT_BIEN_TAUX_ASSURANCE =
  "UPDATE_FINANCEMENT_BIEN_TAUX_ASSURANCE";
export const UPDATE_FINANCEMENT_BIEN_TAUX_EMPRUNT =
  "UPDATE_FINANCEMENT_BIEN_TAUX_EMPRUNT";
export const UPDATE_FINANCEMENT_BIEN_NBE_MENSUALITE_REMBOURSEMENT =
  "UPDATE_FINANCEMENT_BIEN_NBE_MENSUALITE_REMBOURSEMENT";
export const UPDATE_FINANCEMENT_BIEN_MONTANT_A_EMPRUNTER =
  "UPDATE_FINANCEMENT_BIEN_MONTANT_A_EMPRUNTER";
export const UPDATE_FINANCEMENT_BIEN_APPORT_PERSO =
  "UPDATE_FINANCEMENT_BIEN_APPORT_PERSO";
export const UPDATE_FINANCEMENT_BIEN_TABLEAU_AMORTISSEMENT_PRET =
  "UPDATE_FINANCEMENT_BIEN_TABLEAU_AMORTISSEMENT_PRET";
export const UPDATE_FINANCEMENT_BIEN_TABLEAU_CAPITAL_REMBOURSE =
  "UPDATE_FINANCEMENT_BIEN_TABLEAU_CAPITAL_REMBOURSE";
export const UPDATE_FINANCEMENT_BIEN_TABLEAU_REVENU_AVANT_TRAVAUX =
  "UPDATE_FINANCEMENT_BIEN_TABLEAU_REVENU_AVANT_TRAVAUX";
export const UPDATE_CASH_FLOW_MOYEN = "UPDATE_CASH_FLOW_MOYEN";
// UPDATE FISCALITE BIEN
export const UPDATE_FISCALITE_BIEN_OPTION_FISCALE =
  "UPDATE_FISCALITE_BIEN_OPTION_FISCALE";
export const UPDATE_FISCALITE_BIEN_REVENU_FONCIER_ANNUEL =
  "UPDATE_FISCALITE_BIEN_REVENU_FONCIER_ANNUEL";
export const UPDATE_FISCALITE_BIEN_REVENU_FONCIER_ANNUEL_GLOBAL =
  "UPDATE_FISCALITE_BIEN_REVENU_FONCIER_ANNUEL_GLOBAL";

export const UPDATE_FISCALITE_BIEN_TABLEAU_CHARGE_INTERET =
  "UPDATE_FISCALITE_BIEN_TABLEAU_CHARGE_INTERET";
export const UPDATE_FISCALITE_BIEN_CHARGE_INTERET_MOY_5ANS =
  "UPDATE_FISCALITE_BIEN_CHARGE_INTERET_MOY_5ANS";
export const UPDATE_FISCALITE_BIEN_NBE_ANNEE_DEFICIT_FONCIER =
  "UPDATE_FISCALITE_BIEN_NBE_ANNEE_DEFICIT_FONCIER";
export const UPDATE_FISCALITE_BIEN_MONTANT_AMORTISSEMENT_BIEN_ANNUEL =
  "UPDATE_FISCALITE_BIEN_MONTANT_AMORTISSEMENT_BIEN_ANNUEL";
export const UPDATE_FISCALITE_BIEN_TAB_CHARGES_INTERETS_ANNUEL =
  "UPDATE_FISCALITE_BIEN_TAB_CHARGES_INTERETS_ANNUEL";
export const UPDATE_RENDEMENT_PV = "UPDATE_RENDEMENT_PV";
export const ADD_BIEN = "ADD_BIEN";

export const GET_BIEN_SIMILAIRE_VENTE = "GET_BIEN_SIMILAIRE_VENTE";
export const GET_BIEN_SIMILAIRE_LOCATION = "GET_BIEN_SIMILAIRE_LOCATION";
export const GET_BIEN_NOTAIRE = "GET_BIEN_NOTAIRE";

// COMMUNE
export const GET_COMMUNE_BY_INSEE = "GET_COMMUNE_BY_INSEE";

// CLIENT
export const GET_ALL_CLIENT_GESTIONNAIRE = "GET_ALL_CLIENT_GESTIONNAIRE";
export const GET_GESTIONNAIRE = "GET_GESTIONNAIRE";

// GESTIONNAIRES
export const GET_ALL_GESTIONNAIRE_ADMIN = "GET_ALL_GESTIONNAIRE_ADMIN";

//PAYMENT
export const PAYMENT_ACCEPTED = "PAYMENT_ACCEPTED";
export const PAYMENT_REFUSED = "PAYMENT_REFUSED";

// Critéres
export const CREATE_REQUEST = "CREATE_REQUEST";
export const UPDATE_REQUEST = "UPDATE_REQUEST";
export const RESET_REQUEST = "DELETE_REQUEST";
export const UPDATE_ISFILTRED = "UPDATE_ISFILTRED";
export const UPDATE_BUDGET_MAX = "UPDATE_BUDGET_MAX";
export const UPDATE_BUDGET_MIN = "UPDATE_BUDGET_MIN";
export const RESET_BUDGET = "RESET_BUDGET";
export const UPDATE_SURFACE_MAX = "UPDATE_SURFACE_MAX";
export const UPDATE_SURFACE_MIN = "UPDATE_SURFACE_MIN";
export const RESET_SURFACE = "RESET_SURFACE";
export const UPDATE_RENDEMENT_MAX = "UPDATE_RENDEMENT_MAX";
export const UPDATE_RENDEMENT_MIN = "UPDATE_RENDEMENT_MIN";
export const RESET_RENDEMENT = "RESET_RENDEMENT";
export const UPDATE_PRIX_M_MAX = "UPDATE_PRIX_M_MAX";
export const UPDATE_PRIX_M_MIN = "UPDATE_PRIX_M_MIN";
export const RESET_PRIX_M = "RESET_PRIX_M";
export const UPDATE_NBEPIECE = "UPDATE_NBEPIECE";
export const RESET_NBEPIECE = "RESET_NBEPIECE";
export const UPDATE_NBE_ETAGE = "UPDATE_NBE_ETAGE";
export const RESET_NBE_ETAGE = "RESET_NBE_ETAGE";
export const UPDATE_COMMUNES = "UPDATE_COMMUNES";
export const RESET_COMMUNES = "RESET_COMMUNES";
export const UPDATE_DEPARTEMENT = "UPDATE_DEPARTEMENT";
export const RESET_DEPARTEMENT = "RESET_DEPARTEMENT";
export const UPDATE_CLIENT = "UPDATE_CLIENT";
export const RESET_CLIENT = "RESET_CLIENT";
export const UPDATE_TYPE_ANNONCE = "UPDATE_TYPE_ANNONCE";
export const RESET_TYPE_ANNONCE = "RESET_TYPE_ANNONCE";
export const UPDATE_TYPE_BIEN = "UPDATE_TYPE_BIEN";
export const RESET_TYPE_BIEN = "RESET_TYPE_BIEN";
export const UPDATE_MOTCLE = "UPDATE_MOT_CLE";
export const RESET_MOTCLE = "RESET_MOT_CLE";
export const UPDATE_CARACTERISTUQUES_BIEN = "UPDATE_CARACTERISTUQUES_BIEN";
export const RESET_CARACTERISTUQUES_BIEN = "RESET_CARACTERISTUQUES_BIEN";
export const UPDATE_TRAVAUX = "UPDATE_TRAVAUX";
export const RESET_TRAVAUX = "RESET_TRAVAUX";
export const UPDATE_COMMUNE_DISPLAY = "UPDATE_COMMUNE_DISPLAY";
export const RESET_CRITERE = "RESET_CRITERE";
export const RESET_CODE_POSTAL = "RESET_CODE_POSTAL";
export const RESET_CODE_INSEE = "RESET_CODE_INSEE";
export const UPDATE_CODE_POSTAL = "RESET_CODE_POSTAL";
export const UPDATE_CODE_INSEE = "RESET_CODE_INSEE";
export const UPDATE_TYPE = "UPDATE_TYPE";
export const RESET_TYPE = "UPDATE_TYPE";
