import axios from 'axios'
import {
    GET_DISTRICT, // OLD
    GET_DISTRICT_MAP_INFO, // OLD
    GET_ONE_DISTRICT,
    GET_DISTRICT_SUGGESTIONS
} from '../actions/types'
const { APIServerUrl } = require('../shared/values/environnement')

export const getOneDistrict = (code, name, type) => dispatch => {
    axios
        .get(APIServerUrl + '/api/' + type + '/' + code + '/' + name)
        .then(res => {
            dispatch({
                type: GET_ONE_DISTRICT,
                payload: res.data
            })
        })
        .catch(() => {
            console.error('Failed to fetch district')
            dispatch({
                type: GET_ONE_DISTRICT,
                payload: {}
            })
        })
}

export const getDistrictSuggestions = (limit, codeName, type) => dispatch => {
    axios
        .get(APIServerUrl + '/api/' + type + '/suggestions/' + limit + '/code-name/' + codeName)
        .then(res => {
            dispatch({
                type: GET_DISTRICT_SUGGESTIONS,
                payload: res.data
            })
        })
        .catch(() => {
            console.error('Failed to fetch districts');
            dispatch({
                type: GET_DISTRICT_SUGGESTIONS,
                payload: { error: 'KO' }
            })
        })
}


//
// /!\ OLD /!\
//
export const getDistrict = (limit, codeName, type) => dispatch => {
    return axios
        .get(APIServerUrl + '/api/' + type + '/suggestions/' + limit + '/code-name/' + codeName)
        .then(res => {
            dispatch({
                type: GET_DISTRICT,
                payload: res.data
            });
        })
        .catch(() => {
            console.error('Failed to fetch districts');
            dispatch({
                type: GET_DISTRICT,
                payload: { error: 'KO' }
            });
        });
}

export const getDistrictMapInfo = (codeName, type) => dispatch => {
    axios
        .get(APIServerUrl + '/api/district/map-info/' + codeName + '/type/' + type)
        .then(res => {
            dispatch({
                type: GET_DISTRICT_MAP_INFO,
                payload: res.data
            })
        })
        .catch(() => {
            console.error('Failed to fetch map info of ' + codeName + ' district')
            dispatch({
                type: GET_DISTRICT_MAP_INFO,
                payload: { error: 'KO' }
            })
        })
}

//
// /!\ OLD /!\
//