import {
    GET_DISTRICT_AROUND,
    GET_DISTRICT_FROM_COORD,
    GET_MARKERS_AROUND,
    GET_MARKERS_AROUND_CONCAT
} from '../actions/types'

const initialState = {
    polygons: [],
    markers: [],
    center: {}
}

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_DISTRICT_AROUND:
            return {
                polygons: action.payload,
                markers: state.markers,
                center: state.coordinates
            }
        case GET_DISTRICT_FROM_COORD:
            return {
                ...state,
                center: action.payload
            }
        /*
    case GET_TRANSPORTS_WITHIN:
        return {
            ...state,
            markers: action.payload,
            center: state.center 
        }
        */
        case GET_MARKERS_AROUND:
            return {
                ...state,
                markers: action.payload,
                center: state.center
            }
        case GET_MARKERS_AROUND_CONCAT:
            return {
                ...state,
                markers: state.markers.concat(action.payload),
                center: state.center
            }
        default:
            return state
    }
}