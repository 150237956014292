import React from 'react'

// Material UI components
import { makeStyles } from '@material-ui/core/styles'

export default function FixedButtonItem(props) {
  const useStyles = makeStyles(theme => ({
    buttonLi: {
      display: "inline-block",
      margin: theme.spacing(0),
      pointerEvents: 'all',
      width: '60px',
      color: theme.palette.primary.main
    }
  }))

  const classes = useStyles()

  return (
    <li className={classes.buttonLi}>
      {props.children}
      {props.title}
    </li>
  )
}
