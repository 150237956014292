import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import PropTypes from "prop-types";

// Material UI components
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

// Wealzy
import GridContainer from "../../common-component/GridContainer";
const { isEmpty } = require("../../../shared/functions/isEmpty");

function FicheAnnonceSynthese(props) {
  const matchesPrint = true;
  const dispatch = useDispatch();
  // Définition des styles
  const useStyles = makeStyles((theme) => ({
    container: {},
    card: {
      margin: theme.spacing(0.5),
      height: "150px",
      "@media print": {
        height: "150px",
      },
      borderColor: "#eee",
      borderStyle: "solid",
      borderRadius: "4px",
      boxShadow: "none",
      [theme.breakpoints.down("sm")]: {
        height: matchesPrint ? "150px" : "120px",
      },
    },
    cardValue: {
      marginBottom: theme.spacing(0),
      paddingBottom: theme.spacing(0.5),
      fontSize: "1.6rem",
      color: theme.palette.secondary.main,
    },
    cardTitle: {
      fontSize: "1.1rem",
      color: theme.palette.primary.main,
    },
    cardSubtitle: {
      fontSize: "0.8rem",
      color: theme.palette.primary.main,
    },
  }));
  const classes = useStyles();

  // Datas initiales du bien

  var [prixLocationRetenueAnnuel, setPrixLocationRetenueAnnuel] = useState();
  var [datasyncloca, setDataSynloca] = useState();
  var [Rendementnet, setRendementnet] = useState();

  useEffect(() => {}, [props.scolor, props.pcolor]);

  // let revenuFoncier = (props.dataBien.qualification_robot.location
  //   .prix_location_retenue_annuel - props.dataBien.qualification_robot.charge
  //   .total_charges - props.dataBien.qualification_robot.fiscalite.charges_interets_moyen_5_ans)
  //   let amortissementAnnuel = props.dataBien.qualification_robot.fiscalite.montant_amortissement_bien_annuel
  // useEffect(() => {
  //   let revenuFoncierArray = []
  //   let travauxRestant = props.dataBien.qualification_robot.prix.montant_travaux
  //   let delta = 0

  //   for (let i = 0; i < 10; i++) {
  //     if (travauxRestant>0) {
  //       delta = revenuFoncier - travauxRestant
  //     } else {
  //       delta = revenuFoncier
  //     }

  //     if (delta <= -10700) {
  //       revenuFoncierArray[i] = -10700
  //       travauxRestant = travauxRestant - revenuFoncier - 10700
  //     } else {
  //       if (delta >= 0) {
  //         delta = delta - amortissementAnnuel
  //         if (delta >= 0) {
  //           revenuFoncierArray[i] = delta
  //           travauxRestant = travauxRestant - revenuFoncier
  //         } else {
  //           revenuFoncierArray[i] = 0
  //           travauxRestant = travauxRestant - revenuFoncier
  //         }
  //       } else {
  //         revenuFoncierArray[i] = delta
  //         travauxRestant = travauxRestant - revenuFoncier
  //       }
  //     }
  //   }

  //   let revenuFoncierMoyen5ans = revenuFoncierArray.reduce((a, b) => a + b) / 10
  //  // setRevenuFoncierMoyen5ans(parseInt(revenuFoncierMoyen5ans, 10))
  //   console.log("revenuFoncierMoyen5ans",revenuFoncierMoyen5ans)

  //   console.log('revenuFoncierMoyen5ans')
  //   console.log('revenuFoncierMoyen5ans',(revenuFoncierMoyen5ans)-9154)
  // }, [])

  useEffect(() => {
    console.log("props.dataBienBieeeee", props.dataBien);
    setDataSynloca(props.dataBien);
  }, [props.dataBien]);

  useEffect(() => {
    console.log(
      "tbaaadel",
      props.dataBien.qualification_robot.location.prix_location_retenue_annuel +
        "///" +
        props.dataBien.qualification_robot.prix.prix_bien_complet
    );

    dispatch({
      type: "UPDATE_RENDEMENT_BRUT",
      payload:
        parseInt(
          (props.dataBien.qualification_robot.location
            .prix_location_retenue_annuel /
            props.dataBien.qualification_robot.prix.prix_bien_complet) *
            100 *
            10,
          10
        ) / 10,
    });
  }, [
    props.dataBien.qualification_robot.location.prix_location_retenue_annuel,
    props.dataBien.qualification_robot.prix.prix_bien_complet,
  ]);

  useEffect(() => {
    console.log(
      "tbaaadel",
      props.dataBien.qualification_robot.location.prix_location_retenue_annuel +
        "///" +
        props.dataBien.qualification_robot.prix.prix_bien_complet
    );
    setRendementnet(
      parseInt(
        ((props.dataBien.qualification_robot.location
          .prix_location_retenue_annuel -
          props.dataBien.qualification_robot.charge.total_charges) /
          props.dataBien.qualification_robot.prix.prix_bien_complet) *
          100 *
          10,
        10
      ) / 10
    );
    dispatch({
      type: "UPDATE_RENDEMENT_NET",
      payload:
        parseInt(
          ((props.dataBien.qualification_robot.location
            .prix_location_retenue_annuel -
            props.dataBien.qualification_robot.charge.total_charges) /
            props.dataBien.qualification_robot.prix.prix_bien_complet) *
            100 *
            10,
          10
        ) / 10,
    });
  }, [
    props.dataBien.qualification_robot.location.prix_location_retenue_annuel,
    props.dataBien.qualification_robot.prix.prix_bien_complet,
    props.dataBien.qualification_robot.charge.total_charges,
  ]);

  useEffect(() => {
    console.log(
      "UPDATE_RENDEMENT_NET",
      props.dataBien.qualification_robot.rendement.rendement_net_retenue
    );
  }, [props.dataBien.qualification_robot.rendement.rendement_net_retenue]);

  useEffect(() => {
    // Initialisation / Mise à jour des données du bien via REDUX - prix_location_retenue_annuel
    //console.log('props.dataBien.qualification_robot.location.prix_location_retenue_annuel',props.dataBien.qualification_robot.location.prix_location_retenue_annuel)
    setPrixLocationRetenueAnnuel(
      props.dataBien.qualification_robot.location.prix_location_retenue_annuel
    );
  }, [datasyncloca]);

  var [prixBienComplet, setPrixBienComplet] = useState();
  useEffect(() => {
    // Initialisation / Mise à jour des données du bien via REDUX - prix_bien_complet
    setPrixBienComplet(
      props.dataBien.qualification_robot.prix.prix_bien_complet
    );
  }, [datasyncloca]);

  var [apportPersonnel, setApportPersonnel] = useState(0);
  useEffect(() => {
    // Initialisation / Mise à jour des données du bien via REDUX - prix_bien_complet
    //console.log('props.dataBien.qualification_robot.financement.apport_personnel',props.dataBien.qualification_robot.financement.apport_personnel)
    setApportPersonnel(
      props.dataBien.qualification_robot.financement.apport_personnel
    );
  }, [datasyncloca]);

  var [montantAEmprunter, setMontantAEmprunter] = useState(0);
  useEffect(() => {
    // Initialisation / Mise à jour des données du bien via REDUX - prix_bien_complet
    setMontantAEmprunter(
      props.dataBien.qualification_robot.financement.montant_a_emprunter
    );
  }, [datasyncloca]);

  var [mensualiteRemboursement, setMensualiteRemboursement] = useState(0);
  useEffect(() => {
    // Initialisation / Mise à jour des données du bien via REDUX - prix_bien_complet
    //console.log('props.dataBien.qualification_robot.financement.montant_mensualite_remboursement',props.dataBien.qualification_robot.financement
    //)
    if (
      !isNaN(
        props.dataBien.qualification_robot.financement
          .montant_mensualite_remboursement
      )
    ) {
      setMensualiteRemboursement(
        props.dataBien.qualification_robot.financement
          .montant_mensualite_remboursement
      );
    } else if (
      parseInt(props.dataBien.qualification_robot.prix.prix_bien_complet, 10) -
        parseInt(
          props.dataBien.qualification_robot.financement.apport_personnel,
          10
        ) ==
      0
    ) {
      setMensualiteRemboursement(0);
    } else {
      //console.log('dkhallllll+++++++++')
      setMensualiteRemboursement(
        props.dataBien.qualification_robot.financement
          .montant_mensualite_hors_assurance +
          props.dataBien.qualification_robot.financement
            .montant_mensualite_assurance
      );
    }
    console.log(mensualiteRemboursement);
  }, [datasyncloca]);

  var [nbeMensualiteRemboursement, setNbeMensualiteRemboursement] =
    useState(240);
  useEffect(() => {
    // Initialisation / Mise à jour des données du bien via REDUX - prix_bien_complet
    setNbeMensualiteRemboursement(
      props.dataBien.qualification_robot.financement
        .nbe_mensualite_remboursement
    );
  }, [datasyncloca]);

  var [montantTravaux, setMontantTravaux] = useState(0);
  useEffect(() => {
    let toLaunch = false;
    toLaunch =
      !isNaN(props.dataBien.qualification_robot.prix.montant_travaux) &&
      !isNaN(props.dataBien.qualification_robot.prix.montant_meuble) &&
      !isNaN(props.dataBien.qualification_robot.prix.autres_frais);

    // Initialisation / Mise à jour des données du bien via REDUX - montant_travaux, montant_meuble, autres_frais
    toLaunch &&
      setMontantTravaux(
        props.dataBien.qualification_robot.prix.montant_travaux +
          props.dataBien.qualification_robot.prix.montant_meuble +
          props.dataBien.qualification_robot.prix.autres_frais
      );
  }, [datasyncloca]);

  var [totalCharges, setTotalCharges] = useState();
  useEffect(() => {
    // Initialisation / Mise à jour des données du bien via REDUX - total_charges
    //console.log('aaaa',props.dataBien.qualification_robot.charge)
    setTotalCharges(props.dataBien.qualification_robot.charge.total_charges);
  }, [datasyncloca]);

  var [prixBienEstime, setPrixBienEstime] = useState();
  useEffect(() => {
    // Initialisation / Mise à jour des données du bien via REDUX - prix_bien_estime
    setPrixBienEstime(
      props.dataBien.qualification_robot.vente.prix_bien_estime
    );
  }, [datasyncloca]);

  var [prixM2Reel, setPrixM2Reel] = useState(0);
  useEffect(() => {
    // Initialisation / Mise à jour des données du bien via REDUX - prix_m2_vente_reel

    setPrixM2Reel(
      parseInt(props.dataBien.qualification_robot.prix.prix_bien_complet, 10) /
        parseInt(props.dataBien.surface, 10)
    );
  }, [datasyncloca]);

  var [revenuFoncierAnnuel, setRevenuFoncierAnnuel] = useState(0);

  useEffect(() => {}, [revenuFoncierAnnuel]);

  useEffect(() => {
    // Initialisation / Mise à jour des données du bien via REDUX - revenu_foncier_annuel
    if (props.dataBien.qualification_robot.fiscalite !== undefined) {
      //console.log('dakhleuuu')
      //console.log('props.dataBien.qualification_robot.fiscalite',props.dataBien.fiscalite.revenu_foncier_annuel)
    }
    props.dataBien.qualification_robot.fiscalite !== undefined &&
      setRevenuFoncierAnnuel(
        props.dataBien.qualification_robot.fiscalite.revenu_foncier_annuel
          ? props.dataBien.qualification_robot.fiscalite.revenu_foncier_annuel
          : 0
      );
  }, [datasyncloca]); // eslint-disable-line react-hooks/exhaustive-deps

  // Variables calculées
  var [rendementBrut, setRendementBrut] = useState();
  var [rendementNet, setRendementNet] = useState();
  var [plusValueBrute, setPlusValueBrute] = useState();

  useEffect(() => {
    let toLaunch = false;
    toLaunch =
      !isNaN(prixLocationRetenueAnnuel) &&
      !isNaN(prixBienComplet) &&
      !isNaN(totalCharges) &&
      !isNaN(prixBienEstime);

    if (toLaunch) {
      // Calcul du Rendement brut et net
      setRendementBrut(
        parseInt((prixLocationRetenueAnnuel / prixBienComplet) * 100 * 10, 10) /
          10
      );
      if (prixBienComplet != null) {
        setRendementNet(
          parseInt(
            ((prixLocationRetenueAnnuel - totalCharges) / prixBienComplet) *
              100 *
              10,
            10
          ) / 10
        );
      } else {
        setRendementNet(
          parseInt(
            ((prixLocationRetenueAnnuel - totalCharges) /
              props.dataBien.prix_bien) *
              100 *
              10,
            10
          ) / 10
        );
      }

      // Calcul de la plus value brute et nette
      setPlusValueBrute(parseInt(prixBienEstime - prixBienComplet, 10));
    }
  }, [
    prixLocationRetenueAnnuel,
    prixBienComplet,
    totalCharges,
    prixBienEstime,
  ]);

  var [surtaxePlusValue, setSurtaxePlusValue] = useState();
  useEffect(() => {
    let toLaunch;
    toLaunch = !isNaN(plusValueBrute);

    if (toLaunch) {
      // Calcul de la surtaxe sur la plus value
      if (plusValueBrute > 50000 && plusValueBrute <= 60000) {
        setSurtaxePlusValue(
          0.02 * plusValueBrute - ((60000 - plusValueBrute) * 1) / 20
        );
      } else if (plusValueBrute > 60000 && plusValueBrute <= 100000) {
        setSurtaxePlusValue(0.02 * plusValueBrute);
      } else if (plusValueBrute > 100000 && plusValueBrute <= 110000) {
        setSurtaxePlusValue(
          0.03 * plusValueBrute - ((110000 - plusValueBrute) * 1) / 10
        );
      } else if (plusValueBrute > 110000 && plusValueBrute <= 150000) {
        setSurtaxePlusValue(0.03 * plusValueBrute);
      } else if (plusValueBrute > 150000 && plusValueBrute <= 160000) {
        setSurtaxePlusValue(
          0.04 * plusValueBrute - ((150000 - plusValueBrute) * 15) / 100
        );
      } else if (plusValueBrute > 160000 && plusValueBrute <= 200000) {
        setSurtaxePlusValue(0.04 * plusValueBrute);
      } else if (plusValueBrute > 200000 && plusValueBrute <= 210000) {
        setSurtaxePlusValue(
          0.05 * plusValueBrute - ((210000 - plusValueBrute) * 20) / 100
        );
      } else if (plusValueBrute > 210000 && plusValueBrute <= 250000) {
        setSurtaxePlusValue(0.05 * plusValueBrute);
      } else if (plusValueBrute > 250000 && plusValueBrute <= 260000) {
        setSurtaxePlusValue(
          0.06 * plusValueBrute - ((260000 - plusValueBrute) * 25) / 100
        );
      } else if (plusValueBrute > 260000) {
        setSurtaxePlusValue(0.06 * plusValueBrute);
      } else {
        setSurtaxePlusValue(0);
      }
    }
  }, [plusValueBrute]);

  var [plusValueNette, setPlusValueNette] = useState();
  useEffect(() => {
    let toLaunch = false;
    toLaunch = !isNaN(plusValueBrute) && !isNaN(surtaxePlusValue);

    // Calcul de la plus value nette
    toLaunch &&
      setPlusValueNette(
        parseInt(
          (plusValueBrute * (100 - 19 - 15.5)) / 100 - surtaxePlusValue,
          10
        )
      );
  }, [plusValueBrute, surtaxePlusValue]);

  // Calcul du cash flow moyen
  const [cashFlowMoyen, setCashFlowMoyen] = useState();

  useEffect(() => {
    dispatch({
      type: "UPDATE_CASH_FLOW_MOYEN",
      payload: cashFlowMoyen,
    });
    console.log(props.biens.capital_rembourse);

    if (props.biens.capital_rembourse?.length > 0) {
      let revenu_tra = [];

      for (let j = 0; j < props.biens.capital_rembourse.length; j++) {
        revenu_tra.push(props.biens.capital_rembourse[j] + cashFlowMoyen);
      }

      dispatch({
        type: "UPDATE_FINANCEMENT_BIEN_TABLEAU_REVENU_AVANT_TRAVAUX",
        payload: revenu_tra,
      });
    }
    //console.log('cashFlowMoyen2',cashFlowMoyen,"+++", prixLocationRetenueAnnuel , mensualiteRemboursement * 12 , totalCharges)
    //console.log('cashFlowMoyen2',cashFlowMoyen,"+++", prixLocationRetenueAnnuel - mensualiteRemboursement * 12 - totalCharges)
  }, [cashFlowMoyen, props.biens.captial_rembourse]);

  useEffect(() => {
    let mensualiteRembour = props.dataBien.qualification_robot.financement
      .montant_mensualite_remboursement
      ? props.dataBien.qualification_robot.financement
          .montant_mensualite_remboursement * 12
      : (props.dataBien.qualification_robot.financement
          .montant_mensualite_assurance +
          props.dataBien.qualification_robot.financement
            .montant_mensualite_hors_assurance) *
        12;

    if (
      parseInt(props.dataBien.qualification_robot.prix.prix_bien_complet, 10) -
        parseInt(
          props.dataBien.qualification_robot.financement.apport_personnel,
          10
        ) ==
      0
    ) {
      mensualiteRembour = 0;
    }
    //  parseInt(
    //     props.dataBien.qualification_robot.financement
    //       .montant_mensualite_assurance +
    //       props.dataBien.qualification_robot.financement
    //         .montant_mensualite_hors_assurance,
    //     10
    //   ) * 12
    // : 0;

    console.log(
      "cashFlowMoyencashFlowMoyen",
      prixLocationRetenueAnnuel,
      mensualiteRembour,
      totalCharges
    );
    setCashFlowMoyen(
      prixLocationRetenueAnnuel - mensualiteRembour - totalCharges
    );

    console.log(
      `cashflow (prixLocationRetenueAnnuel - mensualiteRemboursement * 12 - totalCharges)${
        prixLocationRetenueAnnuel - mensualiteRemboursement * 12 - totalCharges
      }= prixLocationRetenueAnnuel${prixLocationRetenueAnnuel}-mensualiteRemboursement*12    
      ${mensualiteRemboursement * 12}-totalcharges${totalCharges}`
    );
  }, [prixLocationRetenueAnnuel, mensualiteRemboursement, totalCharges]);

  useEffect(() => {
    let toLaunch = false;

    toLaunch =
      !isNaN(rendementNet) &&
      !isNaN(rendementBrut) &&
      !isNaN(plusValueBrute) &&
      !isNaN(plusValueNette) &&
      !isNaN(cashFlowMoyen);

    toLaunch &&
      props.callback({
        source: "synthese",
        data: [
          { name: "rendement_net_retenue", value: rendementNet },
          { name: "rendement_brut_retenue", value: rendementBrut },
          { name: "plus_value_brut", value: plusValueBrute },
          { name: "plus_value_net", value: plusValueNette },
          { name: "cash_flow_moyen", value: cashFlowMoyen },
        ],
      });
  }, [
    rendementNet,
    rendementBrut,
    plusValueBrute,
    plusValueNette,
    cashFlowMoyen,
  ]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <GridContainer>
      <Grid item xs={6} sm={6} md={3}>
        <Card className={classes.card}>
          <CardContent>
            <Typography
              align={"center"}
              gutterBottom
              variant="h5"
              component="h2"
              className={classes.cardTitle}
              style={{}}
            >
              Investissement
            </Typography>
            <Typography
              align={"center"}
              variant="h4"
              component="h3"
              className={classes.cardValue}
              style={{ color: props.scolor }}
            >
              {prixBienComplet != null ? (
                <Typography
                  align={"center"}
                  variant="h4"
                  component="h3"
                  className={classes.cardValue}
                  style={{ color: props.scolor }}
                >
                  {(prixBienComplet / 1000).toFixed(0) + " k€"}
                </Typography>
              ) : (
                <Typography
                  align={"center"}
                  variant="h4"
                  component="h3"
                  className={classes.cardValue}
                  style={{ color: props.scolor }}
                >
                  {(props.dataBien.prix_bien / 1000).toFixed(0) + " k€"}
                </Typography>
              )}
            </Typography>
            <Typography
              paragraph
              align={"center"}
              variant="subtitle1"
              color="textSecondary"
              className={classes.cardSubtitle}
              style={{ color: props.pcolor }}
            >
              Acquisition, notaire, commissions, travaux, etc.
            </Typography>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={6} sm={6} md={3}>
        <Card className={classes.card}>
          <CardContent>
            <Typography
              align={"center"}
              gutterBottom
              variant="h5"
              component="h2"
              className={classes.cardTitle}
              style={{ color: props.pcolor }}
            >
              Transformation
            </Typography>
            <Typography
              align={"center"}
              variant="h4"
              component="h3"
              className={classes.cardValue}
              style={{ color: props.scolor }}
            >
              {(montantTravaux / 1000).toFixed(0) + " k€"}
            </Typography>
            <Typography
              paragraph
              align={"center"}
              variant="subtitle1"
              color="textSecondary"
              className={classes.cardSubtitle}
              style={{ color: props.pcolor }}
            >
              Travaux, meubles, architecte, études, etc.
            </Typography>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={6} sm={6} md={3}>
        <Card className={classes.card}>
          <CardContent>
            <Typography
              align={"center"}
              gutterBottom
              variant="h5"
              component="h2"
              className={classes.cardTitle}
              style={{ color: props.pcolor }}
            >
              Prix M2
            </Typography>
            <Typography
              align={"center"}
              variant="h4"
              component="h3"
              className={classes.cardValue}
              style={{ color: props.scolor }}
            >
              {parseInt(prixM2Reel.toString().replace(/\,/g, ""), 10) + " €/m2"}
            </Typography>
            <Typography
              paragraph
              align={"center"}
              variant="subtitle1"
              color="textSecondary"
              className={classes.cardSubtitle}
              style={{ color: props.pcolor }}
            >
              Incluant Acquisition, notaire, commissions, travaux, etc.
            </Typography>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={6} sm={6} md={3}>
        <Card className={classes.card}>
          <CardContent>
            <Typography
              align={"center"}
              gutterBottom
              variant="h5"
              component="h2"
              className={classes.cardTitle}
              style={{ color: props.pcolor }}
            >
              Apport personnel
            </Typography>
            <Typography
              align={"center"}
              variant="h4"
              component="h3"
              className={classes.cardValue}
              style={{ color: props.scolor }}
            >
              {apportPersonnel
                ? (apportPersonnel / 1000).toFixed(0) + " k€"
                : "0 k€"}
            </Typography>
            <Typography
              paragraph
              align={"center"}
              variant="subtitle1"
              color="textSecondary"
              className={classes.cardSubtitle}
              style={{ color: props.pcolor }}
            >
              Apport financier de l'investisseur
            </Typography>
          </CardContent>
        </Card>
      </Grid>
      {props.isannonces ? (
        ""
      ) : (
        <>
          <Grid item xs={6} sm={6} md={3}>
            <Card className={classes.card}>
              <CardContent>
                <Typography
                  align={"center"}
                  gutterBottom
                  variant="h5"
                  component="h2"
                  className={classes.cardTitle}
                  style={{ color: props.pcolor }}
                >
                  Emprunt
                </Typography>
                <Typography
                  align={"center"}
                  variant="h4"
                  component="h3"
                  className={classes.cardValue}
                  style={{ color: props.scolor }}
                >
                  {!isEmpty(montantAEmprunter)
                    ? (montantAEmprunter / 1000).toFixed(0) + " k€"
                    : "0 k€"}
                </Typography>
                <Typography
                  paragraph
                  align={"center"}
                  variant="subtitle1"
                  color="textSecondary"
                  className={classes.cardSubtitle}
                  style={{ color: props.pcolor }}
                >
                  {!isEmpty(montantAEmprunter)
                    ? "Soit " +
                      new Intl.NumberFormat().format(
                        parseInt(mensualiteRemboursement)
                      ) +
                      " €/mois sur " +
                      (isNaN(nbeMensualiteRemboursement)
                        ? 20
                        : parseInt(nbeMensualiteRemboursement / 12, 10)) +
                      " an(s)"
                    : ""}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={6} sm={6} md={3}>
            <Card className={classes.card}>
              <CardContent>
                <Typography
                  align={"center"}
                  gutterBottom
                  variant="h5"
                  component="h2"
                  className={classes.cardTitle}
                  style={{ color: props.pcolor }}
                >
                  Revenus
                </Typography>
                <Typography
                  align={"center"}
                  variant="h4"
                  component="h3"
                  className={classes.cardValue}
                  style={{ color: props.scolor }}
                >
                  {parseInt(prixLocationRetenueAnnuel, 10) + " €/an"}
                </Typography>
                <Typography
                  paragraph
                  align={"center"}
                  variant="subtitle1"
                  color="textSecondary"
                  className={classes.cardSubtitle}
                  style={{ color: props.pcolor }}
                >
                  {"Soit " +
                    (prixLocationRetenueAnnuel / 12).toFixed(0) +
                    " €/mois"}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={6} sm={6} md={3}>
            <Card className={classes.card}>
              <CardContent>
                <Typography
                  align={"center"}
                  gutterBottom
                  variant="h5"
                  component="h2"
                  className={classes.cardTitle}
                  style={{ color: props.pcolor }}
                >
                  Charges
                </Typography>
                <Typography
                  align={"center"}
                  variant="h4"
                  component="h3"
                  className={classes.cardValue}
                  style={{ color: props.scolor }}
                >
                  {parseInt(totalCharges, 10) + " €/an"}
                </Typography>
                <Typography
                  paragraph
                  align={"center"}
                  variant="subtitle1"
                  color="textSecondary"
                  className={classes.cardSubtitle}
                  style={{ color: props.pcolor }}
                >
                  {"Soit " + (totalCharges / 12).toFixed(0) + " €/mois"}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={6} sm={6} md={3}>
            <Card className={classes.card}>
              <CardContent>
                <Typography
                  align={"center"}
                  gutterBottom
                  variant="h5"
                  component="h2"
                  className={classes.cardTitle}
                  style={{ color: props.pcolor }}
                >
                  Rendement brut
                </Typography>
                <Typography
                  align={"center"}
                  variant="h4"
                  component="h3"
                  className={classes.cardValue}
                  style={{ color: props.scolor }}
                >
                  {rendementBrut + " %"}
                </Typography>
                <Typography
                  paragraph
                  align={"center"}
                  variant="subtitle1"
                  color="textSecondary"
                  className={classes.cardSubtitle}
                  style={{ color: props.pcolor }}
                >
                  {"Soit Revenus annuels / Investissement"}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={6} sm={6} md={3}>
            <Card className={classes.card}>
              <CardContent>
                <Typography
                  align={"center"}
                  gutterBottom
                  variant="h5"
                  component="h2"
                  className={classes.cardTitle}
                  style={{ color: props.pcolor }}
                >
                  Rendement net
                </Typography>
                <Typography
                  align={"center"}
                  variant="h4"
                  component="h3"
                  className={classes.cardValue}
                  style={{ color: props.scolor }}
                >
                  {Rendementnet
                    ? Rendementnet + " %"
                    : props.dataBien.qualification_robot.rendement
                        .rendement_net_retenue + " %"}{" "}
                </Typography>
                <Typography
                  paragraph
                  align={"center"}
                  variant="subtitle1"
                  color="textSecondary"
                  className={classes.cardSubtitle}
                  style={{ color: props.pcolor }}
                >
                  {"Soit (Revenus annuels - Charges) / Investissement"}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={6} sm={6} md={3}>
            <Card className={classes.card}>
              <CardContent style={{ padding: "10px" }}>
                <Typography
                  align={"center"}
                  gutterBottom
                  variant="h5"
                  component="h2"
                  className={classes.cardTitle}
                  style={{ color: props.pcolor }}
                >
                  Revenu foncier
                </Typography>
                <Typography
                  align={"center"}
                  variant="h4"
                  component="h3"
                  className={classes.cardValue}
                  style={{ color: props.scolor }}
                >
                  {revenuFoncierAnnuel != 0
                    ? revenuFoncierAnnuel + " €/an"
                    : "A calculer"}
                </Typography>
                <Typography
                  paragraph
                  align={"center"}
                  variant="subtitle1"
                  color="textSecondary"
                  className={classes.cardSubtitle}
                  style={{ color: props.pcolor }}
                >
                  {"Moyenne sur 5 ans. Soit entre 0 et " +
                    (revenuFoncierAnnuel * 0.45).toFixed(0) +
                    " €/an d'impôt sur le revenu."}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={6} sm={6} md={3}>
            <Card className={classes.card}>
              <CardContent>
                <Typography
                  align={"center"}
                  gutterBottom
                  variant="h5"
                  component="h2"
                  className={classes.cardTitle}
                  style={{ color: props.pcolor }}
                >
                  Autofinancement
                </Typography>
                <Typography
                  align={"center"}
                  variant="h4"
                  component="h3"
                  className={classes.cardValue}
                  style={{ color: props.scolor }}
                >
                  {mensualiteRemboursement
                    ? (
                        (((prixLocationRetenueAnnuel / 12) * 0.7) /
                          mensualiteRemboursement) *
                        12
                      )
                        //    *
                        // 100
                        .toFixed(1) + " %"
                    : "Non concerné"}
                </Typography>
                <Typography
                  paragraph
                  align={"center"}
                  variant="subtitle1"
                  color="textSecondary"
                  className={classes.cardSubtitle}
                  style={{ color: props.pcolor }}
                >
                  {"Prise en compte de 70% des loyers"}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={6} sm={6} md={3}>
            <Card className={classes.card}>
              <CardContent>
                <Typography
                  align={"center"}
                  gutterBottom
                  variant="h5"
                  component="h2"
                  className={classes.cardTitle}
                  style={{ color: props.pcolor }}
                >
                  Cash Flow
                </Typography>
                <Typography
                  align={"center"}
                  variant="h4"
                  component="h3"
                  className={classes.cardValue}
                  style={{ color: props.scolor }}
                >
                  {cashFlowMoyen
                    ? parseInt(cashFlowMoyen, 10) + " €/an"
                    : "A calculer"}
                </Typography>
                <Typography
                  paragraph
                  align={"center"}
                  variant="subtitle1"
                  color="textSecondary"
                  className={classes.cardSubtitle}
                  style={{ color: props.pcolor }}
                >
                  {"Avant impôts."}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </>
      )}
    </GridContainer>
  );
}

FicheAnnonceSynthese.defaultProps = {
  toPrint: true,
};

FicheAnnonceSynthese.propTypes = {
  toPrint: PropTypes.bool,
  callback: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  biens: state.bien,
  dataIris: state.iris.oneIris,
});

export default React.memo(connect(mapStateToProps, {})(FicheAnnonceSynthese));
