import {
  UPDATE_NBEPIECE,
  RESET_NBEPIECE,
  UPDATE_NBE_ETAGE,
  RESET_NBE_ETAGE,
  UPDATE_CLIENT,
  RESET_CLIENT,
  UPDATE_TYPE_ANNONCE,
  RESET_TYPE_ANNONCE,
  UPDATE_TYPE_BIEN,
  RESET_TYPE_BIEN,
  UPDATE_MOTCLE,
  RESET_MOTCLE,
  UPDATE_CARACTERISTUQUES_BIEN,
  RESET_CARACTERISTUQUES_BIEN,
  UPDATE_TRAVAUX,
  RESET_TRAVAUX,
  RESET_DEPARTEMENT,
  UPDATE_DEPARTEMENT,
  UPDATE_COMMUNES,
  RESET_COMMUNES,
  RESET_PRIX_M,
  UPDATE_PRIX_M_MIN,
  UPDATE_PRIX_M_MAX,
  RESET_RENDEMENT,
  UPDATE_RENDEMENT_MAX,
  UPDATE_RENDEMENT_MIN,
  RESET_SURFACE,
  UPDATE_SURFACE_MIN,
  UPDATE_SURFACE_MAX,
  RESET_BUDGET,
  UPDATE_BUDGET_MIN,
  UPDATE_BUDGET_MAX,
  UPDATE_ISFILTRED,
  RESET_REQUEST,
  UPDATE_REQUEST,
  CREATE_REQUEST,
  UPDATE_COMMUNE_DISPLAY,
  RESET_CRITERE,
  RESET_CODE_POSTAL,
  RESET_CODE_INSEE,
  UPDATE_CODE_POSTAL,
  UPDATE_CODE_INSEE,
  RESET_TYPE,
  UPDATE_TYPE,
} from "../actions/types";

import { isEmpty } from "../shared/functions/isEmpty";

const onChangeCommune = (value) => {
  const listeCommuneDisplay = [];

  if (!isEmpty(value)) {
    value.forEach((val) => {
      listeCommuneDisplay.push(val.label);
    });

    return listeCommuneDisplay;
  } else if (value === null) {
    return [];
  }
};

let ListCodePostal = [];
let ListCodeInsee = [];

const initialState = {
  type: false,
  display: {
    commune_display: [],
  },
  request: {},
  budget_min: 0,
  budget_max: 2000000,
  surface_max: 2000,
  surface_min: 0,
  prix_m2_min: 0,
  prix_m2_max: 2000,
  rendement_min: 0,
  rendement_max: 30,
  commune: {},
  departement: [],
  nbe_piece: [],
  nbe_etage: 0,
  type_bien: [],
  caract_bien: [],
  mot_cle: "",
  travaux: false,
  client: {},
  type_annonce: [],
  code_insee: [],
  code_postal: [],
};
let savedQuery = JSON.parse(localStorage.getItem("myquery"));
export default function (
  state = savedQuery ? savedQuery : initialState,
  action
) {
  switch (action.type) {
    case UPDATE_CLIENT:
      return {
        ...state,
        client: action.payload,
        budget_min: action.payload?.budget_min ? action.payload?.budget_min : 0,
        budget_max: action.payload?.budget_max ? action.payload?.budget_max : 0,
        surface_max: action.payload?.surface_max
          ? action.payload?.surface_max
          : 2000,
        surface_min: action.payload?.surface_min
          ? action.payload?.surface_min
          : 0,
        prix_m2_min: action.payload?.prix_m2_min
          ? action.payload?.prix_m2_min
          : 0,
        prix_m2_max: action.payload?.prix_m2_max
          ? action.payload?.prix_m2_max
          : 0,
        rendement_min: action.payload?.rendement_min
          ? action.payload?.rendement_min
          : 0,
        rendement_max: action.payload?.rendement_max
          ? action.payload?.rendement_max
          : 0,
        commune: action.payload?.commune_cible
          ? action.payload?.commune_cible
          : "",
        departement: action.payload?.departement_cible
          ? action.payload?.departement_cible
          : "",
        nbe_piece:
          action.payload?.nbe_piece_min && action.payload?.nbe_piece_max
            ? [action.payload.nbe_piece_min, action.payload.nbe_piece_max]
            : [],
        nbe_etage: action.payload?.nb_etage_max
          ? action.payload?.nb_etage_max
          : 0,
        type_bien: action.payload?.type_bien ? action.payload?.type_bien : "",
        caract_bien: action.payload?.caract_bien
          ? action.payload?.caract_bien
          : [],
        mot_cle: action.payload?.keyword ? action.payload?.keyword : "",
        travaux: action.payload?.travaux ? action.payload?.travaux : false,
        type_annonce: action.payload?.type_annonce
          ? action.payload?.type_annonce
          : "",
        display: {
          ...state.display,
          commune_display: action.payload?.commune_cible
            ? onChangeCommune(action.payload?.commune_cible)
            : [],
        },
      };

    case UPDATE_NBEPIECE:
      return {
        ...state,
        nbe_piece: action.payload,
      };

    case RESET_NBEPIECE:
      return {
        ...state,
        nbe_piece: [],
      };

    case UPDATE_COMMUNES:
      return {
        ...state,
        commune: action.payload,
      };
    case RESET_COMMUNES:
      return {
        ...state,
        commune: {},
      };
    case UPDATE_COMMUNE_DISPLAY:
      return {
        ...state,
        display: { ...state.display, commune_display: action.payload },
      };

    case UPDATE_CARACTERISTUQUES_BIEN:
      return {
        ...state,
        caract_bien: action.payload,
      };

    case UPDATE_TYPE_BIEN:
      console.log("action.payload", action.payload);
      return {
        ...state,
        type_bien:
          action.payload === [] ? initialState.type_bien : action.payload,
      };

    case UPDATE_BUDGET_MAX:
      return {
        ...state,
        budget_max: action.payload === "" ? 0 : action.payload,
      };

    case UPDATE_BUDGET_MIN:
      return {
        ...state,
        budget_min: action.payload === "" ? 0 : action.payload,
      };

    case UPDATE_SURFACE_MAX:
      return {
        ...state,
        surface_max: action.payload === "" ? 0 : action.payload,
      };

    case UPDATE_SURFACE_MIN:
      return {
        ...state,
        surface_min: action.payload === "" ? 0 : action.payload,
      };

    case UPDATE_PRIX_M_MAX:
      return {
        ...state,
        prix_m2_max: action.payload === "" ? 0 : action.payload,
      };

    case UPDATE_PRIX_M_MIN:
      return {
        ...state,
        prix_m2_min: action.payload === "" ? 0 : action.payload,
      };

    case UPDATE_RENDEMENT_MIN:
      return {
        ...state,
        rendement_min: action.payload === "" ? 0 : action.payload,
      };
    case UPDATE_RENDEMENT_MAX:
      return {
        ...state,
        rendement_max: action.payload === "" ? 0 : action.payload,
      };

    case UPDATE_NBE_ETAGE:
      return {
        ...state,
        nbe_etage: action.payload === "" ? 0 : action.payload,
      };

    case RESET_NBE_ETAGE:
      return {
        ...state,
        nbe_etage: 0,
      };

    case UPDATE_TYPE_ANNONCE:
      return {
        ...state,
        type_annonce: action.payload,
      };

    case RESET_TYPE_ANNONCE: {
      return {
        ...state,
        type_annonce: [],
      };
    }

    case UPDATE_TRAVAUX: {
      return {
        ...state,
        travaux: action.payload,
      };
    }

    case RESET_CODE_POSTAL:
      return {
        ...state,
        code_postal: [],
      };
    case RESET_CODE_INSEE:
      return {
        ...state,
        code_insee: [],
      };
    case UPDATE_CODE_POSTAL:
      return {
        ...state,
        code_postal: action.payload,
      };
    case UPDATE_CODE_INSEE:
      return {
        ...state,
        code_insee: action.payload,
      };
    case RESET_CRITERE:
      console.log("action.payload2", initialState);
      return {
        type: false,
        display: {
          commune_display: [],
        },
        request: {},
        budget_min: 0,
        budget_max: 2000000,
        surface_max: 2000,
        surface_min: 0,
        prix_m2_min: 0,
        prix_m2_max: 2000,
        rendement_min: 0,
        rendement_max: 30,
        commune: {},
        departement: [],
        nbe_piece: [],
        nbe_etage: 0,
        type_bien: [],
        caract_bien: [],
        mot_cle: "",
        travaux: false,
        client: {},
        type_annonce: [],
        code_insee: [],
        code_postal: [],
      };
    case UPDATE_DEPARTEMENT:
      return {
        ...state,
        departement: action.payload,
      };

    case RESET_DEPARTEMENT:
      return {
        ...state,
        departement: [],
      };

    case RESET_MOTCLE:
      return {
        ...state,
        mot_cle: "",
      };
    case UPDATE_MOTCLE:
      return {
        ...state,
        mot_cle: action.payload,
      };

    case UPDATE_REQUEST:
      localStorage.setItem("myquery", JSON.stringify(state));

    case RESET_TYPE: {
      return {
        ...state,
        type: false,
      };
    }
    case UPDATE_TYPE: {
      return {
        ...state,
        type: action.payload,
      };
    }

    default:
      return state;
  }
}
