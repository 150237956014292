import {
  GET_ADRESS_FROM_COORD
} from '../actions/types';

const initialState = {
  adresse: {}
}

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_ADRESS_FROM_COORD:
      return {
        ...state,
        adresse: action.payload,
      }
    default:
      return state;
  }
}