import { CREATE_USER, REINIT_REGISTER } from '../actions/types';

const initialState = {
  statusRegisterUser: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case CREATE_USER:
      return {
        ...state,
        statusRegisterUser: true
      };
    case REINIT_REGISTER:
      return {
        ...state,
        statusRegisterUser: false
      };
    default:
      return state;
  }
}
