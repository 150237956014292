import React, { useState } from "react";
import PropTypes from "prop-types";

// Material UI
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import "../annonce/DossierDetaille/Footer/Footer.css";
// Wealzy
import GridContainer from "../common-component/GridContainer";
import GridItem from "../common-component/GridItem";

// Wealzy images
import logo_vertical_haut_bleu_texte_bleu from "../../img/wealzy/logo_vertical_haut_bleu_texte_bleu.png";
const { isEmpty } = require("../../shared/functions/isEmpty");
const { fileServerUrl } = require("../../shared/values/environnement");

// Styles
const useStyles = makeStyles((theme) => ({
  divContainer: {
    textAlign: "center",
    // paddingTop: "50px",
  },
  img_logo: {
    maxWidth: "500px",
    maxHeight: "300px",
    // marginLeft: "-75px",
    // marginBottom: "-40px",
    // position: "relative",
    // left: "50px",
    "@media print": {
      maxWidth: "500px",
      maxHeight: "300px",
      marginLeft: "0px",
      marginBottom: "-40px",
    },
    ["@media (max-width:800px)"]: {
      // eslint-disable-line no-useless-computed-key
      maxWidth: "300px",
      maxHeight: "300px",
    },
  },
  gridTitreBien: {
    // marginTop: theme.spacing(10),
    color: "white",
  },
  titreFicheSynthese: {
    // paddingTop: theme.spacing(5),
  },
  titreBienTypography: {
    paddingBottom: theme.spacing(0),
    color: theme.palette.primary.main,
    fontSize: "4rem",
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    ["@media (max-width:800px)"]: {
      // eslint-disable-line no-useless-computed-key
      fontSize: "3rem",
    },
  },
  titreBienTypographySubtitle1: {
    // position: "absolute",
    // bottom: "-80px",
    textAlign: "center",
    fontSize: "2rem",
    width: "100%",
    left: "14px",
  },
  titreBienTypographySubtitle2: {
    // position: "absolute",
    // bottom: "-120px",
    textAlign: "center",
    fontSize: "2rem",
    width: "100%",
    left: "14px",
  },
  titreBienTypographySubtitle3: {
    // position: "absolute",
    // bottom: "-170px",
    textAlign: "center",
    width: "100%",
  },
}));

export default function PDFPageFin(props) {
  const classes = useStyles();
  const today = new Date();
  const annee = today.getFullYear();
  const [error, setError] = useState(false);
  const mainRender = () => (
    // <GridContainer>
    //   <GridItem
    //     xs={12}
    //     sm={12}
    //     md={12}
    //     lg={12}
    //     style={{
    //       display: "flex",
    //       alignItem: "center",
    //       justifyContent: "center",
    //     }}
    //   >
    <div className={classes.divContainer}>
      {!error ? (
        <img
          className={classes.img_logo}
          src={
            isEmpty(props.logo)
              ? logo_vertical_haut_bleu_texte_bleu
              : fileServerUrl + "/" + props.logo
          }
          alt="Logo WEALZY"
          onError={({ currentTarget }) => {
            setError(true);
          }}
        />
      ) : (
        <img
          className={classes.img_logo}
          src={logo_vertical_haut_bleu_texte_bleu}
          alt="Logo WEALZY"
          onError={({ currentTarget }) => {
            setError(true);
          }}
        />
      )}
      <Typography
        variant="h3"
        color="primary"
        className={classes.titreFicheSynthese}
        elementtype="span"
      >
        {props?.slogan}
      </Typography>

      <Grid item xs={12} sm={12} md={12} className={classes.gridTitreBien}>
        <Typography
          variant="h2"
          align="center"
          color="primary"
          elementtype="span"
          className={classes.titreBienTypography}
        >
          {props?.siteweb === "www.wealzy.com"
            ? props?.siteweb
            : isEmpty(props?.logo)
            ? "www.wealzy.com"
            : props?.siteweb}
        </Typography>
        <div>
          <Typography
            variant="subtitle1"
            className={classes.titreBienTypographySubtitle1}
            elementtype="p"
          >
            {props?.email && <strong>Email | </strong>}
            {props?.email}
          </Typography>
        </div>
        <Typography
          variant="subtitle1"
          className={classes.titreBienTypographySubtitle2}
          elementtype="p"
        >
          {props?.telephone && <strong>Téléphone | </strong>}
          {props?.telephone}
        </Typography>
        <Typography
          variant="subtitle1"
          className={classes.titreBienTypographySubtitle3}
          elementtype="p"
        >
          {"Copyright Wealzy " + annee}
        </Typography>
      </Grid>
    </div> /*}

    /* {/* {!isEmpty(props.email) && (
          <GridItem xs={12} sm={12} md={12} lg={12}></GridItem>
        )}
        {!isEmpty(props.telephone) && (
          <GridItem xs={12} sm={12} md={12} lg={12}></GridItem>
        )}
        <GridItem
          xs={12}
          sm={12}
          md={12}
          lg={12}
          style={{
            display: "flex",
            alignItem: "center",
            justifyContent: "center",
          }}
        ></GridItem> */
    //   </GridItem>
    // </GridContainer>
  );

  return mainRender();
}

PDFPageFin.defaultProps = {
  slogan: "  ",
  siteweb: "www.wealzy.com",
  raisonSociale: "WEALZY",
  email: "",
  telephone: "",
};

PDFPageFin.propTypes = {
  slogan: PropTypes.string,
  logo: PropTypes.string,
  siteweb: PropTypes.string,
  raisonSociale: PropTypes.string,
};
