import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

// Material UI
import { Grid, Card } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import cyan from "@material-ui/core/colors/cyan";
import DetailsRoundedIcon from "@material-ui/icons/DetailsRounded";

export default function JaugePrix(props) {
  // Styles
  const useStyles = makeStyles((theme) => ({
    card: {
      padding: props.padding,
      margin: theme.spacing(1),
      borderColor: "#eee",
      borderStyle: "solid",
      borderRadius: "4px",
      boxShadow: "none",
      height: "213px",
      padding: "8px",

      "@media print": {
        padding: props.padding,
        margin: theme.spacing(0.5),
        height: props.heightPrint,
      },
      width: props.noData ? "100%" : "inherit",
      height: props.height,
      position: "relative",
      [theme.breakpoints.down("sm")]: {
        padding: theme.spacing(0),
      },
      [theme.breakpoints.down("xs")]: {
        padding: theme.spacing(2),
        height: "220px",
      },
    },
    divReel: {
      width: props.width,
      marginBottom: "-8px",
      fontSize: "1.3rem",
      zIndex: 200,
      [theme.breakpoints.down("sm")]: {
        width: props.widthSM,
      },
      [theme.breakpoints.down("xs")]: {
        width: props.widthXS,
      },
      "@media print": {
        width: props.widthPrint,
      },
    },
    div: {
      width: props.width,
      height: "15px",
      borderStyle: "solid",
      borderWidth: "1px",
      borderColor: "white",

      [theme.breakpoints.down("sm")]: {
        width: props.widthSM,
      },
      [theme.breakpoints.down("xs")]: {
        width: props.widthXS,
      },
      "@media print": {
        width: props.widthPrint,
      },
    },
    divText: {
      width: props.width,
      height: "15px",
      whiteSpace: "nowrap",
      color: theme.palette.primary.main,
      [theme.breakpoints.down("sm")]: {
        width: props.widthSM,
      },
      [theme.breakpoints.down("xs")]: {
        width: props.widthXS,
      },
      "@media print": {
        width: props.widthPrint,
      },
    },
    divContainer: {
      display: "inherit",
      marginLeft: "auto",
      marginRight: "auto",
      textAlign: "center",
      fontSize: "0.8rem",
      lineHeight: "26px",
      //marginTop: theme.spacing(1),
      color: theme.palette.secondary.main,
      "@media print": {
        marginTop: theme.spacing(0),
      },
    },
    cardTitle: {
      fontSize: "1.1rem",
      color: theme.palette.primary.main,
    },
    cardSubtitle: {
      fontSize: "0.8rem",
      color: theme.palette.primary.light,
      bottom: 0,
      left: 3,
      position: "absolute",
      margin: 0,
      paddingBottom: theme.spacing(0),
    },
    cardValue: {
      fontSize: "1.5rem",
      color: theme.palette.secondary.main,
      paddingTop: "22px",
    },
  }));
  useEffect(() => {}, [props.scolor, props.pcolor]);
  const classes = useStyles();
  const theme = useTheme();
  const [position, setPosition] = useState(5);
  useEffect(() => {
    console.log("dkhal");
  }, []);

  useEffect(() => {
    console.log("propssssss", props.title, props);
    if (props.prixHaut === props.prixBas && props.prixReel === props.prixBas) {
      console.log("egale");
      setPosition(5);
    } else if (props.prixReel < props.prixBas) {
      console.log("plus bas");
      setPosition(0);
    } else if (props.prixReel > props.prixHaut) {
      console.log("plus haut");
      setPosition(9);
    } else {
      console.log("entre les 2");
      setPosition(
        parseInt(
          (props.prixReel - props.prixBas) /
            ((props.prixHaut - props.prixBas) / 9) +
            1,
          10
        )
      );
    }
  }, [props.prixBas, props.prixReel, props.prixHaut, props.prixMoyen]);

  useEffect(() => {
    console.log(
      props.title,
      "positiooooon",
      position,
      parseInt(
        (props.prixReel - props.prixBas) /
          ((props.prixHaut - props.prixBas) / 9) +
          1,
        10
      )
    );
  }, [position]);

  const prixReelRender = () => (
    <div style={{ textAlign: "center" }}>
      <div>{isNaN(props.prixReel) ? 0 : parseInt(props.prixReel, 10)}</div>
      <DetailsRoundedIcon style={{ marginLeft: "auto", marginRight: "auto" }} />
    </div>
  );

  const mainRender = () => (
    <Card className={classes.card}>
      <Typography
        style={{ color: props.pcolor }}
        align={"center"}
        variant="h5"
        component="h2"
        className={classes.cardTitle}
      >
        {props.title}
      </Typography>
      {props.noData ? (
        <Grid
          container
          justify="center"
          alignItems="center"
          style={{ marginLeft: "auto", marginRight: "auto", height: "80%" }}
        >
          <Typography
            style={{ color: props.pcolor }}
            align={"center"}
            variant="h4"
          >
            {props.messageNoData}
          </Typography>
        </Grid>
      ) : (
        <div className="row">
          <Grid
            container
            style={{ marginLeft: "auto", marginRight: "auto" }}
            sm={1}
          >
            <br />
            <div
              style={{
                fontWeight: "bold",
                marginTop: theme.spacing(0.5),
                color: theme.palette.primary.main,
              }}
            >
              {"Prix moyen|" + props.prixMoyen}
            </div>
          </Grid>
          <Grid container style={{ marginRight: "10px" }} sm={9}>
            <div className={classes.divContainer}>
              <div className={classes.divReel}>
                {position === 0 && prixReelRender()}
              </div>
              <div className={classes.divReel}>
                {position === 1 && prixReelRender()}
              </div>
              <div className={classes.divReel}>
                {position === 2 && prixReelRender()}
              </div>
              <div className={classes.divReel}>
                {position === 3 && prixReelRender()}
              </div>
              <div className={classes.divReel}>
                {position === 4 && prixReelRender()}
              </div>
              <div className={classes.divReel}>
                {position === 5 && prixReelRender()}
              </div>
              <div className={classes.divReel}>
                {position === 6 && prixReelRender()}
              </div>
              <div className={classes.divReel}>
                {position === 7 && prixReelRender()}
              </div>
              <div className={classes.divReel}>
                {position === 8 && prixReelRender()}
              </div>
              <div className={classes.divReel}>
                {position === 9 && prixReelRender()}
              </div>
              <div className={classes.divReel} style={{ direction: "rtl" }}>
                {position === 10 && prixReelRender()}
              </div>
            </div>
            <div className={classes.divContainer}>
              <div
                className={classes.div}
                style={{ backgroundColor: props[50] }}
              ></div>
              <div
                className={classes.div}
                style={{ backgroundColor: cyan[100] }}
              ></div>
              <div
                className={classes.div}
                style={{ backgroundColor: cyan[200] }}
              ></div>
              <div
                className={classes.div}
                style={{ backgroundColor: cyan[300] }}
              ></div>
              <div
                className={classes.div}
                style={{ backgroundColor: cyan[400] }}
              ></div>
              <div
                className={classes.div}
                style={{ backgroundColor: cyan[500] }}
              ></div>
              <div
                className={classes.div}
                style={{ backgroundColor: cyan[600] }}
              ></div>
              <div
                className={classes.div}
                style={{ backgroundColor: cyan[700] }}
              ></div>
              <div
                className={classes.div}
                style={{ backgroundColor: cyan[800] }}
              ></div>
              <div className={classes.div}></div>
            </div>
            <div className={classes.divContainer}>
              <div className={classes.divText}></div>
              <div className={classes.divText}>
                {"Prix bas | " + props.prixBas}
              </div>
              <div className={classes.divText}></div>
              <div className={classes.divText}></div>
              <div className={classes.divText}></div>
              <div className={classes.divText}></div>
              <div className={classes.divText}></div>
              <div className={classes.divText}></div>
              <div className={classes.divText}></div>
              <div className={classes.divText} style={{ direction: "rtl" }}>
                {"Prix haut | " + props.prixHaut}
              </div>
              <div className={classes.divText}></div>
            </div>
          </Grid>
        </div>
      )}
      <Typography
        style={{ color: props.pcolor }}
        paragraph
        align={"center"}
        variant="subtitle1"
        color="textSecondary"
        className={classes.cardSubtitle}
      >
        {props.subtitle}
      </Typography>
    </Card>
  );

  return mainRender();
}

JaugePrix.defaultProps = {
  widthXS: "20px",
  widthSM: "45px",
  width: "60px",
  widthPrint: "45px",
  noData: false,
  height: 213,
  heightPrint: 195,
  padding: "0px Opx Opx Opx",
  value: 0,
  messageNoData: "Aucune donnée disponible",
};

JaugePrix.propTypes = {
  prixBas: PropTypes.number.isRequired,
  prixMoyen: PropTypes.number.isRequired,
  prixHaut: PropTypes.number.isRequired,
  prixReel: PropTypes.number.isRequired,
  noData: PropTypes.bool,
  messageNoData: PropTypes.string,
  height: PropTypes.number,
  heightPrint: PropTypes.number,
  padding: PropTypes.string,
};
