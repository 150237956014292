import {
  DATA_MAP_LOCATION,
  DATA_MAP_VENTE_NOTAIRE,
  DATA_MAP_VENTE_ACTUEL,
  DATA_MAP_RENDEMENT_BRUT
} from '../actions/types';

const initialState = {
  dataMap: 'loyer'
};

export default function(state = initialState, action) {
  switch (action.type) {
    case DATA_MAP_LOCATION:
      return {
        ...state,
        dataMap: 'loyer'
      };
    case DATA_MAP_VENTE_NOTAIRE:
      return {
        ...state,
        dataMap: 'vente_notaire'
      };
    case DATA_MAP_VENTE_ACTUEL:
      return {
        ...state,
        dataMap: 'vente_actuel'
      };
    case DATA_MAP_RENDEMENT_BRUT:
      return {
        ...state,
        dataMap: 'rendement_brut'
      };
    default:
      return state;
  }
}
