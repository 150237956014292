//
// /!\ OLD /!\
//
import axios from 'axios';
import {
  GET_IRIS_FROM_COORD,
  GET_IRIS_FOR_ONE_COMMUNE
} from './types';

const { APIServerUrl } = require('../shared/values/environnement')

export const getListeIrisByCodeInsee = data => dispatch => {
  if([0, 1, 2, 3, 4, 5,10].includes(data.role)) {
    return axios
    .get(APIServerUrl + '/api/iris/liste-iris-pour-une-commune/' + data.codeInsee)
    .then(res => {
      console.log("iris")
      dispatch({
        type: GET_IRIS_FOR_ONE_COMMUNE,
        payload: res.data
      })
      return res.data;
    })
    .catch(err => {
      dispatch({
        type: GET_IRIS_FOR_ONE_COMMUNE,
        payload: { error: 'KO' }
      })
    })
  } else {
    return false
  }
}

export const getIrisFromCoordinates = (x, y) => dispatch => {
  return axios
    .get(APIServerUrl + '/api/iris/coordinates/' + x + '/' + y)
    .then(res => {
      console.log("iriss",res.data)
      console.log('cbon1')
      localStorage.setItem('iris',JSON.stringify(res.data))
      dispatch({
        type: GET_IRIS_FROM_COORD,
        payload: res.data
      })
      return res.data
    })
    .catch(err => {
      dispatch({
        type: GET_IRIS_FROM_COORD,
        payload: { error: 'KO' }
      })
    })
}

export const getIrisFromCoordinatesiris = (x, y)  => {
  return axios
    .get(APIServerUrl + '/api/iris/coordinates/' + x + '/' + y)
   
   
}
